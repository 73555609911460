import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { TranslationService } from '@core/services/translation.service';
import { MailingListMemberDTO } from '@gen/gen.dto';
import { RegionalFormattingService } from '@shared/services/regional-formatting.service';
import { tap } from 'rxjs/operators';
import { MailingListService } from '../mailing-list.service';

interface Subscriber extends MailingListMemberDTO {
  joinedAtStr: string;
  confirmedStr: string;
}

@Component({
  selector: 'cms-mailing-list-subscribers',
  templateUrl: './subscribers.component.html',
  styleUrls: ['./subscribers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribersComponent implements OnInit {
  subscribers: Subscriber[];

  constructor(
    private cmsConfigService: CmsConfigService,
    private mailingListService: MailingListService,
    private regionalFormattingService: RegionalFormattingService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const groupId = this.cmsConfigService.groupId;
    const dict = this.translationService.dict();
    const confirmed = dict.UI.CMS.MAILING_LIST.CONFIRMED;
    const notConfirmed = dict.UI.CMS.MAILING_LIST.NOT_CONFIRMED;
    this.mailingListService.getSubscribers(groupId).subscribe((response) => {
      this.subscribers = response.members.map((i) => {
        return Object.assign(
          {
            joinedAtStr: this.regionalFormattingService.defaultRelativeDateTime(
              i.joinedAt
            ),
            confirmedStr: i.isConfirmed ? confirmed : notConfirmed,
          },
          i
        );
      });
      this.subscribers.sort((a, b) => (a.email < b.email ? -1 : 1));
      this.cdr.detectChanges();
    });
  }

  getDelete(toRemove: Subscriber) {
    return () => {
      return this.mailingListService.removeSubscriber(toRemove.id).pipe(
        tap((i) => {
          this.subscribers = this.subscribers.filter(
            (i) => i.id !== toRemove.id
          );
          this.cdr.detectChanges();
        })
      );
    };
  }
}
