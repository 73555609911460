import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { NavSecondModule } from '@shared/components/nav-second/nav-second.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { PlainEditorModule } from '../plain-editor/plain-editor.module';
import { MailingListComponent } from './mailing-list.component';
import { MessagesComponent } from './messages/messages.component';
import { SendMessageComponent } from './send-message/send-message.component';
import { SubscribeComponent } from './subscribe/subscribe.component';
import { SubscribersComponent } from './subscribers/subscribers.component';

@NgModule({
  declarations: [
    MailingListComponent,
    SubscribeComponent,
    SendMessageComponent,
    MessagesComponent,
    SubscribersComponent,
  ],
  imports: [
    CommonModule,
    NavSecondModule,
    SharedDirectivesModule,
    PlainEditorModule,
    MatInputModule,
    MatSlideToggleModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    LoadingButtonModule,
    FormEditorModule,
  ],
  exports: [MailingListComponent],
})
export class MailingListModule {}
