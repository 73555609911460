import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmModalComponent } from './confirm-modal.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [ConfirmModalComponent],
  imports: [CommonModule, SharedDirectivesModule, MatButtonModule],
  entryComponents: [ConfirmModalComponent],
})
export class ConfirmModalModule {}
