import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EditingToolbarComponent } from './editing-toolbar.component';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { MatButtonModule } from '@angular/material/button';

@NgModule({
  declarations: [EditingToolbarComponent],
  imports: [
    CommonModule,
    LoadingButtonModule,
    SharedDirectivesModule,
    MatButtonModule,
  ],
  exports: [EditingToolbarComponent],
})
export class EditingToolbarModule {}
