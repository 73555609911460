import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { TranslationKey } from '@core/types/translations.model';

export type MessageType = 'OK' | 'ERROR';

@Component({
  selector: 'shared-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessageComponent implements OnInit {
  @Input() text: string;
  @Input() type: MessageType;
  @Input() key: TranslationKey;
  @Input() params: { [key: string]: string | number } = {};

  constructor(
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    if (this.key) {
      this.translationService
        .fromStringViaPromise(this.key, this.params)
        .then((value) => {
          this.text = value;
          this.cdr.detectChanges();
        });
    }
  }
}
