import { MediaMatcher } from '@angular/cdk/layout';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { ModifyModalComponent } from '@cms/components/modify-modal/modify-modal.component';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { DatService } from '@core/services/dat.service';
import { NavLink, ROUTES } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import { TitleService } from '@core/services/title.service';
import { TranslationService } from '@core/services/translation.service';
import { UserService } from '@core/services/user.service';
import { CmsConfigDTO, GroupDTO, GroupMemberDTO, UserDTO } from '@gen/gen.dto';
import { SampleModalComponent } from '@shared/components/sample-modal/sample-modal.component';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { ModalService, ModalSize } from '@shared/services/modal.service';

const MIN_TOP_GAP = 44;
const MAX_TOP_GAP = 52;
const DEFAULT_TITLE = 'UI.PAGE_TITLES.LANDING';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title: string;
  leftOpened = false;
  rightOpened = false;
  isLoggedIn = false;
  cmsConfig: CmsConfigDTO | false = false;
  topGap: number;
  user: UserDTO;
  selectedGroup: GroupDTO | false = false;
  secondNav: NavLink[] | false;
  isGroupRestriction = false;

  constructor(
    private navService: NavService,
    private router: Router,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private titleService: TitleService,
    private userService: UserService,
    private currentGroupService: CurrentGroupService,
    private modalService: ModalService,
    private cmsConfigService: CmsConfigService,
    private datService: DatService,
    mediaMatcher: MediaMatcher
  ) {
    const mediaListener = mediaMatcher.matchMedia('(max-width:599px)');
    mediaListener.addListener((ev) => {
      this.topGap = ev.matches ? MIN_TOP_GAP : MAX_TOP_GAP;
      this.cdr.detectChanges();
    });
    this.topGap = mediaListener.matches ? MIN_TOP_GAP : MAX_TOP_GAP;
  }

  ngOnInit() {
    this.isGroupRestriction = this.datService.isGroupRestriction;
    this.userService.isLoggedIn.subscribe((is) => {
      this.isLoggedIn = is;
      this.user = is ? this.userService.user : null;
      this.cdr.markForCheck();
    });

    this.titleService.currentTitle$.subscribe((title) => {
      if (title) {
        this.title = title;
      }
    });

    this.currentGroupService.currentGroup$.subscribe((g) => {
      this.selectedGroup = g;
      this.cdr.markForCheck();
    });

    this.cmsConfigService.configReady$.subscribe((is) => {
      if (!is) {
        this.cmsConfig = false;
        this.cdr.detectChanges();
        return;
      }
      this.cmsConfig = this.cmsConfigService.getConfig();
      this.cdr.detectChanges();
    });

    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        const data = event.snapshot.data;
        if (data && data.useServiceTitle) {
          return;
        }
        this.translationService
          .fromStringViaPromise(data && data.title ? data.title : DEFAULT_TITLE)
          .then((title) => {
            this.title = title;
            this.cdr.markForCheck();
          });
      }
    });

    this.navService.secondNav$.subscribe((nav) => {
      this.secondNav = nav;
      this.cdr.markForCheck();
    });
  }

  modifyCms() {
    this.modalService.showModal(ModifyModalComponent, {
      allowOverlayClick: false,
      showCloseButton: false,
      size: ModalSize.XL,
    });
  }

  hasSecondNav() {
    return !!this.secondNav;
  }

  home() {
    this.navService.goto(ROUTES.PUB.HOME);
  }

  login() {
    this.navService.goto(ROUTES.PUB.LOGIN);
  }

  newGroup() {
    this.navService.goto(ROUTES.PRI.NEW_GROUP);
  }

  newEvent() {
    // There should be modal for group selector if group is not selected
    this.navService.goto(ROUTES.PRI.GROUP.EVENTS.EDITOR_NEW);
  }

  logout() {
    this.userService.logout().then((_) => {
      this.navService.goto(ROUTES.PUB.HOME);
    });
  }

  toggleLeftNav() {
    this.leftOpened = !this.leftOpened;
  }

  closeRightNavIfOpen() {
    if (this.rightOpened) {
      this.rightOpened = false;
      this.cdr.detectChanges();
    }
  }

  openRightNav() {
    if (!this.rightOpened) {
      setTimeout(() => {
        if (!this.isLoggedIn) {
          this.login();
        } else {
          this.rightOpened = true;
        }
        this.cdr.detectChanges();
      });
    }
  }

  selectGroup(g: GroupMemberDTO) {
    this.navService.goto(ROUTES.PRI.GROUP.EVENTS.CALENDAR, {
      groupId: g.group.id,
    });
  }

  showModal(): void {
    this.modalService.showModal(SampleModalComponent, {
      allowOverlayClick: true,
      showCloseButton: false,
      data: {
        modalTitle: 'This is the sample modal',
      },
      whenClosed: (data) => {
        console.log(data);
      },
    });
  }
}
