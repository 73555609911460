<div *ngIf="isEditing">
  <cms-pages-plain-editor [form]="form"></cms-pages-plain-editor>

  <shared-form-editor [config]="config"></shared-form-editor>
</div>

<ng-container *ngIf="!isEditing">

  <ng-container *appGroupPermission="'GRP_MANAGE_WEB_PORTAL'">
    <app-nav-second [links]="tabLinks" [allowPartialFit]="false" [externalActive]="true" class="tab-nav dropdown">
    </app-nav-second>
  </ng-container>

  <div [ngSwitch]="activeTabId" class="modal-scroll">
    <div *ngSwitchCase="'JOIN'">
      <div [innerHtml]="page.content?.html"></div>
      <cms-mailing-list-subscribe></cms-mailing-list-subscribe>
    </div>
    <cms-mailing-list-send-message *ngSwitchCase="'SEND_MESSAGE'"></cms-mailing-list-send-message>
    <cms-mailing-list-subscribers *ngSwitchCase="'MEMBERS'"></cms-mailing-list-subscribers>
    <cms-mailing-list-messages *ngSwitchCase="'MESSAGES'"></cms-mailing-list-messages>
  </div>

</ng-container>