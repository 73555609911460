import { Injectable } from '@angular/core';
import { GroupApiService } from './group-api.service';
import { tap } from 'rxjs/operators';
import { CurrentGroupService } from '@shared/services/current-group.service';

@Injectable({
  providedIn: 'root',
})
export class GroupFeaturesService {
  constructor(
    private api: GroupApiService,
    private currentGroupService: CurrentGroupService
  ) {}

  getEditableFeatures() {
    return this.api.getEditableFeatures();
  }

  toggleFeature(groupId: string, featureId: string) {
    return this.api
      .toggleFeature(groupId, featureId)
      .pipe(tap((_) => this.currentGroupService.refreshGroup()));
  }
}
