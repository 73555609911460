import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ROUTES } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import { TranslationService } from '@core/services/translation.service';
import { UserService } from '@core/services/user.service';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { ToasterService } from '@shared/services/toaster.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginComponent implements OnInit {
  private form = this.fb.group({
    username: ['', Validators.required],
    password: ['', Validators.required],
  });

  config: FormEditorConfig;

  private dict = this.translationService.dict();

  constructor(
    private navService: NavService,
    private userService: UserService,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private toasterService: ToasterService
  ) {}

  ngOnInit(): void {
    this.config = this.getConfig();
  }

  private getConfig() {
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: ['full-width'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.USERNAME,
          formControlName: 'username',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.PASSWORD,
          formControlName: 'password',
          type: 'password',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.LOGIN,
          type: 'button.loading',
          callback: this.getLoginCallback(),
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  private getLoginCallback() {
    return () => {
      return this.userService
        .login(
          this.form.controls.username.value,
          this.form.controls.password.value
        )
        .then(
          (ok) => {
            this.navService.goto(ROUTES.PRI.ME);
          },
          (error) => {
            this.toasterService.showError(this.dict.COMMON_UI.ERROR.LOGIN);
          }
        );
    };
  }
}
