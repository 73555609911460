<p>
  <ng-container *ngFor="let i of subscribers; index as ind">
    <ng-container *ngIf="ind > 0">,</ng-container>
    {{ i.email }}
  </ng-container>
</p>

<div class="row-container" *ngFor="let i of subscribers; index as ind">
  <div class="row actions-only">
    <div class="col-xs-1">
      {{ ind + 1 }}
    </div>
    <div class="col-xs-11 col-sm-5 col-md-5">
      {{ i.email }}
    </div>
    <div class="col-xs-4 col-sm-2 col-md-2">
      {{ i.joinedAtStr }}
    </div>
    <div class="col-xs-4 col-sm-2 col-md-2">
      {{ i.confirmedStr }}
    </div>
    <div class="col-xs-1">
      {{ i.messagesReceivedCount }}
    </div>
    <div class="col-xs-1">
      <shared-loading-button
        key="COMMON_UI.KEY_WORD.DELETE"
        [cb]="getDelete(i)"
      >
      </shared-loading-button>
    </div>
  </div>
</div>
