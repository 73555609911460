import { Injectable } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';

export const MEDIA_TYPES = {
  xs: 0,
  s: 1,
  m: 2,
  l: 3,
  xl: 4,
  xxl: 5,
  na: -1,
};
export type MEDIA_TYPE = keyof typeof MEDIA_TYPES;

@Injectable({ providedIn: 'root' })
export class MediaMatcherService {
  mediaType$ = new BehaviorSubject<MEDIA_TYPE>('l');
  mediaTypeInd$ = this.mediaType$.pipe(
    map((t) => MediaMatcherService.mediaToInd(t))
  );
  static mediaToInd(type: MEDIA_TYPE): number {
    if (type in MEDIA_TYPES) {
      return MEDIA_TYPES[type];
    }
    return -1;
  }

  static indToMedia(ind: number): MEDIA_TYPE {
    for (const type in MEDIA_TYPES) {
      if (MEDIA_TYPES.hasOwnProperty(type) && MEDIA_TYPES[type] === ind) {
        return type as MEDIA_TYPE;
      }
    }
    return 'na';
  }

  private xs: MediaQueryList;
  private s: MediaQueryList;
  private m: MediaQueryList;
  private l: MediaQueryList;
  private xl: MediaQueryList;

  constructor(media: MediaMatcher) {
    const current = (matches: boolean, type: MEDIA_TYPE) => {
      if (matches) {
        this.mediaType$.next(type);
      }
    };

    this.xs = media.matchMedia('(max-width: 480px)');
    this.xs.addListener((ev) => current(ev.matches, 'xs'));
    current(this.xs.matches, 'xs');

    this.s = media.matchMedia('(min-width: 481px) and (max-width:768px)');
    this.s.addListener((ev) => current(ev.matches, 's'));
    current(this.s.matches, 's');

    this.m = media.matchMedia('(min-width: 769px) and (max-width:1024px)');
    this.m.addListener((ev) => current(ev.matches, 'm'));
    current(this.m.matches, 'm');

    this.l = media.matchMedia('(min-width: 1025px) and (max-width:1280px)');
    this.l.addListener((ev) => current(ev.matches, 'l'));
    current(this.l.matches, 'l');

    this.xl = media.matchMedia('(min-width: 1281px)');
    this.xl.addListener((ev) => current(ev.matches, 'xl'));
    current(this.xl.matches, 'xl');
  }
}
