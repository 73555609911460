import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivationStart, Router } from '@angular/router';
import { API } from '@core/services/api-v1.models';
import { NavLink, ROUTES } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import { TranslationService } from '@core/services/translation.service';
import { UserService } from '@core/services/user.service';
import { GroupDTO } from '@gen/gen.dto';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';
import { assert } from '../utils/assert.func';

@Injectable({
  providedIn: 'root',
})
export class CurrentGroupService {
  // Refreshes group from the backend always when new group is selected even if the same group was earlier selected

  private nextGroup: GroupDTO | undefined;
  private latestGroupRequestId: string;

  currentGroup$ = new BehaviorSubject<GroupDTO | false>(false);
  isInCms$ = new BehaviorSubject<boolean>(false);

  get currentGroup(): GroupDTO {
    assert(this.currentGroup$.value);
    return this.currentGroup$.value as GroupDTO;
  }

  get featureMap(): { [feature: string]: true } {
    return this.currentGroup.features.reduce((obj, f) => {
      obj[f] = true;
      return obj;
    }, {});
  }

  get currentGroupId(): string {
    return this.currentGroup.id;
  }

  constructor(
    private router: Router,
    private http: HttpClient,
    private navService: NavService,
    private translationService: TranslationService,
    private userService: UserService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationStart) {
        if (event.snapshot.data.isCms) {
          this.isInCms$.next(true);
          return;
        }
        const id = event.snapshot.params?.groupId;
        if (!id) {
          this.currentGroup$.next(false);
          this.navService.clearSecondNav('GROUP');
          return;
        }
        this.setGroupWithId(id);
      }
    });
  }

  setGroupWithId(id: string) {
    if (this.shouldRefreshGroup(id)) {
      this.getGroup(id).subscribe((g) => {
        if (id !== this.latestGroupRequestId) {
          return;
        }
        this.addGroupNav(g);
        this.nextGroup = undefined;
        this.currentGroup$.next(g);
      });
    }
  }

  hasCurrentGroupFeature(featureId: string) {
    return this.currentGroup.features.some((f) => f === featureId);
  }

  // With this we can set next group to prevent api call in case this is available
  // (for example after create)
  setNextGroup(group: GroupDTO) {
    this.nextGroup = group;
  }

  setCurrentGroup(group: GroupDTO) {
    this.nextGroup = undefined;
    this.currentGroup$.next(group);
  }

  refreshGroup() {
    this.nextGroup = undefined;
    this.getGroup(this.currentGroupId).subscribe((g) => {
      this.addGroupNav(g);
      this.nextGroup = undefined;
      this.currentGroup$.next(g);
    });
  }

  private shouldRefreshGroup(id: string) {
    if (this.nextGroup && this.nextGroup.id === id) {
      return true;
    }
    if (!this.currentGroup$.value) {
      return true;
    }
    return this.currentGroup$.value.id !== id;
  }

  // TODO: Create interceptor to change header if group is used via another group
  private getGroup(id: string): Observable<GroupDTO> {
    this.latestGroupRequestId = id;
    if (this.nextGroup && this.nextGroup.id === id) {
      return of(this.nextGroup);
    }
    return this.userService.user$.pipe(
      filter((i) => !!i),
      switchMap((i) => {
        const url = subsToUrl(API.GROUP.GET, { groupId: id });
        return this.http.get<GroupDTO>(url);
      })
    );
  }

  async getSettingsNav(): Promise<NavLink[]> {
    const dict = (await this.translationService.dictViaPromise()).UI.GROUP
      .LINKS;
    const r = ROUTES.PRI.GROUP.SETTINGS;
    const nav = [
      {
        url: r.GENERAL.url,
        name: dict.SETTINGS,
      },
      {
        url: r.EVENTS.url,
        name: dict.SETTINGS_EVENTS,
      },
      {
        url: r.LOOKUP_LISTS.url,
        name: dict.SETTINGS_LOOKUP_LISTS,
      },
      {
        url: r.ROLES.url,
        name: dict.SETTINGS_ROLES,
      },
      {
        url: r.FEATURES.url,
        name: dict.SETTINGS_FEATURES,
      },
    ];
    return nav;
  }

  private async addGroupNav(group: GroupDTO) {
    const dict = await this.translationService.dictViaPromise();
    let groupNav: NavLink[] = [];
    if (group.features.some((f) => f === 'WEB_PORTAL') && group.slug) {
      groupNav.push({
        url: '/' + group.slug,
        name: dict.UI.GROUP.LINKS.WEB_PORTAL,
        cls: 'link--web-portal',
      });
    }
    groupNav = [
      ...groupNav,
      ...[
        {
          url: ROUTES.PRI.GROUP.EVENTS.CALENDAR.url,
          name: dict.UI.GROUP.LINKS.CALENDAR,
        },
        {
          url: ROUTES.PRI.GROUP.MEMBERS.url,
          name: dict.UI.GROUP.LINKS.MEMBERS,
        },
        {
          url: ROUTES.PRI.GROUP.MY_DETAILS.url,
          name: dict.UI.GROUP.LINKS.MY_DETAILS,
        },
        {
          url: ROUTES.PRI.GROUP.STATISTICS.url,
          name: dict.UI.GROUP.LINKS.STATISTICS,
        },
        {
          url: ROUTES.PRI.GROUP.SETTINGS.GENERAL.url,
          activeUrl: ROUTES.PRI.GROUP.SETTINGS.url,
          name: dict.UI.GROUP.LINKS.SETTINGS,
        },
        {
          url: ROUTES.PRI.GROUP.GDPR.url,
          name: dict.UI.GROUP.LINKS.GDPR,
        },
      ],
    ];
    this.navService.setSecondNav('GROUP', groupNav);
  }
}
