import { Injectable } from '@angular/core';
import { ModalService, ModalSize } from './modal.service';
import {
  ConfirmModalComponent,
  ReturnValue,
} from '../components/confirm-modal/confirm-modal.component';

@Injectable({
  providedIn: 'root',
})
export class ConfirmActionService {
  constructor(private modalService: ModalService) {}

  confirm() {
    return new Promise<boolean>((resolve, reject) => {
      this.modalService.showModal(ConfirmModalComponent, {
        allowOverlayClick: false,
        showCloseButton: false,
        size: ModalSize.M,
        whenClosed: (value: ReturnValue) => {
          resolve(value == ReturnValue.CONFIRM);
        },
      });
    });
  }
}
