import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { CmsPageService } from '@cms/services/cms-page.service';
import { CssVarName } from '@cms/services/cms.models';
import { TranslationService } from '@core/services/translation.service';
import { ITranslations } from '@core/types/translations.model';
import { CssVarDTO } from '@gen/gen.dto';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { tap } from 'rxjs/operators';

type Structure = {
  label: string;
  form: string;
  classList: string[];
  color: string;
};

const DIRTY_KEY = 'CMS_CONFIG_STYLE';

@Component({
  selector: 'cms-modify-style',
  templateUrl: './style.component.html',
  styleUrls: ['./style.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class StyleComponent implements OnInit {
  colorsForm: FormGroup;
  data: Structure[][];
  // This is just flatted data
  private flatData: Structure[];

  @ViewChildren('cssElem') elems: QueryList<ElementRef>;

  constructor(
    fb: FormBuilder,
    private cmsPageService: CmsPageService,
    private cmsConfigService: CmsConfigService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private translationService: TranslationService
  ) {
    const colorControls: { [key: string]: any } = {};
    this.data = this.getStructure();
    this.flatData = this.data.reduce((i, j) => {
      return [...i, ...j];
    }, []);
    this.flatData.forEach((c) => {
      colorControls[c.form] = [''];
    });
    this.colorsForm = fb.group(colorControls);
  }

  private getStructure() {
    const dict = this.translationService.dict();
    return getColorOptions(dict).map((i) => {
      return i.map((j) => {
        return Object.assign(
          {
            classList: ['colors', 'colors__' + camelCaseToKebabCase(j.form)],
            color: 'black',
          },
          j
        );
      });
    });
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.elems.forEach((i, index) => {
      const color = window
        .getComputedStyle(i.nativeElement, null)
        .getPropertyValue('background-color');
      this.flatData[index].color = color;
      this.colorsForm.patchValue({ [this.flatData[index].form]: color });
    });
  }

  selected(color: string, form: string) {
    this.colorsForm.patchValue({ [form]: color });
    this.dirtyStateTrackerService.setDirty(DIRTY_KEY);
    const index = this.flatData.findIndex((i) => i.form === form);
    this.elems.toArray()[index].nativeElement.style.backgroundColor = color;
  }

  save() {
    return () => {
      const vars = this.getCssVarsFromForm();
      return this.cmsPageService
        .saveCssVars(this.cmsConfigService.groupId, vars)
        .pipe(
          tap(() => {
            this.cmsConfigService.setCustomColors(vars);
            this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
          })
        );
    };
  }

  private getCssVarsFromForm(): CssVarDTO[] {
    // Quite a general solution
    // https://stackoverflow.com/questions/35096257/regular-rxpression-to-convert-a-camel-case-string-into-kebab-case
    return this.flatData.map((c) => {
      const color = this.colorsForm.controls[c.form].value;
      const key = ('--' + camelCaseToKebabCase(c.form)) as CssVarName;
      return { name: key, value: color };
    });
  }
}

function camelCaseToKebabCase(str: string) {
  const re = /[A-Z]/g;
  return str
    .replace(re, (matched, index, original) => {
      return '-' + matched;
    })
    .toLowerCase();
}

function getColorOptions(dict: ITranslations) {
  const active = dict.UI.CMS.CONFIG.STYLES.ACTIVE;
  const bg = dict.UI.CMS.CONFIG.STYLES.BG;
  const text = dict.UI.CMS.CONFIG.STYLES.TEXT;
  const hover = dict.UI.CMS.CONFIG.STYLES.HOVER;
  //
  const topBar = dict.UI.CMS.CONFIG.STYLES.OPTIONS.TOP_BAR;
  const linkBar = dict.UI.CMS.CONFIG.STYLES.OPTIONS.LINK_BAR;
  const subLink = dict.UI.CMS.CONFIG.STYLES.OPTIONS.SUB_LINK_BAR;
  const showMore = dict.UI.CMS.CONFIG.STYLES.OPTIONS.SHOW_MORE_ITEMS;
  //
  const j = (...args: string[]) => args.join(' ');
  return [
    [
      [j(topBar, bg), 'toolbarBackgroundColor'],
      [j(topBar, text), 'toolbarColor'],
    ],
    [
      [j(topBar, bg, hover), 'toolbarBackgroundColorHover'],
      [j(topBar, text, hover), 'toolbarColorHover'],
    ],
    [[dict.UI.CMS.CONFIG.STYLES.OPTIONS.TOP_BAR_SPACER, 'toolbarSpacerColor']],
    [
      [j(linkBar, bg), 'secondNavBackgroundColor'],
      [j(linkBar, text), 'secondNavColor'],
    ],
    [
      [j(linkBar, bg, hover), 'secondNavBackgroundColorHover'],
      [j(linkBar, text, hover), 'secondNavColorHover'],
    ],
    [
      [j(linkBar, bg, active), 'secondNavBackgroundColorActive'],
      [j(linkBar, text, active), 'secondNavColorActive'],
    ],
    [
      [j(subLink, bg, active), 'tabNavBackgroundColor'],
      [j(subLink, text, active), 'tabNavColor'],
    ],
    [
      [j(showMore, bg), 'showMoreBackgroundColor'],
      [j(showMore, text), 'showMoreColor'],
    ],
    [
      [
        dict.UI.CMS.CONFIG.STYLES.OPTIONS.BANNER_IMAGE_BG,
        'mainBannerBackgroundColor',
      ],
    ],
  ].map((i) => {
    return i.map((j) => {
      return {
        label: j[0] as string,
        form: j[1] as string,
      };
    });
  });
}
