import { Injectable } from '@angular/core';
import { CmsPageDTO } from '@gen/gen.dto';
import { Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class CmsPageStore {
  private pages: { [id: string]: CmsPageDTO } = {};
  pageUpdates$ = new Subject<string>();

  hasPage(id: string) {
    return id in this.pages;
  }

  getPage(id: string) {
    return this.pages[id];
  }

  addPage(page: CmsPageDTO) {
    this.pages[page.id] = page;
    this.pageUpdates$.next(page.id);
  }
}
