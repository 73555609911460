import { Injectable } from '@angular/core';
import { CalendarDTO, EventTypeDTO, SignUpFormDetailsDTO } from '@gen/gen.dto';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { CacherUtils } from '@shared/utils/cacher.util';
import { objToValueCollection } from '@shared/utils/obj-to-update-value-list.func';
import { GroupApiService } from './group-api.service';

const CACHE_AREA = 'GROUP_SIGNUP_FORMST';

@Injectable({
  providedIn: 'root',
})
export class GroupEventSettingsService {
  constructor(
    private api: GroupApiService,
    private currentGroupService: CurrentGroupService,
    private cacherUtils: CacherUtils
  ) {}

  // Calendar
  getCalendars() {
    return this.api.getCalendars(this.currentGroupService.currentGroupId);
  }

  addCalendar(obj: CalendarDTO) {
    const values = objToValueCollection(obj);
    return this.api.addCalendar(
      this.currentGroupService.currentGroupId,
      values
    );
  }

  updateCalendar(obj: CalendarDTO) {
    const values = objToValueCollection(obj);
    return this.api.updateCalendar(obj.id, values);
  }

  removeCalendar(id: string) {
    return this.api.removeCalendar(id);
  }

  setCalendarToDefault(id: string) {
    const values = objToValueCollection({ isDefault: true });
    return this.api.updateCalendar(id, values);
  }

  // EventTypes
  getEventTypes() {
    return this.api.getEventTypes(this.currentGroupService.currentGroupId);
  }

  addEventType(obj: EventTypeDTO) {
    const values = objToValueCollection(obj);
    return this.api.addEventType(
      this.currentGroupService.currentGroupId,
      values
    );
  }

  updateEventType(obj: EventTypeDTO) {
    const values = objToValueCollection(obj);
    return this.api.updateEventType(obj.id, values);
  }

  removeEventType(id: string) {
    return this.api.removeEventType(id);
  }

  setEventTypeToDefault(id: string) {
    const values = objToValueCollection({ isDefault: true });
    return this.api.updateEventType(id, values);
  }

  // Sign up forms
  getSignupForms() {
    return this.api.getSignUpForms(this.currentGroupService.currentGroupId);
  }

  getSignupForm(formId: string) {
    return this.cacherUtils.versionedAutoCacher(
      CACHE_AREA,
      formId,
      (id: string) => this.api.getSignUpForm(id)
    );
  }

  addSignUpForm(obj: SignUpFormDetailsDTO) {
    const values = objToValueCollection(obj);
    return this.api.addSignUpForm(
      this.currentGroupService.currentGroupId,
      values
    );
  }

  updateSignUpForm(obj: SignUpFormDetailsDTO) {
    const values = objToValueCollection(obj);
    return this.api.updateSignUpForm(obj.id, values);
  }

  removeSignUpForm(id: string) {
    return this.api.removeSignUpForm(id);
  }

  setSignUpFormToDefault(id: string) {
    const values = objToValueCollection({ isDefault: true });
    return this.api.updateSignUpForm(id, values);
  }
}
