import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import {
  AnyFormItem,
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { FormEvent } from '@shared/models/form.models';

@Component({
  selector: 'cms-modify-routes-page-form',
  templateUrl: './page-form.component.html',
  styleUrls: ['./page-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageFormComponent implements OnInit {
  config: FormEditorConfig;

  @Input() form: FormGroup;
  @Input() links: { id: string; name: string }[];
  @Output() onEvent = new EventEmitter<FormEvent>();
  @Input() modify = false;

  get controls() {
    return this.form.controls;
  }

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.config = this.getConfig();
  }

  private getConfig(): FormEditorConfig {
    const classList = ['col-xs-12', 'col-sm-3'];
    const create = {
      parentId: 1,
      slug: 1,
      title: 1,
    };
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: ['full-width'],
      fields: [
        {
          classList: ['col-xs-12', 'col-sm-5'],
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.PARENT,
          formControlName: 'parentId',
          type: 'select',
          options: this.links.map((i) => {
            return {
              value: i.id,
              label: i.name,
            };
          }),
        },
        {
          classList,
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.SLUG,
          formControlName: 'slug',
        },
        {
          classList: ['col-xs-12', 'col-sm-3'],
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.TITLE,
          formControlName: 'title',
        },
        {
          classList,
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.ICON,
          formControlName: 'icon',
        },
        {
          classList,
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.ORDER,
          formControlName: 'sortOrder',
          type: 'number',
        },
        {
          classList,
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.IS_DRAFT,
          formControlName: 'isDraft',
          type: 'slideToggle',
        },
        {
          classList,
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.IS_MENU_ITEM,
          formControlName: 'isMenuItem',
          type: 'slideToggle',
        },
        {
          classList,
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.IS_PUBLIC,
          formControlName: 'isPublic',
          type: 'slideToggle',
        },
        {
          classList: ['col-xs-12', 'col-sm-5'],
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.PAGE_TYPE,
          formControlName: 'pageType',
          type: 'select',
          options: [
            {
              value: 0,
              labelKey:
                TranslationService.KM.UI.CMS.CONFIG.ROUTES.PAGE_TYPES.HTML,
            },
            {
              value: 15,
              labelKey:
                TranslationService.KM.UI.CMS.CONFIG.ROUTES.PAGE_TYPES
                  .MAILING_LIST,
            },
          ],
        },
      ],
    };
    // Groupify to allow multiple field at same row and filter based on mode
    config.fields = [
      (config.fields as Partial<AnyFormItem>[]).filter(
        (i) => this.modify || i.formControlName in create
      ),
    ];
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }
}
