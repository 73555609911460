import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CmsEditService } from '@cms/services/cms-edit.service';
import { CmsPageDTO } from '@gen/gen.dto';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';

@Component({
  selector: 'cms-page-plain',
  templateUrl: './plain.component.html',
  styleUrls: ['./plain.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainComponent implements OnInit {
  @Input() page: CmsPageDTO;

  isEditing = false;
  protected form: FormGroup;

  constructor(
    protected editingService: CmsEditService,
    protected cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    LifeCyclesUtil.sub(
      this,
      this.editingService.isEditing$,
      (isEditing: boolean) => {
        this.isEditing = isEditing;
        this.cdr.detectChanges();
      }
    );
    LifeCyclesUtil.sub(
      this,
      this.editingService.editorForm$,
      (form: FormGroup) => {
        this.form = form;
        this.cdr.detectChanges();
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}
