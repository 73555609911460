import { USER_PERMISSION, GROUP_PERMISSION } from 'src/app/core/services/permissions.models';

import { TranslationKey } from 'src/app/core/types/translations.model';import { MainBannerType, CmsPageContent } from 'src/app/cms/services/cms.models';

export enum GroupType {
  NORMAL = 0,
  UNION = 1,
}

export enum StatusOption {
  NA = 0,
  OK = 200,
  NOT_FOUND = 400,
  BAD_REQUEST = 403,
  UNIQUE_CONSTRAINT = 601,
}

export enum MemberRequestKind {
  REMOVED = 0,
  UNCONFIRMED = 1,
  ACTIVE = 2,
  ALL_MEMBERS = 3,
}

export enum MessageRequestKind {
  ALL_MESSAGES = 0,
  FOR_SUBSCRIBERS = 1,
  FOR_ME = 2,
}

export enum CmsPageType {
  HTML = 0,
  MAILING_LIST = 15,
}

export enum AltPermissionType {
  NOT_IN_USE = 0,
  CMS_WEB_PAGE = 1,
  CMS_SUBSCRIBER = 2,
  LOOKUP_LIST = 3,
  LOOKUP_LIST_ITEM = 4,
  CALENDAR = 5,
  EVENT_TYPE = 6,
  SIGN_UP_FORM = 7,
}

export interface GroupAddRequestDTO {
  name: string;
  userId: string;
  timezone: string;
  type: GroupType;
}

export interface GroupRequestDTO {
  id: string;
}

export interface GroupDTO {
  id: string;
  name: string;
  shortName: string;
  removed: number;
  slug: string;
  features: string[];
  status: StatusDTO;
}

export interface FeatureToggleRequestDTO {
  groupId: string;
  featureId: string;
  asGroupMember: boolean;
}

export interface GroupMemberDTO {
  id: string;
  givenName: string;
  familyName: string;
  shortName: string;
  created: number;
  isActivated: boolean;
  group: GroupDTO;
}

export interface SignUpFormsDTO {
  forms: SignUpFormDTO[];
}

export interface SignUpFormDTO {
  id: string;
  name: string;
  isDefault: boolean;
}

export interface CalendarsDTO {
  calendars: CalendarDTO[];
}

export interface EventTypesDTO {
  eventTypes: EventTypeDTO[];
}

export interface CalendarDTO {
  id: string;
  groupId: string;
  name: string;
  isDefault: boolean;
}

export interface EventTypeDTO {
  id: string;
  groupId: string;
  isGlobal: boolean;
  name: string;
  color: string;
  isDefault: boolean;
}

export interface SignUpFormDetailsDTO {
  id: string;
  groupId: string;
  name: string;
  isDefault: boolean;
  givenNameText: string;
  isGivenName: boolean;
  familyNameText: string;
  isFamilyName: boolean;
  fullNameText: string;
  isFullName: boolean;
  birthYearText: string;
  isBirthYear: boolean;
  isBirthYearMandatory: boolean;
  birthDateText: string;
  isBirthDate: boolean;
  isBirthDateMandatory: boolean;
  licenseNumberText: string;
  isLicenseNumber: boolean;
  isLicenseNumberMandatory: boolean;
  licenseNumberValidation: string;
  licenseNumberErrorText: string;
  licenseNumberGuide: string;
  isLicenseNumberNumeric: boolean;
  clubText: string;
  isClub: boolean;
  clubOptionsLookupListId: string;
  isClubMandatory: boolean;
  yesStatusDefaultText: string;
  yesStatusTranslations: string;
  isYesStatus: boolean;
  maybeStatusDefaultText: string;
  maybeStatusTranslations: string;
  isMaybeStatus: boolean;
  noStatusDefaultText: string;
  noStatusTranslations: string;
  isNoStatus: boolean;
  status4DefaultText: string;
  status4Translations: string;
  isStatus4: boolean;
  status5DefautText: string;
  status5Translations: string;
  isStatus5: boolean;
  status6DefaultText: string;
  status6Translations: string;
  isStatus6: boolean;
  messageDefaultText: string;
  messageTranslations: string;
  isMessage: boolean;
  signupCategory1Text: string;
  isSignupCategory1: boolean;
  signupCategory1LookupListId: string;
  signupCategory1Guide: string;
  isSignupCateogry1Mandatory: boolean;
  signupCategory2Text: string;
  isSignupCategory2: boolean;
  signupCategory2LookupListId: string;
  signupCategory2Guide: string;
  isSignupCateogry2Mandatory: boolean;
  signupCategory3Text: string;
  isSignupCategory3: boolean;
  signupCategory3LookupListId: string;
  signupCategory3Guide: string;
  isSignupCateogry3Mandatory: boolean;
  signupCategory4Text: string;
  isSignupCategory4: boolean;
  signupCategory4LookupListId: string;
  signupCategory4Guide: string;
  isSignupCateogry4Mandatory: boolean;
  signupCategory5Text: string;
  isSignupCategory5: boolean;
  signupCategory5LookupListId: string;
  signupCategory5Guide: string;
  isSignupCateogry5Mandatory: boolean;
  signupCategory6Text: string;
  isSignupCategory6: boolean;
  signupCategory6LookupListId: string;
  signupCategory6Guide: string;
  isSignupCateogry6Mandatory: boolean;
  question1Text: string;
  isQuestion1: boolean;
  question1Type: number;
  options1LookupListId: string;
  validation1: string;
  errorText1: string;
  guideText1: string;
  isNumber1: boolean;
  isQuestion1Mandatory: boolean;
  question2Text: string;
  isQuestion2: boolean;
  question2Type: number;
  options2LookupListId: string;
  validation2: string;
  errorText2: string;
  guideText2: string;
  isNumber2: boolean;
  isQuestion2Mandatory: boolean;
  question3Text: string;
  isQuestion3: boolean;
  question3Type: number;
  options3LookupListId: string;
  validation3: string;
  errorText3: string;
  guideText3: string;
  isNumber3: boolean;
  isQuestion3Mandatory: boolean;
  question4Text: string;
  isQuestion4: boolean;
  question4Type: number;
  options4LookupListId: string;
  validation4: string;
  errorText4: string;
  guideText4: string;
  isNumber4: boolean;
  isQuestion4Mandatory: boolean;
  question5Text: string;
  isQuestion5: boolean;
  question5Type: number;
  options5LookupListId: string;
  validation5: string;
  errorText5: string;
  guideText5: string;
  isNumber5: boolean;
  isQuestion5Mandatory: boolean;
  question6Text: string;
  isQuestion6: boolean;
  question6Type: number;
  options6LookupListId: string;
  validation6: string;
  errorText6: string;
  guideText6: string;
  isNumber6: boolean;
  isQuestion6Mandatory: boolean;
  question7Text: string;
  isQuestion7: boolean;
  question7Type: number;
  options7LookupListId: string;
  validation7: string;
  errorText7: string;
  guideText7: string;
  isNumber7: boolean;
  isQuestion7Mandatory: boolean;
  question8Text: string;
  isQuestion8: boolean;
  question8Type: number;
  options8LookupListId: string;
  validation8: string;
  errorText8: string;
  guideText8: string;
  isNumber8: boolean;
  isQuestion8Mandatory: boolean;
  question9Text: string;
  isQuestion9: boolean;
  question9Type: number;
  options9LookupListId: string;
  validation9: string;
  errorText9: string;
  guideText9: string;
  isNumber9: boolean;
}

export interface FeatureRequestDTO {
  limitToSelfService: boolean;
}

export interface FeatureResponseDTO {
  features: FeatureDTO[];
}

export interface FeatureDTO {
  id: string;
  name: TranslationKey;
  description: TranslationKey;
}

export interface LookupListsDTO {
  lookupLists: LookupListDTO[];
}

export interface LookupListCopyValuesDTO {
  fromLookupListId: string;
  toLookupListId: string;
}

export interface LookupListDTO {
  id: string;
  groupId: string;
  name: string;
  description: string;
}

export interface LookupListItemsDTO {
  status: StatusDTO;
  lookupList: LookupListDTO;
  items: LookupListItemDTO[];
}

export interface LookupListItemDTO {
  id: string;
  listId: string;
  value: string;
  name: string;
   order: number;
   priceCat1: number;
   priceCat2: number;
   priceCat3: number;
   priceCat4: number;
   priceCat5: number;
   priceCat6: number;
   priceCat7: number;
   priceCat8: number;
}

export interface LookupListNewSortOrderDTO {
  listId: string;
  items: LookupListItemSortOrderDTO[];
}

export interface LookupListItemSortOrderDTO {
  id: string;
  order: number;
}

export interface StatusDTO {
  value: StatusOption;
  message: string;
  key: string;
  params: StatusParamDTO[];
}

export interface StatusParamDTO {
  name: string;
  value: string;
}

export interface VersionRequestDTO {
  id: string;
  area: string;
}

export interface VersionResponseDTO {
  id: string;
  version: number;
}

export interface ValueCollectionDTO {
  id: string;
  values: UpdateValueDTO[];
}

export interface UpdateValueDTO {
  field: string;
  value: string;
}

export interface IdBasedRequestDTO {
  id: string;
}

export interface AddEmailRequestDTO {
  groupId: string;
  adminMessage: string;
  isAutoConfirmed: boolean;
  isAddedByAdmin: boolean;
  members: MailingListMemberDTO[];
  baseUrl: string;
}

export interface MailingListMemberDTO {
  id: string;
  email: string;
  language: string;
  doesReceiveAllLanguages: boolean;
  isConfirmed: boolean;
  confirmRequestsSent: number;
  joinedAt: number;
  removedAt: number;
  messagesReceivedCount: number;
}

export interface ConfirmEmailRequestDTO {
  token: string;
}

export interface MailingListMembersRequestDTO {
  groupId: string;
  skip: number;
  limit: number;
  kind: MemberRequestKind;
}

export interface MailingListMembersDTO {
  members: MailingListMemberDTO[];
  count: number;
}

export interface MailingListSendMessageRequestDTO {
  groupId: string;
  limitToLanguage: string;
  areMembersIncluded: boolean;
  areSubscribersIncluded: boolean;
  limitToGroupingIds: string[];
  isPubliclyVisible: boolean;
  subject: string;
  body: string;
  baseUrl: string;
}

export interface MailingListMessagesRequestDTO {
  groupId: string;
  skip: number;
  limit: number;
  kind: MessageRequestKind;
  onlyPublic: boolean;
}

export interface MailingListMessagesDTO {
  messages: MailingListMessageDTO[];
  count: number;
}

export interface MailingListMessageDTO {
  id: string;
  subject: string;
  body: string;
  isLimitedToLanguage: boolean;
  language: string;
  areMembersIncluded: boolean;
  areSubscribersIncluded: boolean;
  isLimitedToGroupings: boolean;
  numberOfRecipients: number;
  sentAt: number;
}

export interface MailingListMessageRecipientsRequestDTO {
  id: string;
}

export interface MailingListRecipientsDTO {
  recipients: MailingListRecipientDTO[];
}

export interface MailingListRecipientDTO {
  email: string;
  memberId: string;
  name: string;
  isMember: boolean;
  isSubscriber: boolean;
}

export interface CmsConfigRequestDTO {
  slug: string;
}

export interface CmsConfigDTO {
  status: StatusDTO;
  groupId: string;
  config: PortalConfigDTO;
  links: LinkDTO[];
  cssVars: CssVarDTO[];
}

export interface CmsUpdateConfigRequestDTO {
  groupId: string;
  config: PortalConfigDTO;
}

export interface CmsPageRequestDTO {
  id: string;
}

export interface CmsPageDTO {
  id: string;
  groupId: string;
  isDraft: boolean;
  isMenuItem: boolean;
  isPublic: boolean;
  slug: string;
  title: string;
  pageType: number;
  content: CmsPageContent;
  parentId: string;
  sortOrder: number;
  metaDescription: string;
  metaKeywords: string;
  updated: number;
  icon: string;
}

export interface CmsCssVarUpdateRequestDTO {
  groupId: string;
  cssVars: CssVarDTO[];
}

export interface LinkDTO {
  id: string;
  parentId: string;
  slug: string;
  title: string;
  isMenuItem: boolean;
  isPublic: boolean;
  isDraft: boolean;
  highlightMenuId: string;
  sortOrder: number;
  pageType: CmsPageType;
  icon: string;
}

export interface PageDTO {
  id: string;
  type: number;
  content: string;
}

export interface CssVarDTO {
  name: string;
  value: string;
}

export interface PortalConfigDTO {
  name: string;
  notFoundContent: string;
  mainBanner: string;
  mainBannerType: MainBannerType;
}

export interface RpcRequest {
  message: string;
}

export interface RpcResponse {
  message: string;
}

export interface UserRequestDTO {
  username: string;
  password: string;
  sessionId: string;
  withUserPermissions: boolean;
  withGroupPermissions: boolean;
}

export interface MinimumUserDTO {
  status: StatusDTO;
  id: string;
  language: string;
  locale: string;
  username: string;
  givenName: string;
  familyName: string;
  permissions: string[];
  groups: MinimumUsersGroupDTO[];
}

export interface UserDTO {
  status: StatusDTO;
  id: string;
  username: string;
  givenName: string;
  familyName: string;
  isCalendarShared: boolean;
  birthYear: number;
  locale: string;
  language: string;
  currentSession: SessionDTO;
  emails: EmailDTO[];
  groupMemberships: GroupMemberDTO[];
  permissions: USER_PERMISSION[];
  groupPermissions: MinimumUsersGroupDTO[];
}

export interface MinimumUsersGroupDTO {
  groupId: string;
  memberId: string;
  permissions: GROUP_PERMISSION[];
}

export interface GroupPermissionRequestDTO {
  userId: string;
  groupId: string;
  permission: string;
  altId: string;
  altType: AltPermissionType;
}

export interface GroupPermissionResponseDTO {
  has: boolean;
  groupId: string;
  memberId: string;
}

export interface EmailDTO {
  id: string;
  email: string;
}

export interface SessionDTO {
  id: string;
  expires: number;
}

export interface UserProfileUpdateRequestDTO {
  id: string;
  givenName: string;
  familyName: string;
  birthYear: number;
  language: string;
  locale: string;
}

export interface GroupAddDTO {
  name: string;
  timezone: string;
  type: GroupType;
}

export interface RestValueCollectionDTO {
  id: string;
  values: UpdateValueDTO[];
}

export interface LoginDTO {
  username: string;
  password: string;
}

export interface SubscribeDTO {
  email: string;
}

export interface SendMessageDTO {
  subject: string;
  body: string;
  isPubliclyVisible: boolean;
}