import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { ToasterService } from '@shared/services/toaster.service';
import { tap } from 'rxjs/operators';
import { GroupService } from 'src/app/group/services/group.service';

@Component({
  selector: 'app-settings',
  templateUrl: './general.component.html',
  styleUrls: ['./general.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralComponent implements OnInit {
  config: FormEditorConfig;

  private form = this.fb.group({
    name: ['', Validators.required],
    shortName: [''],
    slug: ['', Validators.pattern('^([a-z0-9][a-z0-9-]*[a-z0-9])?$')],
  });

  private dict = this.translationService.dict();

  constructor(
    private fb: FormBuilder,
    private currentGroupService: CurrentGroupService,
    private translationService: TranslationService,
    private toasterService: ToasterService,
    private groupService: GroupService
  ) {}

  ngOnInit() {
    this.form.patchValue(this.currentGroupService.currentGroup);
    this.config = this.initConfig();
  }

  private initConfig() {
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      dirtyStateTracker: 'GeneralSettings',
      formFieldClassList: ['full-width'],
      fields: [
        {
          labelKey: TranslationService.KM.UI.GROUP.SETTINGS.GENERAL.GROUP_NAME,
          formControlName: 'name',
        },
        {
          labelKey: TranslationService.KM.UI.GROUP.SETTINGS.GENERAL.SHORT_NAME,
          formControlName: 'shortName',
        },
        {
          labelKey: TranslationService.KM.UI.GROUP.SETTINGS.GENERAL.SLUG,
          formControlName: 'slug',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: this.getSaveCallback(),
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getSaveCallback() {
    const groupId = this.currentGroupService.currentGroupId;
    return () => {
      const data = Object.assign({}, this.form.value);
      return this.groupService.saveGeneral(groupId, data).pipe(
        tap(() => {
          this.toasterService.showSuccess(this.dict.COMMON_UI.OK.SAVED);
        })
      );
    };
  }
}
