import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { AccordionToggleButtonsModule } from '@shared/components/accordion-toggle-buttons/accordion-toggle-buttons.module';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { TabNavModule } from '../tab-nav/tab-nav.module';
import { CalendarComponent } from './calendar/calendar.component';
import { EventTypesComponent } from './event-types/event-types.component';
import { EventsComponent } from './events.component';
import { SignUpFormsComponent } from './sign-up-forms/sign-up-forms.component';

@NgModule({
  declarations: [
    EventsComponent,
    SignUpFormsComponent,
    CalendarComponent,
    EventTypesComponent,
  ],
  imports: [
    CommonModule,
    MatCardModule,
    TabNavModule,
    MatExpansionModule,
    MatButtonModule,
    AccordionToggleButtonsModule,
    FormEditorModule,
    SharedDirectivesModule,
    LoadingButtonModule,
  ],
})
export class EventsModule {}
