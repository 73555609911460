import { Injectable } from '@angular/core';

export type TabId = 'GENERAL' | 'ROUTES' | 'STYLE';

const LOCAL_STORAGE_KEY = 'CMS_MODIFY_MODAL_SERVICE_ACTIVE_TAB_ID';

@Injectable({
  providedIn: 'root',
})
export class CmsModifyModalService {
  constructor() {}

  getActiveTabId(): TabId {
    return (localStorage.getItem(LOCAL_STORAGE_KEY) || 'ROUTES') as TabId;
  }

  setActiveTabId(en: TabId) {
    localStorage.setItem(LOCAL_STORAGE_KEY, en);
  }
}
