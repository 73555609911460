import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { SignUpFormDTO } from '@gen/gen.dto';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class SignUpFormUtils {
  constructor(
    private fb: FormBuilder,
    private dirtyStateTracker: DirtyStateTrackerService,
    private translationService: TranslationService
  ) {}

  getAddFormConfig(
    dirtyStateTracker: string,
    saveCallback: () => Observable<any>
  ): FormEditorConfig {
    const form = this.fb.group({
      name: ['', Validators.required],
    });
    const config: EnrichableFormEditorConfig = {
      dirtyStateTracker,
      form,
      formFieldClassList: ['full-width'],
      classList: ['col-xs-12', 'col-sm-5'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.NAME,
          formControlName: 'name',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: saveCallback,
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getEditFormConfig(
    dirtyStateTracker: string,
    saveCallback: () => Observable<any>
  ): FormEditorConfig {
    const form = this.fb.group({
      name: ['', Validators.required],
      isMessage: [''],
      isNoStatus: [''],
      isMaybeStatus: [''],
      isStatus4: [''],
      status4DefaultText: [''],
      status4Translations: [''],
      isStatus5: [''],
      status5DefaultText: [''],
      status5Translations: [''],
      isStatus6: [''],
      status6DefaultText: [''],
      status6Translations: [''],
    });
    const config: EnrichableFormEditorConfig = {
      dirtyStateTracker,
      form,
      formFieldClassList: ['full-width'],
      classList: ['col-xs-12', 'col-sm-5'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.NAME,
          formControlName: 'name',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MESSAGE,
          formControlName: 'isMessage',
          type: 'slideToggle',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_NO_STATUS,
          formControlName: 'isNoStatus',
          type: 'slideToggle',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .IS_MAYBE_STATUS,
          formControlName: 'isMaybeStatus',
          type: 'slideToggle',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_4,
          formControlName: 'isStatus4',
          type: 'slideToggle',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .CUSTOM_STATUS_TEXT,
          formControlName: 'status4DefaultText',
          showIf: () => form.controls['isStatus4'].value,
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .CUSTOM_STATUS_TRANSLATIONS,
          formControlName: 'status4Translations',
          type: 'textarea',
          showIf: () => form.controls['isStatus4'].value,
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_5,
          formControlName: 'isStatus5',
          type: 'slideToggle',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .CUSTOM_STATUS_TEXT,
          formControlName: 'status5DefaultText',
          showIf: () => form.controls['isStatus5'].value,
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .CUSTOM_STATUS_TRANSLATIONS,
          formControlName: 'status5Translations',
          type: 'textarea',
          showIf: () => form.controls['isStatus5'].value,
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_6,
          formControlName: 'isStatus6',
          type: 'slideToggle',
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .CUSTOM_STATUS_TEXT,
          formControlName: 'status6DefaultText',
          showIf: () => form.controls['isStatus6'].value,
        },
        {
          labelKey:
            TranslationService.KM.UI.GROUP.SETTINGS.SIGN_UP_FORM
              .CUSTOM_STATUS_TRANSLATIONS,
          formControlName: 'status6Translations',
          type: 'textarea',
          showIf: () => form.controls['isStatus6'].value,
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: saveCallback,
        },
      ],
    };

    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getSelectConfig(lists: SignUpFormDTO[], callback: () => Promise<any>) {
    const form = this.fb.group({
      list: [''],
    });
    const config: EnrichableFormEditorConfig = {
      form: form,
      formFieldClassList: ['full-width'],
      fields: [
        [
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SELECT,
            formControlName: 'list',
            type: 'select',
            classList: ['col-xs-8', 'col-sm-4'],
            options: lists.map((i) => {
              return {
                value: i.id,
                label: i.name,
              };
            }),
          },
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.MODIFY,
            type: 'button.loading',
            enableAlways: true,
            classList: ['col-xs-4', 'col-sm-4'],
            callback,
          },
        ],
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }
}
