import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavSecondComponent, LinkElement } from './nav-second.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';

import { ClickOutsideModule } from 'ng-click-outside';

@NgModule({
  declarations: [NavSecondComponent, LinkElement],
  imports: [CommonModule, MatIconModule, MatMenuModule, ClickOutsideModule],
  exports: [NavSecondComponent],
})
export class NavSecondModule {}
