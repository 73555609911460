import { Injectable, ComponentRef, Type } from '@angular/core';
import { OverlayComponent } from '../components/overlay/overlay.component';
import { DomService } from './dom.service';

export enum ModalSize {
  XL,
  L,
  M,
}

export interface IModalConfig {
  allowOverlayClick?: boolean;
  showCloseButton?: boolean;
  size?: ModalSize;
  data?: any;
  whenClosed?: (data: any) => void;
}

@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private openModals: { [idRef: number]: ComponentRef<OverlayComponent> } = {};

  constructor(private domService: DomService) {}

  closeModal(refId: number): void {
    this.domService.removeComponent(refId);
  }

  getModalContentInstance(refId: number): any {
    return !!this.openModals[refId]
      ? this.openModals[refId].instance.componentInstance.instance
      : null;
  }

  private setConfig(refId: number, config: IModalConfig): void {
    this.openModals[refId].instance.allowOverlayClick =
      'allowOverlayClick' in config
        ? !!config.allowOverlayClick
        : this.openModals[refId].instance.allowOverlayClick;

    this.openModals[refId].instance.showCloseButton =
      'showCloseButton' in config
        ? !!config.showCloseButton
        : this.openModals[refId].instance.showCloseButton;

    if ('whenClosed' in config) {
      this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
        if (!!config.whenClosed) {
          config.whenClosed(data);
        }
      });
    }

    let baseData: any = {
      __modalSize: 'size' in config ? config.size : ModalSize.M,
    };
    if ('data' in config) {
      baseData = Object.assign(baseData, config.data);
    }

    this.openModals[refId].instance.data = baseData;
  }

  showModal(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(OverlayComponent);
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayComponent>;
    this.openModals[refId].instance.component = content;
    if (config) {
      this.setConfig(refId, config);
    }

    this.openModals[refId].instance.whenOverlayClicked$.subscribe(() => {
      this.closeModal(refId);
    });
    return refId;
  }
}
