import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/services/api-v1.models';
import {
  CalendarDTO,
  CalendarsDTO,
  EventTypeDTO,
  EventTypesDTO,
  FeatureDTO,
  GroupDTO,
  SignUpFormDetailsDTO,
  SignUpFormDTO,
  SignUpFormsDTO,
  StatusDTO,
  ValueCollectionDTO,
} from '@gen/gen.dto';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupApiService {
  constructor(private http: HttpClient) {}

  // General group data
  saveGeneral(groupId: string, data: Partial<GroupDTO>): Observable<GroupDTO> {
    return this.http.post<GroupDTO>(
      subsToUrl(API.GROUP.SETTINGS.GENERAL, { groupId }),
      data
    );
  }

  // Features
  getEditableFeatures() {
    return this.http.get<FeatureDTO[]>(API.GROUP.AVAILABLE_FEATURES);
  }

  toggleFeature(groupId: string, featureId: string) {
    return this.http.get<StatusDTO>(
      subsToUrl(API.GROUP.SETTINGS.FEATURE, {
        groupId,
        featureId,
      })
    );
  }

  // Calendars
  getCalendars(groupId: string) {
    return this.http.get<CalendarsDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.CALENDARS, { groupId })
    );
  }

  addCalendar(groupId: string, values: ValueCollectionDTO) {
    return this.http.post<CalendarDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.CALENDARS, { groupId }),
      values
    );
  }

  updateCalendar(calendarId: string, values: ValueCollectionDTO) {
    return this.http.put<CalendarDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.CALENDAR, { calendarId }),
      values
    );
  }

  removeCalendar(calendarId: string) {
    return this.http.delete<StatusDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.CALENDAR, { calendarId })
    );
  }

  // Event types
  getEventTypes(groupId: string) {
    return this.http.get<EventTypesDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.EVENT_TYPES, { groupId })
    );
  }

  addEventType(groupId: string, values: ValueCollectionDTO) {
    return this.http.post<EventTypeDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.EVENT_TYPES, { groupId }),
      values
    );
  }

  updateEventType(eventTypeId: string, values: ValueCollectionDTO) {
    return this.http.put<CalendarDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.EVENT_TYPE, { eventTypeId }),
      values
    );
  }

  removeEventType(eventTypeId: string) {
    return this.http.delete<StatusDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.EVENT_TYPE, { eventTypeId })
    );
  }

  // Sign up forms
  getSignUpForms(groupId: string) {
    return this.http.get<SignUpFormsDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS, { groupId })
    );
  }

  getSignUpForm(signUpFormId: string) {
    return this.http.get<SignUpFormDetailsDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.SIGN_UP_FORM, { signUpFormId })
    );
  }

  addSignUpForm(groupId: string, values: ValueCollectionDTO) {
    return this.http.post<SignUpFormDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS, { groupId }),
      values
    );
  }

  updateSignUpForm(signUpFormId: string, values: ValueCollectionDTO) {
    return this.http.put<SignUpFormDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.SIGN_UP_FORM, { signUpFormId }),
      values
    );
  }

  removeSignUpForm(signUpFormId: string) {
    return this.http.delete<StatusDTO>(
      subsToUrl(API.GROUP.SETTINGS.EVENTS.SIGN_UP_FORM, { signUpFormId })
    );
  }
}
