import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { UserService } from '@core/services/user.service';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { ToasterService } from '@shared/services/toaster.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';
import { tap } from 'rxjs/operators';

const MIN_YEAR = new Date().getFullYear() - 110;
const MAX_YEAR = new Date().getFullYear();

@Component({
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileComponent implements OnInit, OnDestroy {
  config: FormEditorConfig;

  private form = this.fb.group({
    username: [{ value: '', disabled: true }],
    givenName: ['', Validators.required],
    familyName: ['', Validators.required],
    locale: ['', Validators.required],
    language: ['', Validators.required],
    birthYear: [
      null,
      Validators.compose([
        Validators.min(MIN_YEAR),
        Validators.max(MAX_YEAR),
        Validators.minLength(4),
        Validators.maxLength(4),
        Validators.required,
      ]),
    ],
  });

  private dict = this.translationService.dict();

  constructor(
    private userService: UserService,
    private fb: FormBuilder,
    private translationService: TranslationService,
    private toasterService: ToasterService
  ) {}

  ngOnInit() {
    this.observeUser();
    this.config = this.getConfig();
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private observeUser() {
    LifeCyclesUtil.sub(this, this.userService.user$, (user) => {
      if (user) {
        this.form.patchValue(user);
      }
    });
  }

  private getConfig() {
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: ['full-width'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.USERNAME,
          formControlName: 'username',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.GIVEN_NAME,
          formControlName: 'givenName',
          required: true,
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.FAMILY_NAME,
          formControlName: 'familyName',
          required: true,
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.BIRTH_YEAR,
          formControlName: 'birthYear',
          required: true,
          error: {
            label: this.getBirthYearErrorLabel(),
          },
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.LANGUAGE,
          formControlName: 'language',
          required: true,
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.LOCALE,
          formControlName: 'locale',
          required: true,
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: this.getSaveCallback(),
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  private getBirthYearErrorLabel() {
    return this.translationService.substitute(
      this.translationService.dict().COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION,
      {
        minYear: MIN_YEAR,
        maxYear: MAX_YEAR,
      }
    );
  }

  private getSaveCallback() {
    return () => {
      const data = Object.assign({}, this.form.value);
      delete data.username;
      data.birthYear = parseInt(data.birthYear);
      return this.userService.saveProfile(data).pipe(
        tap(() => {
          this.toasterService.showSuccess(this.dict.COMMON_UI.OK.SAVED);
        })
      );
    };
  }
}
