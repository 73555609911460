import { FormBuilder, FormGroup, Validators } from '@angular/forms';

export function getForm(fb: FormBuilder): FormGroup {
  return fb.group({
    parentId: ['', Validators.required],
    slug: ['', Validators.pattern('^([a-z0-9][a-z0-9-]*[a-z0-9])?$')],
    title: ['', Validators.required],
    isMenuItem: [true, Validators.required],
    isPublic: [true, Validators.required],
    isDraft: [true, Validators.required],
    sortOrder: [1, Validators.required],
    icon: [''],
    pageType: [0],
  });
}

export function dataFromForm<T>(fg: FormGroup): T {
  const data = Object.assign({}, fg) as any;
  if ('pageType' in data) {
    data.pageType = parseInt(data.pageType);
  }
  return data as T;
}
