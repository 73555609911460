import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSideNavComponent } from './left-side-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';

@NgModule({
  declarations: [LeftSideNavComponent],
  imports: [CommonModule, MatButtonModule, MatListModule, MatIconModule],
  exports: [LeftSideNavComponent],
})
export class LeftSideNavModule {}
