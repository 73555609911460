// This file is automatically generated by create_translations.py. Don't edit this file.

export interface ITranslations {
  COMMON_SERVER: {
    MAILING_LIST: {
      SUBSCRIBE_CONFIRMATION: string;
      SUBSCRIBE_ERROR_EMAIL_NOT_FOUND: string;
      SUBSCRIBE_TITLE: string;
      UNSUBSCRIBE_CONFIRMATION: string;
    };
  };
  COMMON_UI: {
    CONFIRM_ACTION: {
      TEXT: string;
      TITLE: string;
    };
    ERROR: {
      LOGIN: string;
      UNKNOWN: string;
    };
    FEATURE: {
      WEB_PORTAL: string;
      WEB_PORTAL_DESCRIPTION: string;
      WEB_PORTAL_MAILING_LIST: string;
      WEB_PORTAL_MAILING_LIST_DESCRIPTION: string;
    };
    KEY_WORD: {
      ACTIVATE: string;
      ADD: string;
      ADD_NEW: string;
      ALL_GROUPS: string;
      BIRTH_YEAR: string;
      BIRTH_YEAR_VALIDATION: string;
      CANCEL: string;
      COLLAPSE_ALL: string;
      COLOR: string;
      CONFIRM: string;
      DEACTIVATE: string;
      DELETE: string;
      DESCRIPTION: string;
      DISCARD: string;
      EMAIL: string;
      EMAIL_REPEAT: string;
      EXPAND_ALL: string;
      FAMILY_NAME: string;
      GIVEN_NAME: string;
      JOIN: string;
      LANGUAGE: string;
      LOCALE: string;
      LOGIN: string;
      MODIFY: string;
      NAME: string;
      NEW_EVENT: string;
      NEW_GROUP: string;
      ORDER: string;
      PASSWORD: string;
      PREVIEW: string;
      SAVE: string;
      SELECT: string;
      SEND: string;
      SET_AS_DEFAULT: string;
      SUBSCRIBE: string;
      USERNAME: string;
    };
    OK: {
      ADDED: string;
      DELETED: string;
      SAVED: string;
    };
    PERMISSIONS: {
      SEARCH_USER: string;
    };
    UNSAVED_CHANGES: {
      TEXT: string;
      TITLE: string;
    };
  };
  UI: {
    CMS: {
      CONFIG: {
        GENERAL: {
          BANNER_IMAGE: string;
          BANNER_IMAGE_STYLE_FILL: string;
          BANNER_IMAGE_STYLE_HEIGHT: string;
          BANNER_IMAGE_STYLE_TITLE: string;
          PAGE_NOT_FOUND: string;
          WEB_PORTAL_NAME: string;
        };
        ROUTES: {
          CHILD_PAGE: string;
          ICON: string;
          IS_DRAFT: string;
          IS_MENU_ITEM: string;
          IS_PUBLIC: string;
          LEVEL_UP: string;
          MODIFY_PAGE: string;
          NEW_PAGE: string;
          ORDER: string;
          PAGES: string;
          PAGE_TYPE: string;
          PAGE_TYPES: {
            HTML: string;
            MAILING_LIST: string;
          };
          PARENT: string;
          PATH: string;
          SIBLING_PAGE: string;
          SLUG: string;
          TITLE: string;
        };
        STYLES: {
          ACTIVE: string;
          BG: string;
          HOVER: string;
          OPTIONS: {
            BANNER_IMAGE_BG: string;
            LINK_BAR: string;
            SHOW_MORE_ITEMS: string;
            SUB_LINK_BAR: string;
            TOP_BAR: string;
            TOP_BAR_SPACER: string;
          };
          TEXT: string;
        };
        TABS: {
          GENERAL: string;
          ROUTES: string;
          STYLES: string;
        };
      };
      DRAFT: string;
      EDIT: {
        HTML_CONTENT: string;
        META_DESCRIPTION: string;
        META_KEYWORDS: string;
      };
      MAILING_LIST: {
        BODY: string;
        CONFIRMED: string;
        ERRORS: {
          CONFIRM_DOES_NOT_MATCH_EMAIL: string;
          EMAIL_HAS_MAXIMUM_CONFIRMATION_REQUESTS: string;
          EMAIL_IS_ALREADY_SUBSCRIBER: string;
          INVALID_EMAIL: string;
        };
        MESSAGE_FOOTER: string;
        MESSAGE_SENT_OK: string;
        NOT_CONFIRMED: string;
        SEND_MESSAGE: string;
        SENT_MESSAGES: string;
        SHOW_PUBLICLY: string;
        SUBJECT: string;
        SUBSCRIBERS: string;
        SUBSCRIPTION_MESSAGE: string;
        SUBSCRIPTION_OK: string;
      };
      MEMBERS_ONLY: string;
      NO_PAGES: string;
    };
    GROUP: {
      LINKS: {
        CALENDAR: string;
        GDPR: string;
        MEMBERS: string;
        MY_DETAILS: string;
        SETTINGS: string;
        SETTINGS_EVENTS: string;
        SETTINGS_FEATURES: string;
        SETTINGS_LOOKUP_LISTS: string;
        SETTINGS_ROLES: string;
        STATISTICS: string;
        WEB_PORTAL: string;
      };
      SETTINGS: {
        EVENTS: {
          CALENDARS: string;
          CALENDARS_HELP: string;
          EVENT_TYPES: string;
          EVENT_TYPES_HELP: string;
          EVENT_VIEWS: string;
          EVENT_VIEWS_HELP: string;
          SIGN_UP_FORMS: string;
          SIGN_UP_FORMS_HELP: string;
        };
        GENERAL: {
          GROUP_NAME: string;
          SHORT_NAME: string;
          SLUG: string;
        };
        LOOKUPS: {
          ADD_LIST: string;
          LIST_ADDED_OK: string;
          NO_LISTS: string;
        };
        SIGN_UP_FORM: {
          CUSTOM_STATUS_TEXT: string;
          CUSTOM_STATUS_TRANSLATIONS: string;
          IS_MAYBE_STATUS: string;
          IS_MESSAGE: string;
          IS_NO_STATUS: string;
          IS_STATUS_4: string;
          IS_STATUS_5: string;
          IS_STATUS_6: string;
          SET_DEFAULT: string;
        };
      };
    };
    PAGE_TITLES: {
      EVENT_EDITOR_ADD: string;
      EVENT_EDITOR_MODIFY: string;
      LANDING: string;
      LOGIN: string;
      MAIN: string;
      NOT_IMPLEMENTED: string;
      PORTAL: string;
      USER: string;
    };
    VIEWS: {
      SLIDING_MENU: {
        LOGGED_IN_AS: string;
        LOGIN: string;
        LOGOUT: string;
        MY_PROFILE: string;
      };
    };
  };
}


export class Translations implements ITranslations {
  COMMON_SERVER = {
    MAILING_LIST: {
      SUBSCRIBE_CONFIRMATION: "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_CONFIRMATION" as TranslationKey,
      SUBSCRIBE_ERROR_EMAIL_NOT_FOUND: "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_ERROR_EMAIL_NOT_FOUND" as TranslationKey,
      SUBSCRIBE_TITLE: "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_TITLE" as TranslationKey,
      UNSUBSCRIBE_CONFIRMATION: "COMMON_SERVER.MAILING_LIST.UNSUBSCRIBE_CONFIRMATION" as TranslationKey,
    },
  };
  COMMON_UI = {
    CONFIRM_ACTION: {
      TEXT: "COMMON_UI.CONFIRM_ACTION.TEXT" as TranslationKey,
      TITLE: "COMMON_UI.CONFIRM_ACTION.TITLE" as TranslationKey,
    },
    ERROR: {
      LOGIN: "COMMON_UI.ERROR.LOGIN" as TranslationKey,
      UNKNOWN: "COMMON_UI.ERROR.UNKNOWN" as TranslationKey,
    },
    FEATURE: {
      WEB_PORTAL: "COMMON_UI.FEATURE.WEB_PORTAL" as TranslationKey,
      WEB_PORTAL_DESCRIPTION: "COMMON_UI.FEATURE.WEB_PORTAL_DESCRIPTION" as TranslationKey,
      WEB_PORTAL_MAILING_LIST: "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST" as TranslationKey,
      WEB_PORTAL_MAILING_LIST_DESCRIPTION: "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST_DESCRIPTION" as TranslationKey,
    },
    KEY_WORD: {
      ACTIVATE: "COMMON_UI.KEY_WORD.ACTIVATE" as TranslationKey,
      ADD: "COMMON_UI.KEY_WORD.ADD" as TranslationKey,
      ADD_NEW: "COMMON_UI.KEY_WORD.ADD_NEW" as TranslationKey,
      ALL_GROUPS: "COMMON_UI.KEY_WORD.ALL_GROUPS" as TranslationKey,
      BIRTH_YEAR: "COMMON_UI.KEY_WORD.BIRTH_YEAR" as TranslationKey,
      BIRTH_YEAR_VALIDATION: "COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION" as TranslationKey,
      CANCEL: "COMMON_UI.KEY_WORD.CANCEL" as TranslationKey,
      COLLAPSE_ALL: "COMMON_UI.KEY_WORD.COLLAPSE_ALL" as TranslationKey,
      COLOR: "COMMON_UI.KEY_WORD.COLOR" as TranslationKey,
      CONFIRM: "COMMON_UI.KEY_WORD.CONFIRM" as TranslationKey,
      DEACTIVATE: "COMMON_UI.KEY_WORD.DEACTIVATE" as TranslationKey,
      DELETE: "COMMON_UI.KEY_WORD.DELETE" as TranslationKey,
      DESCRIPTION: "COMMON_UI.KEY_WORD.DESCRIPTION" as TranslationKey,
      DISCARD: "COMMON_UI.KEY_WORD.DISCARD" as TranslationKey,
      EMAIL: "COMMON_UI.KEY_WORD.EMAIL" as TranslationKey,
      EMAIL_REPEAT: "COMMON_UI.KEY_WORD.EMAIL_REPEAT" as TranslationKey,
      EXPAND_ALL: "COMMON_UI.KEY_WORD.EXPAND_ALL" as TranslationKey,
      FAMILY_NAME: "COMMON_UI.KEY_WORD.FAMILY_NAME" as TranslationKey,
      GIVEN_NAME: "COMMON_UI.KEY_WORD.GIVEN_NAME" as TranslationKey,
      JOIN: "COMMON_UI.KEY_WORD.JOIN" as TranslationKey,
      LANGUAGE: "COMMON_UI.KEY_WORD.LANGUAGE" as TranslationKey,
      LOCALE: "COMMON_UI.KEY_WORD.LOCALE" as TranslationKey,
      LOGIN: "COMMON_UI.KEY_WORD.LOGIN" as TranslationKey,
      MODIFY: "COMMON_UI.KEY_WORD.MODIFY" as TranslationKey,
      NAME: "COMMON_UI.KEY_WORD.NAME" as TranslationKey,
      NEW_EVENT: "COMMON_UI.KEY_WORD.NEW_EVENT" as TranslationKey,
      NEW_GROUP: "COMMON_UI.KEY_WORD.NEW_GROUP" as TranslationKey,
      ORDER: "COMMON_UI.KEY_WORD.ORDER" as TranslationKey,
      PASSWORD: "COMMON_UI.KEY_WORD.PASSWORD" as TranslationKey,
      PREVIEW: "COMMON_UI.KEY_WORD.PREVIEW" as TranslationKey,
      SAVE: "COMMON_UI.KEY_WORD.SAVE" as TranslationKey,
      SELECT: "COMMON_UI.KEY_WORD.SELECT" as TranslationKey,
      SEND: "COMMON_UI.KEY_WORD.SEND" as TranslationKey,
      SET_AS_DEFAULT: "COMMON_UI.KEY_WORD.SET_AS_DEFAULT" as TranslationKey,
      SUBSCRIBE: "COMMON_UI.KEY_WORD.SUBSCRIBE" as TranslationKey,
      USERNAME: "COMMON_UI.KEY_WORD.USERNAME" as TranslationKey,
    },
    OK: {
      ADDED: "COMMON_UI.OK.ADDED" as TranslationKey,
      DELETED: "COMMON_UI.OK.DELETED" as TranslationKey,
      SAVED: "COMMON_UI.OK.SAVED" as TranslationKey,
    },
    PERMISSIONS: {
      SEARCH_USER: "COMMON_UI.PERMISSIONS.SEARCH_USER" as TranslationKey,
    },
    UNSAVED_CHANGES: {
      TEXT: "COMMON_UI.UNSAVED_CHANGES.TEXT" as TranslationKey,
      TITLE: "COMMON_UI.UNSAVED_CHANGES.TITLE" as TranslationKey,
    },
  };
  UI = {
    CMS: {
      CONFIG: {
        GENERAL: {
          BANNER_IMAGE: "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE" as TranslationKey,
          BANNER_IMAGE_STYLE_FILL: "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE_STYLE_FILL" as TranslationKey,
          BANNER_IMAGE_STYLE_HEIGHT: "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE_STYLE_HEIGHT" as TranslationKey,
          BANNER_IMAGE_STYLE_TITLE: "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE_STYLE_TITLE" as TranslationKey,
          PAGE_NOT_FOUND: "UI.CMS.CONFIG.GENERAL.PAGE_NOT_FOUND" as TranslationKey,
          WEB_PORTAL_NAME: "UI.CMS.CONFIG.GENERAL.WEB_PORTAL_NAME" as TranslationKey,
        },
        ROUTES: {
          CHILD_PAGE: "UI.CMS.CONFIG.ROUTES.CHILD_PAGE" as TranslationKey,
          ICON: "UI.CMS.CONFIG.ROUTES.ICON" as TranslationKey,
          IS_DRAFT: "UI.CMS.CONFIG.ROUTES.IS_DRAFT" as TranslationKey,
          IS_MENU_ITEM: "UI.CMS.CONFIG.ROUTES.IS_MENU_ITEM" as TranslationKey,
          IS_PUBLIC: "UI.CMS.CONFIG.ROUTES.IS_PUBLIC" as TranslationKey,
          LEVEL_UP: "UI.CMS.CONFIG.ROUTES.LEVEL_UP" as TranslationKey,
          MODIFY_PAGE: "UI.CMS.CONFIG.ROUTES.MODIFY_PAGE" as TranslationKey,
          NEW_PAGE: "UI.CMS.CONFIG.ROUTES.NEW_PAGE" as TranslationKey,
          ORDER: "UI.CMS.CONFIG.ROUTES.ORDER" as TranslationKey,
          PAGES: "UI.CMS.CONFIG.ROUTES.PAGES" as TranslationKey,
          PAGE_TYPE: "UI.CMS.CONFIG.ROUTES.PAGE_TYPE" as TranslationKey,
          PAGE_TYPES: {
            HTML: "UI.CMS.CONFIG.ROUTES.PAGE_TYPES.HTML" as TranslationKey,
            MAILING_LIST: "UI.CMS.CONFIG.ROUTES.PAGE_TYPES.MAILING_LIST" as TranslationKey,
          },
          PARENT: "UI.CMS.CONFIG.ROUTES.PARENT" as TranslationKey,
          PATH: "UI.CMS.CONFIG.ROUTES.PATH" as TranslationKey,
          SIBLING_PAGE: "UI.CMS.CONFIG.ROUTES.SIBLING_PAGE" as TranslationKey,
          SLUG: "UI.CMS.CONFIG.ROUTES.SLUG" as TranslationKey,
          TITLE: "UI.CMS.CONFIG.ROUTES.TITLE" as TranslationKey,
        },
        STYLES: {
          ACTIVE: "UI.CMS.CONFIG.STYLES.ACTIVE" as TranslationKey,
          BG: "UI.CMS.CONFIG.STYLES.BG" as TranslationKey,
          HOVER: "UI.CMS.CONFIG.STYLES.HOVER" as TranslationKey,
          OPTIONS: {
            BANNER_IMAGE_BG: "UI.CMS.CONFIG.STYLES.OPTIONS.BANNER_IMAGE_BG" as TranslationKey,
            LINK_BAR: "UI.CMS.CONFIG.STYLES.OPTIONS.LINK_BAR" as TranslationKey,
            SHOW_MORE_ITEMS: "UI.CMS.CONFIG.STYLES.OPTIONS.SHOW_MORE_ITEMS" as TranslationKey,
            SUB_LINK_BAR: "UI.CMS.CONFIG.STYLES.OPTIONS.SUB_LINK_BAR" as TranslationKey,
            TOP_BAR: "UI.CMS.CONFIG.STYLES.OPTIONS.TOP_BAR" as TranslationKey,
            TOP_BAR_SPACER: "UI.CMS.CONFIG.STYLES.OPTIONS.TOP_BAR_SPACER" as TranslationKey,
          },
          TEXT: "UI.CMS.CONFIG.STYLES.TEXT" as TranslationKey,
        },
        TABS: {
          GENERAL: "UI.CMS.CONFIG.TABS.GENERAL" as TranslationKey,
          ROUTES: "UI.CMS.CONFIG.TABS.ROUTES" as TranslationKey,
          STYLES: "UI.CMS.CONFIG.TABS.STYLES" as TranslationKey,
        },
      },
      DRAFT: "UI.CMS.DRAFT" as TranslationKey,
      EDIT: {
        HTML_CONTENT: "UI.CMS.EDIT.HTML_CONTENT" as TranslationKey,
        META_DESCRIPTION: "UI.CMS.EDIT.META_DESCRIPTION" as TranslationKey,
        META_KEYWORDS: "UI.CMS.EDIT.META_KEYWORDS" as TranslationKey,
      },
      MAILING_LIST: {
        BODY: "UI.CMS.MAILING_LIST.BODY" as TranslationKey,
        CONFIRMED: "UI.CMS.MAILING_LIST.CONFIRMED" as TranslationKey,
        ERRORS: {
          CONFIRM_DOES_NOT_MATCH_EMAIL: "UI.CMS.MAILING_LIST.ERRORS.CONFIRM_DOES_NOT_MATCH_EMAIL" as TranslationKey,
          EMAIL_HAS_MAXIMUM_CONFIRMATION_REQUESTS: "UI.CMS.MAILING_LIST.ERRORS.EMAIL_HAS_MAXIMUM_CONFIRMATION_REQUESTS" as TranslationKey,
          EMAIL_IS_ALREADY_SUBSCRIBER: "UI.CMS.MAILING_LIST.ERRORS.EMAIL_IS_ALREADY_SUBSCRIBER" as TranslationKey,
          INVALID_EMAIL: "UI.CMS.MAILING_LIST.ERRORS.INVALID_EMAIL" as TranslationKey,
        },
        MESSAGE_FOOTER: "UI.CMS.MAILING_LIST.MESSAGE_FOOTER" as TranslationKey,
        MESSAGE_SENT_OK: "UI.CMS.MAILING_LIST.MESSAGE_SENT_OK" as TranslationKey,
        NOT_CONFIRMED: "UI.CMS.MAILING_LIST.NOT_CONFIRMED" as TranslationKey,
        SEND_MESSAGE: "UI.CMS.MAILING_LIST.SEND_MESSAGE" as TranslationKey,
        SENT_MESSAGES: "UI.CMS.MAILING_LIST.SENT_MESSAGES" as TranslationKey,
        SHOW_PUBLICLY: "UI.CMS.MAILING_LIST.SHOW_PUBLICLY" as TranslationKey,
        SUBJECT: "UI.CMS.MAILING_LIST.SUBJECT" as TranslationKey,
        SUBSCRIBERS: "UI.CMS.MAILING_LIST.SUBSCRIBERS" as TranslationKey,
        SUBSCRIPTION_MESSAGE: "UI.CMS.MAILING_LIST.SUBSCRIPTION_MESSAGE" as TranslationKey,
        SUBSCRIPTION_OK: "UI.CMS.MAILING_LIST.SUBSCRIPTION_OK" as TranslationKey,
      },
      MEMBERS_ONLY: "UI.CMS.MEMBERS_ONLY" as TranslationKey,
      NO_PAGES: "UI.CMS.NO_PAGES" as TranslationKey,
    },
    GROUP: {
      LINKS: {
        CALENDAR: "UI.GROUP.LINKS.CALENDAR" as TranslationKey,
        GDPR: "UI.GROUP.LINKS.GDPR" as TranslationKey,
        MEMBERS: "UI.GROUP.LINKS.MEMBERS" as TranslationKey,
        MY_DETAILS: "UI.GROUP.LINKS.MY_DETAILS" as TranslationKey,
        SETTINGS: "UI.GROUP.LINKS.SETTINGS" as TranslationKey,
        SETTINGS_EVENTS: "UI.GROUP.LINKS.SETTINGS_EVENTS" as TranslationKey,
        SETTINGS_FEATURES: "UI.GROUP.LINKS.SETTINGS_FEATURES" as TranslationKey,
        SETTINGS_LOOKUP_LISTS: "UI.GROUP.LINKS.SETTINGS_LOOKUP_LISTS" as TranslationKey,
        SETTINGS_ROLES: "UI.GROUP.LINKS.SETTINGS_ROLES" as TranslationKey,
        STATISTICS: "UI.GROUP.LINKS.STATISTICS" as TranslationKey,
        WEB_PORTAL: "UI.GROUP.LINKS.WEB_PORTAL" as TranslationKey,
      },
      SETTINGS: {
        EVENTS: {
          CALENDARS: "UI.GROUP.SETTINGS.EVENTS.CALENDARS" as TranslationKey,
          CALENDARS_HELP: "UI.GROUP.SETTINGS.EVENTS.CALENDARS_HELP" as TranslationKey,
          EVENT_TYPES: "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES" as TranslationKey,
          EVENT_TYPES_HELP: "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES_HELP" as TranslationKey,
          EVENT_VIEWS: "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS" as TranslationKey,
          EVENT_VIEWS_HELP: "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS_HELP" as TranslationKey,
          SIGN_UP_FORMS: "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS" as TranslationKey,
          SIGN_UP_FORMS_HELP: "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS_HELP" as TranslationKey,
        },
        GENERAL: {
          GROUP_NAME: "UI.GROUP.SETTINGS.GENERAL.GROUP_NAME" as TranslationKey,
          SHORT_NAME: "UI.GROUP.SETTINGS.GENERAL.SHORT_NAME" as TranslationKey,
          SLUG: "UI.GROUP.SETTINGS.GENERAL.SLUG" as TranslationKey,
        },
        LOOKUPS: {
          ADD_LIST: "UI.GROUP.SETTINGS.LOOKUPS.ADD_LIST" as TranslationKey,
          LIST_ADDED_OK: "UI.GROUP.SETTINGS.LOOKUPS.LIST_ADDED_OK" as TranslationKey,
          NO_LISTS: "UI.GROUP.SETTINGS.LOOKUPS.NO_LISTS" as TranslationKey,
        },
        SIGN_UP_FORM: {
          CUSTOM_STATUS_TEXT: "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TEXT" as TranslationKey,
          CUSTOM_STATUS_TRANSLATIONS: "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TRANSLATIONS" as TranslationKey,
          IS_MAYBE_STATUS: "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MAYBE_STATUS" as TranslationKey,
          IS_MESSAGE: "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MESSAGE" as TranslationKey,
          IS_NO_STATUS: "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_NO_STATUS" as TranslationKey,
          IS_STATUS_4: "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_4" as TranslationKey,
          IS_STATUS_5: "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_5" as TranslationKey,
          IS_STATUS_6: "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_6" as TranslationKey,
          SET_DEFAULT: "UI.GROUP.SETTINGS.SIGN_UP_FORM.SET_DEFAULT" as TranslationKey,
        },
      },
    },
    PAGE_TITLES: {
      EVENT_EDITOR_ADD: "UI.PAGE_TITLES.EVENT_EDITOR_ADD" as TranslationKey,
      EVENT_EDITOR_MODIFY: "UI.PAGE_TITLES.EVENT_EDITOR_MODIFY" as TranslationKey,
      LANDING: "UI.PAGE_TITLES.LANDING" as TranslationKey,
      LOGIN: "UI.PAGE_TITLES.LOGIN" as TranslationKey,
      MAIN: "UI.PAGE_TITLES.MAIN" as TranslationKey,
      NOT_IMPLEMENTED: "UI.PAGE_TITLES.NOT_IMPLEMENTED" as TranslationKey,
      PORTAL: "UI.PAGE_TITLES.PORTAL" as TranslationKey,
      USER: "UI.PAGE_TITLES.USER" as TranslationKey,
    },
    VIEWS: {
      SLIDING_MENU: {
        LOGGED_IN_AS: "UI.VIEWS.SLIDING_MENU.LOGGED_IN_AS" as TranslationKey,
        LOGIN: "UI.VIEWS.SLIDING_MENU.LOGIN" as TranslationKey,
        LOGOUT: "UI.VIEWS.SLIDING_MENU.LOGOUT" as TranslationKey,
        MY_PROFILE: "UI.VIEWS.SLIDING_MENU.MY_PROFILE" as TranslationKey,
      },
    },
  };
}

export type TranslationKey = 
  |"COMMON_SERVER.MAILING_LIST.SUBSCRIBE_CONFIRMATION"
  | "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_ERROR_EMAIL_NOT_FOUND"
  | "COMMON_SERVER.MAILING_LIST.SUBSCRIBE_TITLE"
  | "COMMON_SERVER.MAILING_LIST.UNSUBSCRIBE_CONFIRMATION"
  | "COMMON_UI.CONFIRM_ACTION.TEXT"
  | "COMMON_UI.CONFIRM_ACTION.TITLE"
  | "COMMON_UI.ERROR.LOGIN"
  | "COMMON_UI.ERROR.UNKNOWN"
  | "COMMON_UI.FEATURE.WEB_PORTAL"
  | "COMMON_UI.FEATURE.WEB_PORTAL_DESCRIPTION"
  | "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST"
  | "COMMON_UI.FEATURE.WEB_PORTAL_MAILING_LIST_DESCRIPTION"
  | "COMMON_UI.KEY_WORD.ACTIVATE"
  | "COMMON_UI.KEY_WORD.ADD"
  | "COMMON_UI.KEY_WORD.ADD_NEW"
  | "COMMON_UI.KEY_WORD.ALL_GROUPS"
  | "COMMON_UI.KEY_WORD.BIRTH_YEAR"
  | "COMMON_UI.KEY_WORD.BIRTH_YEAR_VALIDATION"
  | "COMMON_UI.KEY_WORD.CANCEL"
  | "COMMON_UI.KEY_WORD.COLLAPSE_ALL"
  | "COMMON_UI.KEY_WORD.COLOR"
  | "COMMON_UI.KEY_WORD.CONFIRM"
  | "COMMON_UI.KEY_WORD.DEACTIVATE"
  | "COMMON_UI.KEY_WORD.DELETE"
  | "COMMON_UI.KEY_WORD.DESCRIPTION"
  | "COMMON_UI.KEY_WORD.DISCARD"
  | "COMMON_UI.KEY_WORD.EMAIL"
  | "COMMON_UI.KEY_WORD.EMAIL_REPEAT"
  | "COMMON_UI.KEY_WORD.EXPAND_ALL"
  | "COMMON_UI.KEY_WORD.FAMILY_NAME"
  | "COMMON_UI.KEY_WORD.GIVEN_NAME"
  | "COMMON_UI.KEY_WORD.JOIN"
  | "COMMON_UI.KEY_WORD.LANGUAGE"
  | "COMMON_UI.KEY_WORD.LOCALE"
  | "COMMON_UI.KEY_WORD.LOGIN"
  | "COMMON_UI.KEY_WORD.MODIFY"
  | "COMMON_UI.KEY_WORD.NAME"
  | "COMMON_UI.KEY_WORD.NEW_EVENT"
  | "COMMON_UI.KEY_WORD.NEW_GROUP"
  | "COMMON_UI.KEY_WORD.ORDER"
  | "COMMON_UI.KEY_WORD.PASSWORD"
  | "COMMON_UI.KEY_WORD.PREVIEW"
  | "COMMON_UI.KEY_WORD.SAVE"
  | "COMMON_UI.KEY_WORD.SELECT"
  | "COMMON_UI.KEY_WORD.SEND"
  | "COMMON_UI.KEY_WORD.SET_AS_DEFAULT"
  | "COMMON_UI.KEY_WORD.SUBSCRIBE"
  | "COMMON_UI.KEY_WORD.USERNAME"
  | "COMMON_UI.OK.ADDED"
  | "COMMON_UI.OK.DELETED"
  | "COMMON_UI.OK.SAVED"
  | "COMMON_UI.PERMISSIONS.SEARCH_USER"
  | "COMMON_UI.UNSAVED_CHANGES.TEXT"
  | "COMMON_UI.UNSAVED_CHANGES.TITLE"
  | "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE"
  | "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE_STYLE_FILL"
  | "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE_STYLE_HEIGHT"
  | "UI.CMS.CONFIG.GENERAL.BANNER_IMAGE_STYLE_TITLE"
  | "UI.CMS.CONFIG.GENERAL.PAGE_NOT_FOUND"
  | "UI.CMS.CONFIG.GENERAL.WEB_PORTAL_NAME"
  | "UI.CMS.CONFIG.ROUTES.CHILD_PAGE"
  | "UI.CMS.CONFIG.ROUTES.ICON"
  | "UI.CMS.CONFIG.ROUTES.IS_DRAFT"
  | "UI.CMS.CONFIG.ROUTES.IS_MENU_ITEM"
  | "UI.CMS.CONFIG.ROUTES.IS_PUBLIC"
  | "UI.CMS.CONFIG.ROUTES.LEVEL_UP"
  | "UI.CMS.CONFIG.ROUTES.MODIFY_PAGE"
  | "UI.CMS.CONFIG.ROUTES.NEW_PAGE"
  | "UI.CMS.CONFIG.ROUTES.ORDER"
  | "UI.CMS.CONFIG.ROUTES.PAGES"
  | "UI.CMS.CONFIG.ROUTES.PAGE_TYPE"
  | "UI.CMS.CONFIG.ROUTES.PAGE_TYPES.HTML"
  | "UI.CMS.CONFIG.ROUTES.PAGE_TYPES.MAILING_LIST"
  | "UI.CMS.CONFIG.ROUTES.PARENT"
  | "UI.CMS.CONFIG.ROUTES.PATH"
  | "UI.CMS.CONFIG.ROUTES.SIBLING_PAGE"
  | "UI.CMS.CONFIG.ROUTES.SLUG"
  | "UI.CMS.CONFIG.ROUTES.TITLE"
  | "UI.CMS.CONFIG.STYLES.ACTIVE"
  | "UI.CMS.CONFIG.STYLES.BG"
  | "UI.CMS.CONFIG.STYLES.HOVER"
  | "UI.CMS.CONFIG.STYLES.OPTIONS.BANNER_IMAGE_BG"
  | "UI.CMS.CONFIG.STYLES.OPTIONS.LINK_BAR"
  | "UI.CMS.CONFIG.STYLES.OPTIONS.SHOW_MORE_ITEMS"
  | "UI.CMS.CONFIG.STYLES.OPTIONS.SUB_LINK_BAR"
  | "UI.CMS.CONFIG.STYLES.OPTIONS.TOP_BAR"
  | "UI.CMS.CONFIG.STYLES.OPTIONS.TOP_BAR_SPACER"
  | "UI.CMS.CONFIG.STYLES.TEXT"
  | "UI.CMS.CONFIG.TABS.GENERAL"
  | "UI.CMS.CONFIG.TABS.ROUTES"
  | "UI.CMS.CONFIG.TABS.STYLES"
  | "UI.CMS.DRAFT"
  | "UI.CMS.EDIT.HTML_CONTENT"
  | "UI.CMS.EDIT.META_DESCRIPTION"
  | "UI.CMS.EDIT.META_KEYWORDS"
  | "UI.CMS.MAILING_LIST.BODY"
  | "UI.CMS.MAILING_LIST.CONFIRMED"
  | "UI.CMS.MAILING_LIST.ERRORS.CONFIRM_DOES_NOT_MATCH_EMAIL"
  | "UI.CMS.MAILING_LIST.ERRORS.EMAIL_HAS_MAXIMUM_CONFIRMATION_REQUESTS"
  | "UI.CMS.MAILING_LIST.ERRORS.EMAIL_IS_ALREADY_SUBSCRIBER"
  | "UI.CMS.MAILING_LIST.ERRORS.INVALID_EMAIL"
  | "UI.CMS.MAILING_LIST.MESSAGE_FOOTER"
  | "UI.CMS.MAILING_LIST.MESSAGE_SENT_OK"
  | "UI.CMS.MAILING_LIST.NOT_CONFIRMED"
  | "UI.CMS.MAILING_LIST.SEND_MESSAGE"
  | "UI.CMS.MAILING_LIST.SENT_MESSAGES"
  | "UI.CMS.MAILING_LIST.SHOW_PUBLICLY"
  | "UI.CMS.MAILING_LIST.SUBJECT"
  | "UI.CMS.MAILING_LIST.SUBSCRIBERS"
  | "UI.CMS.MAILING_LIST.SUBSCRIPTION_MESSAGE"
  | "UI.CMS.MAILING_LIST.SUBSCRIPTION_OK"
  | "UI.CMS.MEMBERS_ONLY"
  | "UI.CMS.NO_PAGES"
  | "UI.GROUP.LINKS.CALENDAR"
  | "UI.GROUP.LINKS.GDPR"
  | "UI.GROUP.LINKS.MEMBERS"
  | "UI.GROUP.LINKS.MY_DETAILS"
  | "UI.GROUP.LINKS.SETTINGS"
  | "UI.GROUP.LINKS.SETTINGS_EVENTS"
  | "UI.GROUP.LINKS.SETTINGS_FEATURES"
  | "UI.GROUP.LINKS.SETTINGS_LOOKUP_LISTS"
  | "UI.GROUP.LINKS.SETTINGS_ROLES"
  | "UI.GROUP.LINKS.STATISTICS"
  | "UI.GROUP.LINKS.WEB_PORTAL"
  | "UI.GROUP.SETTINGS.EVENTS.CALENDARS"
  | "UI.GROUP.SETTINGS.EVENTS.CALENDARS_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS"
  | "UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS_HELP"
  | "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS"
  | "UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS_HELP"
  | "UI.GROUP.SETTINGS.GENERAL.GROUP_NAME"
  | "UI.GROUP.SETTINGS.GENERAL.SHORT_NAME"
  | "UI.GROUP.SETTINGS.GENERAL.SLUG"
  | "UI.GROUP.SETTINGS.LOOKUPS.ADD_LIST"
  | "UI.GROUP.SETTINGS.LOOKUPS.LIST_ADDED_OK"
  | "UI.GROUP.SETTINGS.LOOKUPS.NO_LISTS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TEXT"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.CUSTOM_STATUS_TRANSLATIONS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MAYBE_STATUS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_MESSAGE"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_NO_STATUS"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_4"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_5"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.IS_STATUS_6"
  | "UI.GROUP.SETTINGS.SIGN_UP_FORM.SET_DEFAULT"
  | "UI.PAGE_TITLES.EVENT_EDITOR_ADD"
  | "UI.PAGE_TITLES.EVENT_EDITOR_MODIFY"
  | "UI.PAGE_TITLES.LANDING"
  | "UI.PAGE_TITLES.LOGIN"
  | "UI.PAGE_TITLES.MAIN"
  | "UI.PAGE_TITLES.NOT_IMPLEMENTED"
  | "UI.PAGE_TITLES.PORTAL"
  | "UI.PAGE_TITLES.USER"
  | "UI.VIEWS.SLIDING_MENU.LOGGED_IN_AS"
  | "UI.VIEWS.SLIDING_MENU.LOGIN"
  | "UI.VIEWS.SLIDING_MENU.LOGOUT"
  | "UI.VIEWS.SLIDING_MENU.MY_PROFILE";
