import { Route } from '@angular/compiler/src/core';
import { Routes } from '@angular/router';
import { MainComponent as CmsMainComponent } from '@cms/components/main/main.component';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { ROUTES } from '@core/services/nav.models';
import { CanActivateWithTranslations } from '@core/services/translation.guard';
import { UserService } from '@core/services/user.service';
import { NewGroupComponent } from '@group/components/new-group/new-group.component';
import { ContainerComponent } from '@group/components/settings/container/container.component';
import { EventsComponent } from '@group/components/settings/events/events.component';
import { FeaturesComponent } from '@group/components/settings/features/features.component';
import { GeneralComponent } from '@group/components/settings/general/general.component';
import { LookupsComponent } from '@group/components/settings/lookups/lookups.component';
import { EventEditorComponent } from '@group/events/components/editor/event-editor.component';
// All route components
import { MainComponent } from '@landing/components/main/main.component';
import { CanActivateWithCurrentGroup } from '@shared/services/current-group.guard';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { LoginComponent } from '@user/components/login/login.component';
import { ProfileComponent } from '@user/components/profile/profile.component';

const VALID_ROUTE_IDENTIFIER = new RegExp(/^[A-Z_]*$/);

export class RouteCreator {
  private static enrichRoutes(r: typeof ROUTES) {
    r.PUB.canActivateChild = [CanActivateWithTranslations];
    r.PUB.HOME.component = MainComponent;
    r.PUB.LOGIN.component = LoginComponent;
    r.PRI.canActivateChild = [UserService, CanActivateWithTranslations];
    r.PRI.ME.component = ProfileComponent;
    r.PRI.NEW_GROUP.component = NewGroupComponent;
    r.PRI.GROUP.SETTINGS.component = ContainerComponent;
    r.PRI.GROUP.SETTINGS.data = Object.assign(r.PRI.GROUP.SETTINGS.data, {
      containerRoutes: {
        [ROUTES.PRI.GROUP.SETTINGS.GENERAL.url]: GeneralComponent,
        [ROUTES.PRI.GROUP.SETTINGS.EVENTS.url]: EventsComponent,
        [ROUTES.PRI.GROUP.SETTINGS.FEATURES.url]: FeaturesComponent,
        [ROUTES.PRI.GROUP.SETTINGS.LOOKUP_LISTS.url]: LookupsComponent,
      },
    });
    ((r.PRI.GROUP
      .SETTINGS as unknown) as any).childComponent = ContainerComponent;
    r.PRI.GROUP.canActivateChild = [CanActivateWithCurrentGroup];
    r.PRI.GROUP.EVENTS.EDITOR_NEW.component = EventEditorComponent;
  }

  static getRoute(route: any) {
    return route.fullPath as string;
  }

  static getRoutes(): Routes {
    this.enrichRoutes(ROUTES);
    const routes: Routes = [
      ...this.getRecursiveRoutesNoChildRoutes(ROUTES, [
        'UI.PAGE_TITLES.PORTAL',
      ]),
      {
        path: 'pub/not-found',
        component: NotFoundComponent,
      },
      {
        path: '**',
        component: CmsMainComponent,
        data: {
          isCms: true,
          routeReUse: 'CMS.MAIN',
        },
        canDeactivate: [DirtyStateTrackerService],
      },
    ];
    return routes;
  }

  private static getRecursiveRoutesNoChildRoutes(
    structure: any,
    title: string[],
    childData: any = {},
    canActivateChild: any[] = []
  ): Routes {
    let routes: Routes = [];
    if ('component' in structure && !structure.notRoute) {
      let data = childData;
      if ('data' in structure) {
        data = { ...data, ...structure.data };
      }
      let canActivate = canActivateChild;
      if ('canActivate' in structure) {
        canActivate = structure.canActivate;
      }
      const obj = {
        path: structure.url.substr(1),
        data: data,
        canActivate: canActivate,
        component: structure.component,
        canDeactivate: [DirtyStateTrackerService],
      } as Route;
      routes.push(obj);
    }
    const newTitle = [...title];
    if ('title' in structure) {
      newTitle.push(structure.title);
    }
    for (const prop in structure) {
      if (prop.match(VALID_ROUTE_IDENTIFIER)) {
        if ('childComponent' in structure) {
          structure[prop].component = structure.childComponent;
          structure[prop].childComponent = structure.childComponent;
        }
        routes = [
          ...routes,
          ...this.getRecursiveRoutesNoChildRoutes(
            structure[prop],
            title,
            {
              ...childData,
              ...(structure.childData || {}),
            },
            [...canActivateChild, ...(structure.canActivateChild || [])]
          ),
        ];
      }
    }
    return routes;
  }

  // This is for getting routes WITH child routes
  private static getRecursiveRoutes(
    structure: any,
    title: string[],
    childData: any = {},
    isFirst = true
  ): Routes | Route {
    const obj = {
      path: structure.path,
    } as any;
    if ('component' in structure) {
      let data = childData;
      if ('data' in structure) {
        data = { ...data, ...structure.data };
      }
      obj.data = data;
      if ('canActivate' in structure) {
        obj.canActivate = structure.canActivate;
      }
      obj.component = structure.component;
    }
    const newTitle = [...title];
    if ('title' in structure) {
      newTitle.push(structure.title);
    }
    if ('canActivateChild' in structure) {
      // obj.canActivateChild = structure.canActivateChild;
    }
    let childRoutes: Routes = [];
    for (const prop in structure) {
      if (prop.match(VALID_ROUTE_IDENTIFIER)) {
        childRoutes.push(
          this.getRecursiveRoutes(
            structure[prop],
            title,
            {
              ...childData,
              ...(structure.childData || {}),
            },
            false
          ) as Route
        );
      }
    }
    if (isFirst) {
      return childRoutes;
    }
    if (childRoutes.length > 0) {
      obj.children = childRoutes;
    }
    return obj;
  }
}
