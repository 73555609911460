<span class="toolbar__item__text--with-icon" *ngIf="isGroupRestriction">
  {{restrictedGroupName}}
</span>

<ng-container *ngIf="!isGroupRestriction">
  <span class="toolbar__item__text--with-icon" *ngIf="selectedGroup">
    {{selectedGroup.name}}
  </span>
  <span class="toolbar__item__text--with-icon" *ngIf="!selectedGroup" translate="COMMON_UI.KEY_WORD.ALL_GROUPS">
  </span>
  <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-caret-down"></mat-icon>

  <div class="dropdown" *ngIf="isGroupSelectorOpen" (clickOutside)="closeGroupSelector($event)">
    <div class="nav-container--standalone">
      <div class="nav-container--show-more nav-container--show-more--left">
        <!-- Add here select all groups -->
        <ng-container *ngFor="let g of groups">
          <span (click)="selectGroup(g)" class="link link--show-more dropdown--come-in nowrap">
            {{g.group.name}}
          </span>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>