import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BaseModalComponent } from '../../base-components/base-modal.component';

// Better to have string enum so that 0 and undefined don't get mixed
export enum ReturnValue {
  CANCEL = 'CANCEL',
  DISCARD = 'DISCARD',
}

@Component({
  selector: 'app-dirty-modal',
  templateUrl: './dirty-modal.component.html',
  styleUrls: ['./dirty-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DirtyModalComponent extends BaseModalComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  ret(discard: boolean) {
    this.close(discard ? ReturnValue.DISCARD : ReturnValue.CANCEL);
  }
}
