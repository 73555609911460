import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import {
  ITranslations,
  TranslationKey,
  Translations,
} from './../types/translations.model';
import { filter, map, take } from 'rxjs/operators';

const UI_LANGUAGES = ['ui', 'common_ui'];
const DEFAULT_LANGUAGE = 'fi-FI';

@Injectable({ providedIn: 'root' })
export class TranslationService {
  private translations: { [key: string]: ITranslations } = {};
  private locale_: string;
  get locale() {
    return this.locale_;
  }

  // Use this static to get typed translation keys to string
  private static translationKeyMap: Translations | undefined = undefined;
  static get KM(): Translations {
    if (!this.translationKeyMap) {
      this.translationKeyMap = new Translations();
    }
    return this.translationKeyMap;
  }
  // When this has value false, don't allow resetting new locale, that will cause race condition
  localeReady$ = new BehaviorSubject<boolean>(false);

  constructor(private http: HttpClient) {
    this.setLocale(DEFAULT_LANGUAGE);
  }

  async setLocale(locale: string) {
    if (!this.locale_ || this.locale_ !== locale) {
      this.locale_ = locale;
      const translations = await this.loadLanguages(locale);
      this.translations[locale] = translations;
      this.localeReady$.next(true);
    }
  }

  dict(): ITranslations | undefined {
    return this.translations[this.locale_];
  }

  dictViaPromise(): Promise<ITranslations> {
    const dict = this.dict();
    if (dict) {
      return Promise.resolve(dict);
    }
    return this.localeReady$
      .pipe(
        filter((s) => s),
        take(1),
        map((_) => this.dict())
      )
      .toPromise();
  }

  fromString(key: TranslationKey, params?: { [key: string]: string | number }) {
    const dict = this.dict();
    if (!dict) {
      return '';
    }
    let val = this.findTranslation(key.split('.'), dict);
    if (params) {
      val = this.substitute(val, params);
    }
    return val;
  }

  substitute(value: string, params: { [key: string]: string | number }) {
    for (const p in params) {
      if (params.hasOwnProperty(p)) {
        value = value.replace('{' + p + '}', params[p].toString());
      }
    }
    return value;
  }

  fromStringViaPromise(
    key: TranslationKey,
    params?: { [key: string]: string | number }
  ) {
    const dict = this.dict();
    if (dict) {
      return Promise.resolve(this.fromString(key, params));
    }
    return this.localeReady$
      .pipe(
        filter((s) => s),
        take(1),
        map((_) => this.fromString(key, params))
      )
      .toPromise();
  }

  private findTranslation(key: string[], obj: { [key: string]: any }) {
    const originalKey = key.join('.');
    const rec = (key: string[], obj: { [key: string]: any }) => {
      if (!obj) {
        return '';
      }
      if (key.length === 1) {
        return obj[key[0]];
      }
      const first = key.splice(0, 1)[0];
      return rec(key, obj[first]);
    };
    const translation = rec(key.slice(), obj);
    if (translation == '') {
      console.error('Translation not found for key ' + originalKey);
    }
    return translation;
  }

  private loadLanguages(lang: string): Promise<ITranslations> {
    if (lang in this.translations) {
      return Promise.resolve(this.translations[lang]);
    }
    this.localeReady$.next(false);
    const promises: Promise<Partial<ITranslations>>[] = [];

    UI_LANGUAGES.forEach((i) => {
      promises.push(
        this.http
          .get<Partial<ITranslations>>(
            'assets/translations/' + i + '.' + lang + '.json'
          )
          .toPromise()
      );
    });

    return Promise.all(promises).then((values) => {
      const obj = values.reduce((o, t) => {
        return { ...o, ...t };
      }, {});
      return obj as ITranslations;
    });
  }
}
