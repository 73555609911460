import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatCardModule } from '@angular/material/card';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { EventEditorComponent } from './event-editor.component';

@NgModule({
  declarations: [EventEditorComponent],
  imports: [CommonModule, MatCardModule, LoadingButtonModule],
})
export class EventEditorModule {}
