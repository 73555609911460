<div class="editor-buttons">
  <button *ngIf="!isEditing" mat-stroked-button color="primary" (click)="startEdit()">
    {{ texts.modify }}
  </button>

  <ng-container *ngIf="isEditing">
    <button class="cancel-edit" mat-stroked-button color="primary" (click)="cancelEdit()">
      {{ texts.cancelEdit }}
    </button>

    <shared-loading-button [text]="texts.save" [cb]="getSave(true)">
    </shared-loading-button>
  </ng-container>
</div>