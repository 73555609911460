import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { NavLink } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import { UserService } from '@core/services/user.service';
import { CmsPageDTO } from '@gen/gen.dto';
import { SubscriptionBaseComponent } from '@shared/base-components/subscription-base/subscription-base.component';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { filter, take } from 'rxjs/operators';
import { CmsConfigService } from '../../services/cms-config.service';
import { CmsEditService } from '../../services/cms-edit.service';
import { CmsPageService } from '../../services/cms-page.service';
import { MainBannerType } from '../../services/cms.models';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent extends SubscriptionBaseComponent implements OnInit {
  hasPages = true;
  configReady = false;
  portalName: string;
  page: CmsPageDTO | false = false;
  secondLevelLinks: NavLink[] = [];
  childLinks: NavLink[] = [];
  siblingLinks: NavLink[] = [];
  navPath: NavLink[] = [];
  image: string | false = false;
  pageNotFound = false;
  notFoundStr: string;
  isEditing = false;
  mainBannerType: MainBannerType;

  constructor(
    private cmsConfigService: CmsConfigService,
    private cmsPageService: CmsPageService,
    private cdr: ChangeDetectorRef,
    private currentGroupService: CurrentGroupService,
    private userService: UserService,
    private navService: NavService,
    private cmsEditService: CmsEditService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub(this.cmsConfigService.configReady$)
      .pipe(filter((i) => !!i))
      .subscribe((name: string) => {
        const config = this.cmsConfigService.getConfig();
        this.mainBannerType = config.config?.mainBannerType;
        this.notFoundStr = config.config?.notFoundContent;
        this.image = config.config?.mainBanner;
        this.portalName = config.config?.name;
        this.hasPages = config.links?.length > 0;
        this.configReady = true;
        if (this.userService.isLoggedIn) {
          this.currentGroupService.setGroupWithId(config.groupId);
        }
        this.cdr.detectChanges();
      });

    // Subscribe to page updates that may be triggered from anywhere
    this.sub(this.cmsPageService.pageUpdated$)
      .pipe(filter((id) => this.page && this.page.id === id))
      .subscribe((id) => {
        if (this.page) {
          this.cmsPageService
            .getPage(this.page.id)
            .pipe(take(1))
            .subscribe((page) => {
              this.page = page;
              this.cmsEditService.setCurrentPage(page);
              this.cdr.detectChanges();
            });
        }
      });

    this.sub(this.cmsEditService.isEditing$).subscribe((i) => {
      this.isEditing = i;
      this.cdr.detectChanges();
    });

    this.sub(this.cmsConfigService.currentPath$).subscribe((i) => {
      if (i === false) {
        this.pageNotFound = true;
        this.cdr.detectChanges();
        return;
      }
      this.pageNotFound = false;
      // When page changes, change links
      this.secondLevelLinks = this.cmsConfigService.getSecondLevelNav();
      this.childLinks = this.cmsConfigService.getChildLinks();
      this.navPath = this.cmsConfigService.getNavPathAsNavLinks();
      this.siblingLinks = this.cmsConfigService.getSiblingLinks(2);
      // TODO: Load page configuration and set visible!
      this.page = false;
      setTimeout(() => {
        // If page comes directly from store, no need to call this immediately
        if (!this.page) {
          this.cdr.detectChanges();
        }
      });
      if (i) {
        this.cmsPageService.getPage(i.id).subscribe((page) => {
          this.page = page;
          this.cdr.detectChanges();
          this.cmsEditService.setCurrentPage(page);
        });
      }
    });
  }

  gotoLink(link: NavLink) {
    this.navService.goto(link);
  }
}
