import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { TabNavModule } from '../tab-nav/tab-nav.module';
import { GeneralComponent } from './general.component';

@NgModule({
  declarations: [GeneralComponent],
  imports: [
    CommonModule,
    TabNavModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    LoadingButtonModule,
    SharedDirectivesModule,
    FormEditorModule,
  ],
})
export class GeneralModule {}
