import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { UserDTO } from '@gen/gen.dto';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import { ROUTES } from './nav.models';
import { NavService } from './nav.service';
import { UserApiService } from './user-api.service';
import { UserStore } from './user.store';

@Injectable({ providedIn: 'root' })
export class UserService implements CanActivate {
  private isLoggingIn$ = new BehaviorSubject<boolean>(true);

  get isLoggedIn(): Observable<boolean> {
    return this.store.user.pipe(map((user) => user !== undefined));
  }

  get isLoggingIn(): Observable<boolean> {
    return this.isLoggingIn$;
  }

  get user(): UserDTO | undefined {
    return this.store.user$.getValue();
  }

  get user$(): BehaviorSubject<UserDTO | undefined> {
    return this.store.user$;
  }

  constructor(
    private api: UserApiService,
    private router: Router,
    private navService: NavService,
    private store: UserStore
  ) {
    this.isLoggingIn$.next(true);
    this.refresh();
  }

  refresh(): Promise<UserDTO | false> {
    return new Promise((resolve, reject) => {
      this.api.getMe().subscribe(
        (user) => {
          this.store.set(user);
          this.isLoggingIn$.next(false);
          resolve(user);
        },
        (_) => {
          this.store.afterLogOut();
          this.isLoggingIn$.next(false);
          resolve(false);
        }
      );
    });
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> {
    const hasUser = (): true | UrlTree => {
      const has = this.store.user$.getValue() !== undefined;
      if (route.data && route.data.isLoginPage) {
        return has ? this.router.parseUrl('/pri/me') : true;
      }
      return has ? true : this.router.parseUrl('/pub/login');
    };
    if (!this.isLoggingIn$.getValue()) {
      return hasUser();
    }
    return this.isLoggingIn$.pipe(
      filter((val) => !val),
      map((_) => hasUser())
    );
  }

  saveProfile(profile: Partial<UserDTO>) {
    return this.api.setMe(profile).pipe(
      tap((user) => {
        this.store.set(user);
      })
    );
  }

  login(username: string, password: string): Promise<boolean> {
    this.isLoggingIn$.next(true);
    const promise = new Promise<boolean>((resolve, reject) => {
      this.api
        .login({
          username,
          password,
        })
        .subscribe(
          (user) => {
            this.store.set(user);
            resolve(true);
          },
          (error) => reject(false),
          () => this.isLoggingIn$.next(false)
        );
    });
    return promise;
  }

  logout() {
    return this.api
      .logout()
      .toPromise()
      .then(() => this.store.set(undefined));
  }

  gotoMe() {
    this.navService.goto(ROUTES.PRI.ME);
  }

  gotoLogin() {
    this.navService.goto(ROUTES.PUB.LOGIN);
  }
}
