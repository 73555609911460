import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FeaturesComponent } from './features.component';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';

@NgModule({
  declarations: [FeaturesComponent],
  imports: [CommonModule, LoadingButtonModule],
})
export class FeaturesModule {}
