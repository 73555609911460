import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupSelectorComponent } from './group-selector.component';
import { MatIconModule } from '@angular/material/icon';
import { ClickOutsideModule } from 'ng-click-outside';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';

@NgModule({
  declarations: [GroupSelectorComponent],
  imports: [
    CommonModule,
    MatIconModule,
    ClickOutsideModule,
    SharedDirectivesModule,
  ],
  exports: [GroupSelectorComponent],
})
export class GroupSelectorModule {}
