import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ROUTES } from '@core/services/nav.models';
import { EventsComponent } from '../events/events.component';
import { FeaturesComponent } from '../features/features.component';
import { GeneralComponent } from '../general/general.component';
import { LookupsComponent } from '../lookups/lookups.component';

const R = ROUTES.PRI.GROUP.SETTINGS;

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContainerComponent implements OnInit {
  //routes = {};

  routes = {
    [R.GENERAL.url]: GeneralComponent,
    [R.EVENTS.url]: EventsComponent,
    [R.FEATURES.url]: FeaturesComponent,
    [R.LOOKUP_LISTS.url]: LookupsComponent,
  };

  constructor(private route: ActivatedRoute) {
    if ('routes' in route.snapshot.data) {
      // this.routes = route.snapshot.data;
    }
    //this.routes = route.snapshot.data.routes;
    //console.log(route.snapshot.data.routes);
    //console.log(this.routes);
  }

  ngOnInit(): void {}
}
