import { NotImplementedComponent } from '@shared/components/not-implemented/not-implemented.component';

export const SECOND_NAV = {
  GROUP: 'group',
};

export type NavLink = {
  url: string | ((NavLink) => void);
  activeUrl?: string; // if other link is to be used to determine if this is active
  id?: string;
  name: string;
  icon?: string;
  doesOverflow?: boolean;
  isLastVisible?: boolean;
  isActive?: boolean;
  cls?: string;
};

export type C = {
  url?: string;
  title?: string;
  data?: any;
  component?: any;
};

export const GROUP_NAME_IN_TITLE = 'GROUP_NAME_IN_TITLE';

const PRI_ROUTES = {
  PUB: {
    childData: {
      isPublic: true,
    },
    canActivateChild: [],
    HOME: {
      url: '',
      title: 'UI.PAGE_TITLES.MAIN',
    } as C,
    LOGIN: {
      url: '',
      title: 'UI.PAGE_TITLES.LOGIN',
      data: {
        isLoginPage: true,
      },
    } as C,
  },
  PRI: {
    childData: {
      isPublic: false,
    },
    canActivateChild: [],
    ME: {
      url: 'user',
      title: 'UI.PAGE_TITLES.USER',
    } as C,
    NEW_GROUP: {
      url: '',
      title: 'UI.PAGE_TITLES.NEW_GROUP',
    } as C,
    GROUP: {
      url: 'group/:groupId',
      title: GROUP_NAME_IN_TITLE,
      canActivateChild: [],
      childData: {
        isGroup: true,
        secondNav: SECOND_NAV.GROUP,
      },
      data: {
        isGroup: true,
        secondNav: SECOND_NAV.GROUP,
      },
      EVENTS: {
        CALENDAR: {
          url: '',
          component: NotImplementedComponent,
          title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
        },
        EDITOR: {
          url: 'event-modify/:eventId',
          component: NotImplementedComponent,
          title: 'UI.PAGE_TITLES.EVENT_EDITOR_MODIFY',
        },
        EDITOR_NEW: {
          url: 'event-add',
          component: NotImplementedComponent,
          title: 'UI.PAGE_TITLES.EVENT_EDITOR_ADD',
        },
      },
      MEMBERS: {
        url: '',
        component: NotImplementedComponent,
        title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
      },
      SETTINGS: {
        url: '',
        component: undefined as any,
        data: {
          routeReUse: 'GROUP.SETTINGS',
        },
        childData: {
          routeReUse: 'GROUP.SETTINGS',
        },
        GENERAL: {
          url: '',
          title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
        } as C,
        EVENTS: {
          url: '',
          component: NotImplementedComponent,
          title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
        },
        LOOKUP_LISTS: {
          url: '',
          component: NotImplementedComponent,
          title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
        },
        ROLES: {
          url: '',
          component: NotImplementedComponent,
          title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
        },
        FEATURES: {
          url: '',
          title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
        } as C,
      },
      STATISTICS: {
        url: '',
        component: NotImplementedComponent,
        title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
      },
      MY_DETAILS: {
        url: '',
        component: NotImplementedComponent,
        title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
      },
      GDPR: {
        url: '',
        component: NotImplementedComponent,
        title: 'UI.PAGE_TITLES.NOT_IMPLEMENTED',
      },
    },
  },
};

function normalize(name: string) {
  const regex = /_/gi;
  name = name.replace(regex, '-');
  return name.toLowerCase();
}

function hasChildren(data: any) {
  for (const i in data) {
    if (i.match(/^[A-Z_]{1,}$/) !== null) {
      return true;
    }
  }
  return false;
}

function rec_create(url: string, data: any) {
  for (const i in data) {
    if (i.match(/^[A-Z_]{1,}$/) !== null) {
      // This is relative path
      if (!data[i].url) {
        data[i].url = normalize(i);
      }
      // This is relative path
      data[i].path = url + '/' + data[i].url;
      const swap = data[i].url;
      data[i].url = data[i].path;
      data[i].path = swap;
      if (hasChildren(data[i])) {
        rec_create(data[i].url, data[i]);
      }
    }
  }
  return data;
}

export const ROUTES: typeof PRI_ROUTES = rec_create(
  '',
  PRI_ROUTES
) as typeof PRI_ROUTES;
