import { Injectable } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { LookupListDTO, LookupListItemSortOrderDTO } from '@gen/gen.dto';
import {
  EnrichableFormEditorConfig,
  FormItemButton,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { Observable } from 'rxjs';

export enum FormTrackingKeys {
  LIST = 'LOOKUPS_UTIL_LIST',
  ITEM = 'LOOKUPS_UTIL_ITEM',
  ITEMS = 'LOOKUPS_UTIL_ITEMS',
}

@Injectable()
export class LookupsUtil {
  constructor(
    private fb: FormBuilder,
    private dirtyStateTracker: DirtyStateTrackerService,
    private translationService: TranslationService
  ) {}

  getListForm(value?: Partial<LookupListDTO>): FormGroup {
    this.dirtyStateTracker.removeAllDirtyStates();
    const form = this.fb.group({
      id: [''],
      groupId: [''],
      name: ['', Validators.required],
      description: [''],
    });
    if (value) {
      form.patchValue(value);
    }
    return form;
  }

  stopTracking(type: FormTrackingKeys) {
    this.dirtyStateTracker.removeDirty(type);
  }

  getListConfig(
    form: FormGroup,
    callbacks: {
      save: () => Observable<any>;
      cancel: () => Observable<any> | Promise<any>;
      remove?: () => Observable<any>;
    },
    modify = false
  ) {
    const buttons: Partial<FormItemButton>[] = [
      {
        classList: ['item-in-row-no-col'],
        labelKey: modify
          ? TranslationService.KM.COMMON_UI.KEY_WORD.SAVE
          : TranslationService.KM.COMMON_UI.KEY_WORD.ADD,
        type: 'button.loading',
        callback: callbacks.save,
      },
      {
        classList: ['item-in-row-no-col'],
        labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.CANCEL,
        type: 'button.plain',
        enableAlways: true,
        callback: callbacks.cancel,
      },
    ];
    if (callbacks.remove) {
      buttons.push({
        classList: ['item-in-row-no-col'],
        labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.DELETE,
        type: 'button.loading',
        enableAlways: true,
        confirm: true,
        callback: callbacks.remove,
      });
    }

    const config: EnrichableFormEditorConfig = {
      form: form,
      dirtyStateTracker: FormTrackingKeys.LIST,
      formFieldClassList: ['full-width'],
      classList: ['col-xs-12', 'col-sm-4'],
      fields: [
        [
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.NAME,
            formControlName: 'name',
          },
        ],
        [
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.DESCRIPTION,
            formControlName: 'description',
            type: 'textarea',
          },
        ],
        buttons,
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getListsConfig(lists: LookupListDTO[], callback: () => Observable<any>) {
    const form = this.fb.group({
      list: [''],
    });
    const config: EnrichableFormEditorConfig = {
      form: form,
      formFieldClassList: ['full-width'],
      fields: [
        [
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SELECT,
            formControlName: 'list',
            type: 'select',
            classList: ['col-xs-8', 'col-sm-4'],
            options: lists.map((i) => {
              return {
                value: i.id,
                label: i.name,
              };
            }),
          },
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.MODIFY,
            type: 'button.loading',
            enableAlways: true,
            classList: ['col-xs-4', 'col-sm-4'],
            callback,
          },
        ],
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getItemForm(value?: Partial<LookupListItemSortOrderDTO>): FormGroup {
    const form = this.getItemFormInt(value);
    this.dirtyStateTracker.removeDirty(FormTrackingKeys.ITEM);
    this.dirtyStateTracker.trackFormGroup(this, FormTrackingKeys.ITEM, form);
    return form;
  }

  getItemsForm(value: Partial<LookupListItemSortOrderDTO[]>): FormArray {
    const array = value.map((i) => this.getItemFormInt(i));
    const form = new FormArray(array);
    this.dirtyStateTracker.removeDirty(FormTrackingKeys.ITEMS);
    this.dirtyStateTracker.trackFormGroup(this, FormTrackingKeys.ITEMS, form);
    return form;
  }

  private getItemFormInt(
    value?: Partial<LookupListItemSortOrderDTO>
  ): FormGroup {
    const priceValidator = Validators.pattern(/^\d{0,9}$/);
    const form = this.fb.group({
      id: [''],
      listId: [''],
      name: ['', Validators.required],
      value: [''],
      order: [
        '',
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(1000),
        ]),
      ],
      priceCat1: ['', priceValidator],
      priceCat2: ['', priceValidator],
      priceCat3: ['', priceValidator],
      priceCat4: ['', priceValidator],
      priceCat5: ['', priceValidator],
      priceCat6: ['', priceValidator],
      priceCat7: ['', priceValidator],
      priceCat8: ['', priceValidator],
    });
    if (value) {
      form.patchValue(value);
    }
    return form;
  }
}
