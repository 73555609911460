import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { ProfileComponent } from './profile.component';

@NgModule({
  declarations: [ProfileComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    LoadingButtonModule,
    FormEditorModule,
  ],
})
export class ProfileComponentModule {
  constructor() {}
}
