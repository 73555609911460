import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { SignUpFormDetailsDTO, SignUpFormDTO } from '@gen/gen.dto';
import { FormEditorConfig } from '@shared/components/form-editor/form-editor.model';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { ToasterService } from '@shared/services/toaster.service';
import { tap } from 'rxjs/operators';
import { GroupEventSettingsService } from '../../../../services/group-event-settings.service';
import { SignUpFormUtils } from './sign-up-form.utils';

const DIRTY_KEY = 'SIGN_UP_FORM_EDITOR';

type PageAction = 'SELECT' | 'ADD' | 'MODIFY';

@Component({
  selector: 'app-sign-up-forms',
  templateUrl: './sign-up-forms.component.html',
  styleUrls: ['./sign-up-forms.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignUpFormsComponent implements OnInit {
  selectConfig: FormEditorConfig | undefined;
  signUpForms: SignUpFormDTO[];
  addConfig: FormEditorConfig;
  editConfig: FormEditorConfig;
  action: PageAction = 'SELECT';

  editing: SignUpFormDetailsDTO;

  constructor(
    private eventSettingsService: GroupEventSettingsService,
    private tx: TranslationService,
    private fb: FormBuilder,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private toasterService: ToasterService,
    private signUpFormUtils: SignUpFormUtils,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.reloadSignUpForms();
    this.addConfig = this.signUpFormUtils.getAddFormConfig(
      DIRTY_KEY,
      this.onAddCallback()
    );
  }

  setAction(action: PageAction) {
    this.action = action;
    this.cdr.markForCheck();
  }

  private reloadSignUpForms() {
    this.action = 'SELECT';
    this.eventSettingsService.getSignupForms().subscribe((response) => {
      this.signUpForms = response.forms;
      this.selectConfig = this.signUpFormUtils.getSelectConfig(
        this.signUpForms,
        () => this.selectForm()
      );
      this.selectConfig.form.patchValue({
        list: this.signUpForms[0].id,
      });
      this.cdr.markForCheck();
    });
  }

  selectForm(id?: string) {
    this.action = 'MODIFY';
    const selectedId = id || this.selectConfig.form.value.list;
    return this.eventSettingsService.getSignupForm(selectedId).then((form) => {
      this.editing = form;
      this.editConfig = this.signUpFormUtils.getEditFormConfig(
        DIRTY_KEY,
        this.onSaveCallback()
      );
      this.editConfig.form.patchValue(form);
      this.cdr.detectChanges();
    });
  }

  getRemove() {
    return () => {
      return this.eventSettingsService.removeSignUpForm(this.editing.id).pipe(
        tap((i) => {
          this.toasterService.showSuccessTranslationKey(
            TranslationService.KM.COMMON_UI.OK.DELETED
          );
          this.reloadSignUpForms();
        })
      );
    };
  }

  getSetDefault() {
    return () => {
      return this.eventSettingsService
        .setSignUpFormToDefault(this.editing.id)
        .pipe(
          tap((i) => {
            this.editConfig.form.patchValue({ isDefault: true });
            this.editing.isDefault = true;
            this.toasterService.showSuccessTranslationKey(
              TranslationService.KM.COMMON_UI.OK.SAVED
            );
            this.cdr.detectChanges();
          })
        );
    };
  }

  private onSaveCallback() {
    return () => {
      const data = Object.assign({}, this.editConfig.form.value);
      data.id = this.editing.id;
      this.editConfig.form.markAsUntouched();
      this.editConfig.form.markAsPristine();
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      return this.eventSettingsService.updateSignUpForm(data).pipe(
        tap((i) => {
          this.reloadSignUpForms();
          this.toasterService.showSuccessTranslationKey(
            TranslationService.KM.COMMON_UI.OK.SAVED
          );
        })
      );
    };
  }

  private onAddCallback() {
    return () => {
      const data = Object.assign({}, this.addConfig.form.value);
      this.addConfig.form.patchValue({ name: '' });
      this.addConfig.form.markAsUntouched();
      this.addConfig.form.markAsPristine();
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      return this.eventSettingsService.addSignUpForm(data).pipe(
        tap((i) => {
          this.reloadSignUpForms();
          this.toasterService.showSuccessTranslationKey(
            TranslationService.KM.COMMON_UI.OK.ADDED
          );
        })
      );
    };
  }
}
