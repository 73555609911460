import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlainComponent } from './plain.component';
import { MatButtonModule } from '@angular/material/button';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { MatInputModule } from '@angular/material/input';
import { PlainEditorModule } from '../plain-editor/plain-editor.module';

@NgModule({
  declarations: [PlainComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    LoadingButtonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    PlainEditorModule,
  ],
  exports: [PlainComponent],
})
export class PlainModule {}
