import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LeftSideNavModule } from './left-side-nav/left-side-nav.module';
import { RightSideNavModule } from './right-side-nav/right-side-nav.module';
import { GroupSelectorModule } from './group-selector/group-selector.module';
import { NotFoundModule } from './not-found/not-found.module';

@NgModule({
  imports: [
    CommonModule,
    LeftSideNavModule,
    RightSideNavModule,
    GroupSelectorModule,
    NotFoundModule,
  ],
})
export class CoreComponentsModule {
  constructor() {}
}
