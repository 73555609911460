import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VersionResponseDTO } from '@gen/gen.dto';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from './api-v1.models';

@Injectable({
  providedIn: 'root',
})
export class VersionService {
  constructor(private http: HttpClient) {}

  getVersion(id: string): Observable<number> {
    return this.http
      .get<VersionResponseDTO>(subsToUrl(API.VERSION.url, { id }))
      .pipe(map((i) => i.version));
  }

  getVersionForArea(id: string, area: string): Observable<number> {
    return this.http
      .get<VersionResponseDTO>(subsToUrl(API.VERSION_AREA.url, { id, area }))
      .pipe(map((i) => i.version));
  }
}
