import { Injectable } from '@angular/core';
import { GroupDTO } from '@gen/gen.dto';
import { GroupApiService } from './group-api.service';
import { tap } from 'rxjs/operators';
import { CurrentGroupService } from '@shared/services/current-group.service';

@Injectable({
  providedIn: 'root',
})
export class GroupService {
  constructor(
    private api: GroupApiService,
    private currentGroup: CurrentGroupService
  ) {}

  saveGeneral(groupId: string, dto: Partial<GroupDTO>) {
    return this.api.saveGeneral(groupId, dto).pipe(
      tap((group) => {
        this.currentGroup.setCurrentGroup(group);
      })
    );
  }
}
