import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-event-editor',
  templateUrl: './event-editor.component.html',
  styleUrls: ['./event-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventEditorComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
