import { AbstractControl } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import {
  AnyFormItem,
  EnrichableFormEditorConfig,
  FormEditorConfig,
  FormItemButton,
  FormItemDefaults,
  FormItemError,
  FormItemTypeMap,
} from './form-editor.model';

export interface EnrichParams {
  tx?: TranslationService;
}

export function enrichConfigToDefaults(
  config: EnrichableFormEditorConfig,
  enrichParams?: EnrichParams
): FormEditorConfig {
  const conf = {
    form: config.form,
    formFieldClassList: config.formFieldClassList,
    dirtyStateTracker: config.dirtyStateTracker,
    fields: getRowArrays(config, enrichParams),
  };
  return conf;
}

function getRowArrays(
  config: EnrichableFormEditorConfig,
  enrichParams?: EnrichParams
): Array<Partial<AnyFormItem[]>> {
  return (config.fields as Array<any>).map((i) => {
    return (Array.isArray(i) ? i : [i]).map((i) =>
      enrichField(i, config, enrichParams)
    );
  });
}

function enrichField(
  item: Partial<AnyFormItem>,
  config: EnrichableFormEditorConfig,
  enrichParams?: EnrichParams
): AnyFormItem {
  if ('labelKey' in item) {
    item.label = enrichParams.tx.fromString(item.labelKey);
  }
  if ('error' in item) {
    // Might be missing label, but added later
    item.errors = [item.error as FormItemError];
  }
  if ('formControlName' in item) {
    item.formControl = config.form.controls[item.formControlName];
  }
  if ('classList' in config && !('classList' in item)) {
    item.classList = config.classList;
  }
  if ('options' in item) {
    item.options = item.options.map((i) => {
      if ('labelKey' in i) {
        i.label = enrichParams.tx.fromString(i.labelKey);
      }
      return i;
    });
  }
  item.hasErrors = 'errors' in item && item.errors.length > 0;
  if (item.hasErrors) {
    item.errors = item.errors.map((i: Partial<FormItemError>) => {
      if (i.labelKey) {
        i.label = enrichParams.tx.fromString(item.labelKey);
      }
      if (!('when' in i)) {
        i.when = (fc: AbstractControl) => fc.invalid && fc.touched;
      }
      return i as FormItemError;
    });
  }
  for (let key in FormItemDefaults) {
    if (!(key in item)) {
      item[key] = FormItemDefaults[key];
    }
  }
  item.isButtonItem = item.type.startsWith('button.');
  if (item.isButtonItem) {
    (item as FormItemButton).enableAlways =
      (item as FormItemButton).enableAlways || false;
    (item as FormItemButton).confirm =
      (item as FormItemButton).confirm || false;
  }
  item.isFormItem = item.type in FormItemTypeMap;
  return item as AnyFormItem;
}
