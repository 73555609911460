<ng-container [ngSwitch]="action">

  <ng-container *ngSwitchCase="'SELECT'">
    <shared-form-editor *ngIf="signUpForms?.length > 0" [config]="selectConfig"></shared-form-editor>
    <button (click)="setAction('ADD')" mat-stroked-button color="primary" translate="COMMON_UI.KEY_WORD.ADD_NEW">
    </button>
  </ng-container>

  <ng-container *ngSwitchCase="'ADD'">
    <button (click)="setAction('SELECT')" mat-stroked-button color="primary" translate="COMMON_UI.KEY_WORD.CANCEL">
    </button>

    <h2 translate="COMMON_UI.KEY_WORD.ADD"></h2>

    <shared-form-editor [config]="addConfig"></shared-form-editor>

  </ng-container>

  <ng-container *ngSwitchCase="'MODIFY'">
    <button (click)="setAction('SELECT')" mat-stroked-button color="primary" translate="COMMON_UI.KEY_WORD.CANCEL">
    </button>

    <shared-loading-button [cb]="getRemove()" [key]="'COMMON_UI.KEY_WORD.DELETE'" *ngIf="editing && !editing.isDefault">
    </shared-loading-button>

    <shared-loading-button [cb]="getSetDefault()" [key]="'UI.GROUP.SETTINGS.SIGN_UP_FORM.SET_DEFAULT'"
      *ngIf="editing && !editing.isDefault">
    </shared-loading-button>

    <h2 translate="COMMON_UI.KEY_WORD.MODIFY"></h2>

    <shared-form-editor [config]="editConfig"></shared-form-editor>
  </ng-container>

</ng-container>