import { Injectable } from '@angular/core';
import { CmsApiService } from '@cms/services/cms-api.service';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { CmsPageDTO, LinkDTO, StatusDTO } from '@gen/gen.dto';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { switchMap, tap } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class CmsEditorService {
  constructor(
    private cmsConfigService: CmsConfigService,
    private currentGroupService: CurrentGroupService,
    private api: CmsApiService
  ) {}

  addPage(data: CmsPageDTO): Promise<LinkDTO> {
    return new Promise((resolve, reject) => {
      let response: LinkDTO;
      return this.api
        .addPage(this.currentGroupService.currentGroupId, data)
        .pipe(
          tap((r) => (response = r)),
          switchMap(() => this.cmsConfigService.refreshConfig())
        )
        .subscribe(() => resolve(response));
    });
  }

  updatePage(id: string, data: Partial<CmsPageDTO>) {
    return new Promise((resolve, reject) => {
      let response: any;
      return this.api
        .updatePage(id, data)
        .pipe(
          tap((r) => (response = r)),
          switchMap(() => this.cmsConfigService.refreshConfig())
        )
        .subscribe(() => resolve(response));
    });
  }

  deletePage(pageId: string) {
    return new Promise((resolve, reject) => {
      let response: StatusDTO;
      return this.api
        .deletePage(pageId)
        .pipe(
          tap((r) => (response = r)),
          switchMap(() => this.cmsConfigService.refreshConfig())
        )
        .subscribe(() => resolve(response));
    });
  }
}
