<shared-accordion-toggle-buttons>

  <mat-accordion multi>

    <!-- Calendars -->
    <mat-expansion-panel (opened)="onceOpened.calendar = true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span translate="UI.GROUP.SETTINGS.EVENTS.CALENDARS"></span>
        </mat-panel-title>
        <mat-panel-description>
          <span translate="UI.GROUP.SETTINGS.EVENTS.CALENDARS_HELP"></span>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-calendar-editor *ngIf="onceOpened.calendar"></app-calendar-editor>
    </mat-expansion-panel>

    <!-- Event types -->
    <mat-expansion-panel (opened)="onceOpened.eventTypes = true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span translate="UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES"></span>
        </mat-panel-title>
        <mat-panel-description>
          <span translate="UI.GROUP.SETTINGS.EVENTS.EVENT_TYPES_HELP"></span>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-event-type-editor *ngIf="onceOpened.eventTypes"></app-event-type-editor>
    </mat-expansion-panel>

    <!-- Screen configuration -->
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span translate="UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS"></span>
        </mat-panel-title>
        <mat-panel-description>
          <span translate="UI.GROUP.SETTINGS.EVENTS.EVENT_VIEWS_HELP"></span>
        </mat-panel-description>
      </mat-expansion-panel-header>
      <pre>
      Manage different views:
      - Internal views
      - External views
      - Sign up view (external event)
      - Sign up biew (internal event)
      </pre>
    </mat-expansion-panel>

    <!-- Sign up forms -->
    <mat-expansion-panel (opened)="onceOpened.events = true">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <span translate="UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS"></span>
        </mat-panel-title>
        <mat-panel-description>
          <span translate="UI.GROUP.SETTINGS.EVENTS.SIGN_UP_FORMS_HELP"></span>
        </mat-panel-description>
      </mat-expansion-panel-header>

      <app-sign-up-forms *ngIf="onceOpened.events"></app-sign-up-forms>
    </mat-expansion-panel>
  </mat-accordion>

</shared-accordion-toggle-buttons>