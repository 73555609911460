import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss'],
})
export class EventsComponent implements OnInit {
  onceOpened = {
    calendar: false,
    events: false,
    eventTypes: false,
  };
  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy() {}
}
