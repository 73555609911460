import { Injectable } from '@angular/core';
import { CssVarDTO } from '@gen/gen.dto';
import { of, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsApiService } from './cms-api.service';
import { CmsPageStore } from './cms-page.store';

@Injectable({ providedIn: 'root' })
export class CmsPageService {
  // Build store to cache pages

  pageUpdated$ = new Subject<string>();

  constructor(private api: CmsApiService, private store: CmsPageStore) {}

  getPage(pageId: string) {
    if (this.store.hasPage(pageId)) {
      return of(this.store.getPage(pageId));
    }
    return this.api
      .getPageContent(pageId)
      .pipe(tap((page) => this.store.addPage(page)));
  }

  savePage(pageId: string, content: any) {
    return this.api.updatePage(pageId, content).pipe(
      tap((page) => {
        this.store.addPage(page);
        this.pageUpdated$.next(page.id);
      })
    );
  }

  saveCssVars(groupId: string, vars: CssVarDTO[]) {
    return this.api.updateCssVars(groupId, vars);
  }
}
