import { Subject, Observable } from 'rxjs';
import { OnDestroy, Component } from '@angular/core';
import { takeUntil } from 'rxjs/operators';

@Component({
  template: '',
})
export class SubscriptionBaseComponent implements OnDestroy {
  private notifier = new Subject();

  ngOnDestroy() {
    this.notifier.next();
    this.notifier.complete();
  }

  // This is very close to autounsubscribe
  protected sub<T>(obs: Observable<T>): Observable<T> {
    return obs.pipe(takeUntil(this.notifier));
  }
}
