<h2 translate="UI.CMS.CONFIG.ROUTES.NEW_PAGE"></h2>
<div>
  <a href="javascript:void(0)" (click)="newSetSibling()" translate="UI.CMS.CONFIG.ROUTES.SIBLING_PAGE"></a>
  &nbsp;|&nbsp;
  <a href="javascript:void(0)" (click)="newSetChild()" translate="UI.CMS.CONFIG.ROUTES.CHILD_PAGE"></a>
</div>
<cms-modify-routes-page-form [form]="newForm" [links]="links">
  <div class="form-button">
    <shared-loading-button [enableWhenDirty]="NEW_FORM_DIRTY_KEY" [disabled]="newForm.status != 'VALID'" [cb]="addNew()"
      class="margin-to-right"></shared-loading-button>
    <ng-template [appDirtyShower] [showWhenDirty]="NEW_FORM_DIRTY_KEY">
      <button mat-stroked-button color="primary" (click)="cancelAdd()" translate="COMMON_UI.KEY_WORD.CANCEL">
      </button>
    </ng-template>
  </div>
</cms-modify-routes-page-form>

<h2 translate="UI.CMS.CONFIG.ROUTES.MODIFY_PAGE" [par]="{page: modifyLink.title}"></h2>
<cms-modify-routes-page-form [modify]="true" [form]="modifyForm" [links]="links">
  <div class="form-button">
    <shared-loading-button [enableWhenDirty]="MOD_FORM_DIRTY_KEY" [disabled]="modifyForm.status != 'VALID'"
      [cb]="modify()" class="margin-to-right"></shared-loading-button>
    <ng-template [appDirtyShower] [showWhenDirty]="MOD_FORM_DIRTY_KEY">
      <button mat-stroked-button color="primary" (click)="cancelEdit()" translate="COMMON_UI.KEY_WORD.CANCEL">
      </button>
    </ng-template>
  </div>
</cms-modify-routes-page-form>

<h2 translate="UI.CMS.CONFIG.ROUTES.PAGES"></h2>
<div>
  <span translate="UI.CMS.CONFIG.ROUTES.PATH" [par]="{path: parentPath}"></span>&nbsp;
  <a href="javascript:void(0)" (click)="levelUp()" *ngIf="parent" translate="UI.CMS.CONFIG.ROUTES.LEVEL_UP"></a>
</div>
<div class="row-container" *ngFor="let link of currentLevel">
  <div class="row actions-only">
    <div class="col-xs-1">
      {{link.sortOrder}}
    </div>
    <div class="col-xs-11 col-sm-2">
      <a href="javascript:void(0)" (click)="levelDown(link)">
        /{{link.slug}}
      </a>
    </div>
    <div class="col-xs-12 col-sm-3">
      <a href="javascript:void(0)" (click)="startModify(link)">
        {{link.title}}
      </a>
    </div>
    <div class="col-xs-12 col-sm-3">
      <shared-loading-button key="COMMON_UI.KEY_WORD.DELETE" [disableWhenDirty]="true" [confirm]="true"
        *ngIf="modifyLink.id !== link.id" [cb]="deletePage(link.id)">
      </shared-loading-button>
    </div>
    <div class="col-xs-12 col-sm-3">
      <shared-order-editor (onNewPosition)="setPositionOf(link, $event)" [current]="link.sortOrder"
        [total]="currentLevel.length"></shared-order-editor>
    </div>

  </div>
</div>