import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewGroupModule } from './new-group/new-group.module';
import { SettingsModule } from './settings/settings.module';
import { TabNavModule } from './settings/tab-nav/tab-nav.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, NewGroupModule, SettingsModule, TabNavModule],
})
export class GroupComponentsModule {}
