import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DatService } from '@core/services/dat.service';
import { UserDTO } from '@gen/gen.dto';
import { SubscriptionBaseComponent } from '@shared/base-components/subscription-base/subscription-base.component';
import { ROUTES } from '../../services/nav.models';
import { NavService } from '../../services/nav.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'core-right-side-nav',
  templateUrl: './right-side-nav.component.html',
  styleUrls: ['./right-side-nav.component.scss'],
})
export class RightSideNavComponent
  extends SubscriptionBaseComponent
  implements OnInit {
  @Output() close = new EventEmitter<void>();

  isGroupRestriction = false;
  isLoggedIn = false;
  user?: UserDTO;

  constructor(
    private navService: NavService,
    private userService: UserService,
    private datService: DatService
  ) {
    super();
  }

  ngOnInit() {
    this.isGroupRestriction = this.datService.isGroupRestriction;
    this.sub(this.userService.isLoggedIn).subscribe((is) => {
      this.isLoggedIn = is;
      this.user = this.userService.user;
    });
  }

  me() {
    this.close.emit();
    this.navService.goto(ROUTES.PRI.ME);
  }

  login() {
    this.close.emit();
    this.navService.goto(ROUTES.PUB.LOGIN);
  }

  logout(): void {
    this.userService.logout().then(() => {
      this.navService.goto(ROUTES.PUB.HOME);
      this.close.emit();
    });
  }

  newGroup() {
    this.close.emit();
    this.navService.goto(ROUTES.PRI.NEW_GROUP);
  }
}
