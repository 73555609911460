import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { API } from '@core/services/api-v1.models';
import { ROUTES } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import { UserService } from '@core/services/user.service';
import { GroupDTO } from '@gen/gen.dto';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NewGroupService {
  constructor(
    private router: Router,
    private navService: NavService,
    private http: HttpClient,
    private currentGroupService: CurrentGroupService,
    private userService: UserService
  ) {}

  createGroup(name: string) {
    return this.http
      .post<GroupDTO>(API.GROUP_CREATE.AS_USER, {
        name: name,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      })
      .pipe(
        tap(async (group) => {
          this.currentGroupService.setNextGroup(group);
          await this.userService.refresh();
          this.navService.goto(ROUTES.PRI.GROUP.EVENTS.CALENDAR, {
            groupId: group.id,
          });
        })
      );
  }
}
