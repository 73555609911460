import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccordionToggleButtonsModule } from './accordion-toggle-buttons/accordion-toggle-buttons.module';
import { ConfirmModalModule } from './confirm-modal/confirm-modal.module';
import { DirtyModalModule } from './dirty-modal/dirty-modal.module';
import { FormEditorModule } from './form-editor/form-editor.module';
import { LoadingButtonModule } from './loading-button/loading-button.module';
import { LoadingModule } from './loading/loading.module';
import { MessageModule } from './message/message.module';
import { NavSecondModule } from './nav-second/nav-second.module';
import { NotImplementedModule } from './not-implemented/not-implemented.module';
import { OrderEditorModule } from './order-editor/order-editor.module';
import { OverlayModule } from './overlay/overlay.module';
import { RouterOutletModule } from './router-outlet/router-outlet.module';
import { SampleModalModule } from './sample-modal/sample-modal.module';
@NgModule({
  imports: [
    CommonModule,
    LoadingButtonModule,
    NotImplementedModule,
    NavSecondModule,
    OverlayModule,
    SampleModalModule,
    RouterOutletModule,
    MessageModule,
    LoadingModule,
    DirtyModalModule,
    ConfirmModalModule,
    OrderEditorModule,
    FormEditorModule,
    AccordionToggleButtonsModule,
  ],
})
export class SharedComponentsModule {
  constructor() {}
}
