import { Directive, ElementRef, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

const MIN_LINES = 4;
const MAX_LINES = 30;

@Directive({
  selector: '[autosizeTextarea]',
})
export class AutosizeTextareaDirective {
  private formControl_: FormControl;

  private sub: Subscription;

  @Input() set formControl(value: FormControl) {
    this.ngOnDestroy();
    this.formControl_ = value;
    if (value) {
      this.sub = this.formControl_.valueChanges.subscribe((i) => {
        this.setHeight(i);
      });
      this.setHeight(this.formControl_.value);
    }
  }

  constructor(private el: ElementRef) {}

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  private setHeight(str?: string) {
    const el = this.el.nativeElement as HTMLTextAreaElement;
    if (!str) {
      el.rows = 4;
      return;
    }
    const lines = str.split(/\r\n|\r|\n/).length + 2;
    el.rows = Math.max(Math.min(lines, MAX_LINES), MIN_LINES);
  }
}
