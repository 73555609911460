import { Component, OnInit } from '@angular/core';
import { BaseModalComponent } from '../../base-components/base-modal.component';

@Component({
  selector: 'app-sample-modal',
  templateUrl: './sample-modal.component.html',
  styleUrls: ['./sample-modal.component.scss'],
})
export class SampleModalComponent extends BaseModalComponent implements OnInit {
  modalTitle: string;
  constructor() {
    super();
  }

  ngOnInit(): void {
    if (!!this._data && !!this._data.modalTitle) {
      this.modalTitle = this._data.modalTitle;
    }
  }
}
