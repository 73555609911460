import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainModule } from './main/main.module';
import { ModifyModalModule } from './modify-modal/modify-modal.module';
import { PagesModule } from './pages/pages.module';
import { EditingToolbarModule } from './editing-toolbar/editing-toolbar.module';
@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    MainModule,
    ModifyModalModule,
    PagesModule,
    EditingToolbarModule,
  ],
})
export class CmsComponentsModule {}
