import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription, Subject } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { UserDTO } from '@gen/gen.dto';
import { GROUP_PERMISSION } from '@core/services/permissions.models';
import { debounceTime, filter, distinct } from 'rxjs/operators';
import { CurrentGroupService } from '../services/current-group.service';
import { GroupDTO } from '@gen/gen.dto';

// Debounce in computing state ms
const SOLVE_DEBOUNCE_TIME = 200;

@Directive({
  selector: '[appGroupPermission]',
})
export class GroupPermissionDirective implements OnDestroy {
  private groupId_: string | undefined;
  @Input() set groupId(value: string | undefined) {
    this.groupId_ = value;
    this.solvePermissions.next();
  }
  get groupId(): string | undefined {
    return this.groupId_;
  }

  private permission: GROUP_PERMISSION;
  @Input() set appGroupPermission(permission: GROUP_PERMISSION) {
    this.permission = permission;
    this.solvePermissions.next();
  }

  private readonly solvePermissions = new Subject();

  // This is hide on true (when user has permission)
  @Input() hide = false;

  private previousState = false;

  private user: UserDTO | undefined;

  private subs: Subscription[] = [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private currentGroupService: CurrentGroupService,
    userService: UserService
  ) {
    this.subs.push(
      userService.user$.subscribe((u) => {
        this.user = u;
        this.solvePermissions.next();
      })
    );
    this.subs.push(
      currentGroupService.currentGroup$
        .pipe(
          filter((g) => !!g),
          distinct((g: GroupDTO) => g.id)
        )
        .subscribe(() => this.solvePermissions.next())
    );
    this.subs.push(
      this.solvePermissions
        .pipe(debounceTime(SOLVE_DEBOUNCE_TIME))
        .subscribe(() => this.computeState())
    );
  }

  ngOnDestroy() {
    this.subs.forEach((s) => s.unsubscribe());
  }

  private computeState() {
    let groupId: string | undefined = this.groupId;
    if (!this.groupId && this.currentGroupService.currentGroup$.value) {
      groupId = this.currentGroupService.currentGroupId;
    }
    if (!groupId) {
      // Not necessarily error because might still be computing groupId
      return;
    }
    let state = this.previousState;
    if (this.user && this.permission) {
      const group = this.user.groupPermissions.find(
        (i) => i.groupId === groupId
      );
      if (group) {
        state = group.permissions.indexOf(this.permission) !== -1;
        if (this.hide) {
          state = !state;
        }
      }
    }
    if (state !== this.previousState) {
      if (state) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.markForCheck();
      } else {
        this.viewContainer.clear();
      }
    }
    this.previousState = state;
  }
}
