import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { PlainEditorComponent } from './plain-editor.component';

@NgModule({
  declarations: [PlainEditorComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatInputModule,
    LoadingButtonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedDirectivesModule,
    FormEditorModule,
  ],
  exports: [PlainEditorComponent],
})
export class PlainEditorModule {}
