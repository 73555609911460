import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContainerComponent } from './container.component';
import { TabNavModule } from '../tab-nav/tab-nav.module';
import { MatCardModule } from '@angular/material/card';
import { RouterOutletModule } from '@shared/components/router-outlet/router-outlet.module';

@NgModule({
  declarations: [ContainerComponent],
  imports: [CommonModule, MatCardModule, TabNavModule, RouterOutletModule],
})
export class ContainerModule {}
