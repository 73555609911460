import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { MessageModule } from '@shared/components/message/message.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { LookupsComponent } from './lookups.component';

@NgModule({
  declarations: [LookupsComponent],
  imports: [
    CommonModule,
    MessageModule,
    SharedDirectivesModule,
    MatButtonModule,
    FormEditorModule,
  ],
})
export class LookupsModule {}
