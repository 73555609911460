import { AbstractControl, FormGroup } from '@angular/forms';
import { TranslationKey } from '@core/types/translations.model';
import { Observable } from 'rxjs';

export interface EnrichableFormEditorConfig {
  formFieldClassList?: string[];
  classList?: string[];
  dirtyStateTracker?: string;
  form: FormGroup;
  fields: Array<Partial<AnyFormItem>[]> | Partial<AnyFormItem>[];
}

export const FormItemTypeMap = {
  text: true,
  password: true,
  textarea: true,
  select: true,
  number: true,
  checkbox: true,
};

export type FormItemButtonType = 'button.loading' | 'button.plain';

export type FormItemType =
  | 'text'
  | 'password'
  | 'textarea'
  | 'slideToggle'
  | 'select'
  | 'number'
  | FormItemButtonType;

export const FormItemDefaults = {
  type: 'text' as FormItemType,
  classList: ['col-xs-12', 'col-sm-6', 'col-m-4', 'col-l-3'],
  disabled: false,
  required: false,
};

export interface FormItemError {
  when: (control: AbstractControl) => boolean;
  label: string;
  labelKey?: string;
}

export interface FormItem {
  // Configurable
  label: string;
  formControl: AbstractControl;
  type: FormItemType;
  classList: string[];
  required: boolean;
  errors?: FormItemError[];
  // Computable, use enrichConfigToDefaults to compute
  hasErrors: boolean;
  isFormItem: boolean;
  isButtonItem: boolean;
  showIf?: () => boolean;
  // Items from which to enrich
  labelKey?: TranslationKey;
  error?: Partial<FormItemError>;
  formControlName?: string;
}

export interface FormItemButton extends FormItem {
  type: FormItemButtonType;
  enableAlways?: boolean;
  confirm?: boolean;
  callback: () => Promise<any> | Observable<any> | boolean | void;
}

export interface FormItemSelect extends FormItem {
  type: 'select';
  options: {
    value: any;
    label?: string;
    labelKey?: TranslationKey;
  }[];
}

export type AnyFormItem = FormItem | FormItemButton | FormItemSelect;

export interface FormEditorConfig {
  dirtyStateTracker?: string; // Key for tracking dirty state
  formFieldClassList?: string[]; //
  form: FormGroup;
  fields: Array<AnyFormItem[]>;
}
