import {
  Component,
  Output,
  Input,
  EventEmitter,
  HostBinding,
} from '@angular/core';
import { ModalSize } from '../services/modal.service';

export interface BaseConfig {
  __modalSize: ModalSize;
}

@Component({
  selector: 'app-base-modal',
  template: '',
  styles: [],
})
export class BaseModalComponent {
  protected _data: any;

  @HostBinding('class.modal-xl') isXl: boolean = false;
  @HostBinding('class.modal-m') isM: boolean = false;

  @Output() closeModal: EventEmitter<any> = new EventEmitter();

  @Input() set data(value: any) {
    if (!!value) {
      this.isXl = value.__modalSize == ModalSize.XL;
      this.isM = value.__modalSize == ModalSize.M;
      this._data = value;
    }
  }

  get data() {
    return this._data;
  }

  constructor() {}

  close(data: any): void {
    this.closeModal.emit(data);
  }
}
