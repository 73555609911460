import { NgIf } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';

@Directive({
  selector: '[appDirtyShower]',
})
export class DirtyShowerDirective {
  private ngIfDirective: NgIf;

  @Input() showWhenDirty: string;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private cdr: ChangeDetectorRef
  ) {
    this.ngIfDirective = new NgIf(this.viewContainer, this.templateRef);
  }

  ngOnInit() {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.dirtyStateTrackerService.isSomethingDirty$,
      () => {
        this.ngIfDirective.ngIf = this.dirtyStateTrackerService.isDirty(
          this.showWhenDirty
        );
      }
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }
}
