import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponent } from './main.component';
import { MatCardModule } from '@angular/material/card';
import { MessageModule } from '@shared/components/message/message.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { LoadingModule } from '@shared/components/loading/loading.module';
import { NavSecondModule } from '@shared/components/nav-second/nav-second.module';
import { EditingToolbarModule } from '../editing-toolbar/editing-toolbar.module';
import { PlainModule } from '../pages/plain/plain.module';
import { MailingListModule } from '../pages/mailing-list/mailing-list.module';

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MessageModule,
    SharedDirectivesModule,
    PlainModule,
    MailingListModule,
    LoadingModule,
    NavSecondModule,
    EditingToolbarModule,
  ],
})
export class MainModule {}
