import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AutosizeTextareaDirective } from './autosize-textarea.directive';
import { ConfirmClickDirective } from './confirm-click.directive';
import { ContentDirective } from './content.directive';
import { DirtyShowerDirective } from './dirty-shower.directive';
import { GroupPermissionDirective } from './group-permission.directive';
import { MediaMatcherDirective } from './media-matcher.directive';
import { TranslateDirective } from './translate.directive';
import { UserPermissionDirective } from './user-permission.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    MediaMatcherDirective,
    TranslateDirective,
    UserPermissionDirective,
    ContentDirective,
    GroupPermissionDirective,
    ConfirmClickDirective,
    AutosizeTextareaDirective,
    DirtyShowerDirective,
  ],
  exports: [
    MediaMatcherDirective,
    TranslateDirective,
    UserPermissionDirective,
    ContentDirective,
    GroupPermissionDirective,
    ConfirmClickDirective,
    AutosizeTextareaDirective,
    DirtyShowerDirective,
  ],
})
export class SharedDirectivesModule {
  constructor() {}
}
