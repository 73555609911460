import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ElementRef,
  Input,
  ChangeDetectorRef,
} from '@angular/core';
import { DomService } from '../../services/dom.service';
import { SubscriptionBaseComponent } from '../../base-components/subscription-base/subscription-base.component';
import { NavService } from '@core/services/nav.service';

@Component({
  selector: 'app-router-outlet',
  templateUrl: './router-outlet.component.html',
  styleUrls: ['./router-outlet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RouterOutletComponent
  extends SubscriptionBaseComponent
  implements OnInit {
  @Input() routes: { [path: string]: any };
  wasFound = true;
  private currentComponent: number;

  constructor(
    private domService: DomService,
    private elementRef: ElementRef,
    private navService: NavService,
    private cdr: ChangeDetectorRef
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub(this.navService.activePath$).subscribe((path) =>
      this.setComponent(path)
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    if (this.currentComponent) {
      this.domService.removeComponent(this.currentComponent);
    }
  }

  private setComponent(route: string) {
    if (this.currentComponent) {
      this.domService.removeComponent(this.currentComponent);
    }
    this.wasFound = route in this.routes;
    if (this.wasFound) {
      this.currentComponent = this.domService.appendComponentToElem(
        this.routes[route],
        this.elementRef.nativeElement
      );
    }
    this.cdr.detectChanges();
  }
}
