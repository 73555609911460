import { Injectable } from '@angular/core';
import {
  LookupListDTO,
  LookupListItemDTO,
  LookupListItemsDTO,
  LookupListItemSortOrderDTO,
  LookupListsDTO,
} from '@gen/gen.dto';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { CacherUtils } from '@shared/utils/cacher.util';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { GroupLookupsApiService } from './group-lookups-api.service';

@Injectable({
  providedIn: 'root',
})
export class GroupLookupsService {
  private cacheLists: { [key: string]: LookupListsDTO } = {};
  private cacheItems: { [key: string]: LookupListItemsDTO } = {};

  constructor(
    private api: GroupLookupsApiService,
    private currentGroupService: CurrentGroupService
  ) {}

  // List operations
  getLists(groupId?: string): Observable<LookupListsDTO> {
    const useGroupId = groupId || this.currentGroupService.currentGroupId;
    return CacherUtils.cacher(useGroupId, this.cacheLists, (groupId: string) =>
      this.api.getLookups(groupId)
    );
  }

  getItems(listId: string): Observable<LookupListItemsDTO> {
    return CacherUtils.cacher(listId, this.cacheItems, (listId: string) =>
      this.api.getItems(listId)
    );
  }

  addList(list: LookupListDTO) {
    const groupId = this.currentGroupService.currentGroupId;
    return this.api.addList(groupId, list).pipe(
      tap((i) => {
        if (groupId in this.cacheLists) {
          this.cacheLists[groupId].lookupLists.push(i);
        }
      })
    );
  }

  updateList(list: LookupListDTO) {
    return this.api.updateList(list).pipe(
      tap((response) => {
        const groupId = response.groupId;
        if (groupId in this.cacheLists) {
          const itemIndex = this.cacheLists[groupId].lookupLists.findIndex(
            (i) => i.id === response.id
          );
          if (itemIndex > -1) {
            this.cacheLists[groupId].lookupLists[itemIndex] = response;
          }
        }
        if (response.id in this.cacheItems) {
          this.cacheItems[response.id].lookupList = response;
        }
      })
    );
  }

  removeList(list: LookupListDTO) {
    return this.api.removeList(list.id).pipe(
      tap(() => {
        const groupId = list.groupId;
        if (groupId in this.cacheLists) {
          const itemIndex = this.cacheLists[groupId].lookupLists.findIndex(
            (i) => i.id === list.id
          );
          if (itemIndex > -1) {
            this.cacheLists[groupId].lookupLists.splice(itemIndex, 1);
          }
        }
        if (list.id in this.cacheItems) {
          delete this.cacheItems[list.id];
        }
      })
    );
  }

  copyNonExistingValues(fromList: LookupListDTO, toList: LookupListDTO) {
    return this.api
      .copyNonExistingValues(fromList.id, toList.id)
      .pipe(tap((i) => (this.cacheItems[toList.id] = i)));
  }

  // Item operations
  addItems(lookupList: LookupListDTO, items: LookupListItemDTO[]) {
    return this.api
      .addItems(lookupList.id, items)
      .pipe(tap((i) => (this.cacheItems[lookupList.id] = i)));
  }

  updateItems(lookupList: LookupListDTO, items: LookupListItemDTO[]) {
    return this.api
      .updateItems(lookupList.id, items)
      .pipe(tap((i) => (this.cacheItems[lookupList.id] = i)));
  }

  removeItem(item: LookupListItemDTO) {
    return this.api.removeItem(item.id).pipe(
      tap(() => {
        if (item.listId in this.cacheItems) {
          const itemIndex = this.cacheItems[item.listId].items.findIndex(
            (i) => i.id === item.id
          );
          if (itemIndex > -1) {
            this.cacheItems[item.listId].items.splice(itemIndex, 1);
          }
        }
      })
    );
  }

  sortItems(list: LookupListDTO, items: LookupListItemSortOrderDTO[]) {
    return this.api
      .sortItems(list.id, items)
      .pipe(tap((i) => (this.cacheItems[list.id] = i)));
  }
}
