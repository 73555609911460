import { Injectable } from '@angular/core';
import { UserDTO } from '@gen/gen.dto';
import { RegionalFormattingService } from '@shared/services/regional-formatting.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class UserStore {
  user$ = new BehaviorSubject<UserDTO | undefined>(undefined);

  constructor(private regionalFormattingService: RegionalFormattingService) {}

  get user(): Observable<UserDTO | undefined> {
    return this.user$.asObservable();
  }

  set(data: UserDTO) {
    if (data) {
      this.regionalFormattingService.setLocale(data.locale);
    }
    this.user$.next(data);
  }

  afterLogOut() {
    this.user$.next(undefined);
  }
}
