import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { EventTypeDTO } from '@gen/gen.dto';
import { FormEditorConfig } from '@shared/components/form-editor/form-editor.model';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { ToasterService } from '@shared/services/toaster.service';
import { tap } from 'rxjs/operators';
import { GroupEventSettingsService } from '../../../../services/group-event-settings.service';
import { EventTypesFormUtils } from './event-types-form.utils';
const DIRTY_KEY = 'EVENT_CALENDAR_EDITOR';

type PageAction = 'SELECT' | 'ADD' | 'MODIFY';

@Component({
  selector: 'app-event-type-editor',
  templateUrl: './event-types.component.html',
  styleUrls: ['./event-types.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventTypesComponent implements OnInit {
  selectConfig: FormEditorConfig | undefined;
  list: EventTypeDTO[];
  addConfig: FormEditorConfig;
  editConfig: FormEditorConfig;
  action: PageAction = 'SELECT';
  editing: EventTypeDTO;

  constructor(
    private eventSettingsService: GroupEventSettingsService,
    private tx: TranslationService,
    private fb: FormBuilder,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private toasterService: ToasterService,
    private formUtils: EventTypesFormUtils,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.reloadList();
    this.addConfig = this.formUtils.getAddFormConfig(
      DIRTY_KEY,
      this.onAddCallback()
    );
  }

  setAction(action: PageAction) {
    this.action = action;
    this.cdr.markForCheck();
  }

  private reloadList() {
    this.action = 'SELECT';
    this.eventSettingsService.getEventTypes().subscribe((response) => {
      this.list = response.eventTypes;
      this.selectConfig = this.formUtils.getSelectConfig(this.list, () =>
        this.selectForm()
      );
      if (this.list.length > 0) {
        this.selectConfig.form.patchValue({
          list: this.list[0].id,
        });
      } else {
        this.action = 'ADD';
      }
      this.cdr.markForCheck();
    });
  }

  selectForm(id?: string) {
    this.action = 'MODIFY';
    const selectedId = id || this.selectConfig.form.value.list;
    const selected = this.list.find((i) => i.id === selectedId);
    this.editing = selected;
    this.editConfig = this.formUtils.getEditFormConfig(
      DIRTY_KEY,
      this.onSaveCallback()
    );
    this.editConfig.form.patchValue(selected);
    this.cdr.detectChanges();
  }

  getRemove() {
    return () => {
      return this.eventSettingsService.removeEventType(this.editing.id).pipe(
        tap((i) => {
          this.toasterService.showSuccessTranslationKey(
            TranslationService.KM.COMMON_UI.OK.DELETED
          );
          this.reloadList();
        })
      );
    };
  }

  getSetDefault() {
    return () => {
      return this.eventSettingsService
        .setEventTypeToDefault(this.editing.id)
        .pipe(
          tap((i) => {
            this.list.forEach((i) => (i.isDefault = false));
            this.editing.isDefault = true;
            this.editConfig.form.patchValue({ isDefault: true });
            this.toasterService.showSuccessTranslationKey(
              TranslationService.KM.COMMON_UI.OK.SAVED
            );
            this.cdr.detectChanges();
          })
        );
    };
  }

  private onSaveCallback() {
    return () => {
      const data = Object.assign({}, this.editConfig.form.value);
      data.id = this.editing.id;
      this.editConfig.form.markAsUntouched();
      this.editConfig.form.markAsPristine();
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      return this.eventSettingsService.updateEventType(data).pipe(
        tap((i) => {
          this.reloadList();
          this.toasterService.showSuccessTranslationKey(
            TranslationService.KM.COMMON_UI.OK.SAVED
          );
        })
      );
    };
  }

  private onAddCallback() {
    return () => {
      const data = Object.assign({}, this.addConfig.form.value);
      this.addConfig.form.patchValue({ name: '' });
      this.addConfig.form.markAsUntouched();
      this.addConfig.form.markAsPristine();
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      return this.eventSettingsService.addEventType(data).pipe(
        tap((i) => {
          this.reloadList();
          this.toasterService.showSuccessTranslationKey(
            TranslationService.KM.COMMON_UI.OK.ADDED
          );
        })
      );
    };
  }
}
