<form [formGroup]="colorsForm">

  <ng-container *ngFor="let group of data">
    <div class="row">
      <ng-container *ngFor="let i of group">
        <div class="col-xs-9 col-sm-3">
          <mat-form-field class="full-width">
            <mat-label>{{i.label}}</mat-label>
            <input matInput [formControlName]="i.form">
          </mat-form-field>
        </div>
        <div class="col-xs-3 col-sm-3">
          <div [ngClass]="i.classList" #cssElem [cpOKButton]="true" (colorPickerSelect)="selected($event, i.form)"
            [(colorPicker)]="i.color"></div>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <div class="row">
    <div class="col-xs-9 col-sm-3">
      <shared-loading-button [cb]="save()"></shared-loading-button>
    </div>
  </div>

</form>