import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/services/api-v1.models';
import {
  MailingListMembersDTO,
  MailingListMessagesDTO,
  SendMessageDTO,
  StatusDTO,
} from '@gen/gen.dto';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MailingListApiService {
  constructor(private http: HttpClient) {}

  subscribeToMailingList(pageId: string, email: string): Observable<StatusDTO> {
    const url = subsToUrl(API.CMS.MAILING_LIST.SUBSCRIBE, { pageId });
    return this.http.post<StatusDTO>(url, { email });
  }

  sendToMailingList(
    pageId: string,
    message: SendMessageDTO
  ): Observable<StatusDTO> {
    const url = subsToUrl(API.GROUP.CMS.MAILING_LIST.SEND_MESSAGE, { pageId });
    return this.http.post<StatusDTO>(url, message);
  }

  getSubscribers(groupId: string, skip = 0, limit = 100) {
    const url = subsToUrl(
      API.GROUP.CMS.MAILING_LIST.SUBSCRIBERS,
      { groupId },
      { skip, limit }
    );
    return this.http.get<MailingListMembersDTO>(url);
  }

  getMessages(groupId: string, skip = 0, limit = 100) {
    const url = subsToUrl(
      API.GROUP.CMS.MAILING_LIST.MESSAGES,
      { groupId },
      { skip, limit }
    );
    return this.http.get<MailingListMessagesDTO>(url);
  }

  removeSubscriber(subscriberId: string) {
    const url = subsToUrl(API.GROUP.CMS.MAILING_LIST.SUBSCRIBER, {
      subscriberId,
    });
    return this.http.delete<StatusDTO>(url);
  }
}
