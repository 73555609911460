import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DatService } from '@core/services/dat.service';
import { LoginDTO, UserDTO } from '@gen/gen.dto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { API } from './api-v1.models';

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  constructor(private http: HttpClient, private datService: DatService) {}

  login(credentials: LoginDTO): Observable<UserDTO> {
    return this.http
      .post<UserDTO>(API.AUTH.LOGIN, credentials)
      .pipe(map((i) => this.filterUserDTO(i)));
  }

  getMe(): Observable<UserDTO> {
    return this.http
      .get<UserDTO>(API.USER.ME)
      .pipe(map((i) => this.filterUserDTO(i)));
  }

  setMe(data: Partial<UserDTO>): Observable<UserDTO> {
    return this.http
      .put<UserDTO>(API.USER.ME, data)
      .pipe(map((i) => this.filterUserDTO(i)));
  }

  logout(): Observable<void> {
    return this.http.get<void>(API.AUTH.LOGOUT);
  }

  private filterUserDTO(user: UserDTO): UserDTO {
    if (!this.datService.isGroupRestriction) {
      return user;
    }
    const onlyGroup = user.groupMemberships.find(
      (i) => i.group.id === this.datService.onlyGroupId
    );
    user.groupMemberships = onlyGroup ? [onlyGroup] : [];
    return user;
  }
}
