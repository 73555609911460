import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotImplementedComponent } from './not-implemented.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { MatCardModule } from '@angular/material/card';

@NgModule({
  declarations: [NotImplementedComponent],
  imports: [CommonModule, SharedDirectivesModule, MatCardModule],
})
export class NotImplementedModule {}
