import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivationStart, Router } from '@angular/router';
import { TranslationService } from '@core/services/translation.service';
import { CmsPageDTO, CmsPageType } from '@gen/gen.dto';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { ToasterService } from '@shared/services/toaster.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { CmsPageService } from './cms-page.service';

const DIRTY_KEY = 'CMS_EDIT_PAGE_DEFAULT_EDITOR';

@Injectable({ providedIn: 'root' })
export class CmsEditService {
  isEditing$ = new BehaviorSubject<boolean>(false);
  editorForm$ = new BehaviorSubject<FormGroup>(this.fb.group({}));

  private formSubscription: Subscription | undefined = undefined;
  private currentPage: CmsPageDTO;

  constructor(
    private fb: FormBuilder,
    private cmsPageService: CmsPageService,
    private toasterService: ToasterService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private router: Router
  ) {
    this.dirtyStateTrackerService.clearDirtyState$.subscribe(() => {
      if (this.formSubscription) {
        this.isEditing$.next(false);
      }
    });
    router.events
      .pipe(filter((event) => event instanceof ActivationStart))
      .subscribe((event) => {
        if (this.isEditing$.value) {
          this.isEditing$.next(false);
        }
      });
  }

  // Set current page
  setCurrentPage(page: CmsPageDTO) {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
    const form = this.fb.group({
      content: this.getContentFormGroup(page),
      metaDescription: [''],
      metaKeywords: [''],
    });
    this.patchValue(form, page);
    this.editorForm$.next(form);
    if (this.currentPage && this.currentPage.id !== page.id) {
      this.isEditing$.next(false);
    }
    this.currentPage = page;
    this.formSubscription = form.valueChanges.subscribe((i) => {
      if (form.dirty) {
        this.dirtyStateTrackerService.setDirty(DIRTY_KEY);
      }
    });
  }

  private getContentFormGroup(page: CmsPageDTO) {
    let obj: { [key: string]: any[] } = {
      html: [''],
    };
    switch (page.pageType) {
      case CmsPageType.MAILING_LIST:
        obj['subscribeMessage'] = [''];
        obj['messageFooter'] = [''];
    }
    return this.fb.group(obj);
  }

  private patchValue(fg: FormGroup, obj: any) {
    Object.keys(fg.controls).forEach((i) => {
      if ('controls' in fg.controls[i] && obj && i in obj) {
        this.patchValue(fg.controls[i] as FormGroup, obj[i]);
      } else if (obj && i in obj) {
        fg.controls[i].patchValue(obj[i]);
      }
    });
  }

  startEditing() {
    this.isEditing$.next(true);
  }

  stopEditing() {
    this.isEditing$.next(false);
    this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
    this.setCurrentPage(this.currentPage);
  }

  save(stopEditing: boolean) {
    const value = this.getAllEditedValues(this.editorForm$.value);
    return this.cmsPageService.savePage(this.currentPage.id, value).pipe(
      tap((reply) => {
        this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
        this.toasterService.showSuccessTranslationKey(
          TranslationService.KM.COMMON_UI.OK.SAVED
        );
        this.currentPage = reply;
        if (stopEditing) {
          this.isEditing$.next(false);
        }
      })
    );
  }

  private getAllEditedValues(fg: FormGroup) {
    let response: any = {};
    Object.keys(fg.controls).forEach((i) => {
      if ('controls' in fg.controls[i]) {
        response[i] = this.getAllEditedValues(fg.controls[i] as FormGroup);
      } else {
        response[i] = fg.controls[i].value;
      }
    });
    return response;
  }
}
