import { AbstractControl } from '@angular/forms';
import { first } from 'rxjs/operators';

export class CustomValidators {
  static match(sourceControl: () => AbstractControl | undefined) {
    return (targetControl: AbstractControl) => {
      const source = sourceControl();
      if (!source) {
        return null;
      }
      const firstControlValue = source.value;
      const secondControlValue = targetControl.value;
      if (firstControlValue !== secondControlValue) {
        return { match: true };
      }
      return null;
    };
  }
}
