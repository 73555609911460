import { Injectable } from '@angular/core';
import { VersionService } from '@core/services/version.service';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

export type CacheContent<T> = {
  id: string;
  version: number;
  value: T;
  waiters: { resolve: (T) => void; reject: (any) => void }[];
};

export type Cache<T> = {
  [id: string]: CacheContent<T>;
};

@Injectable({
  providedIn: 'root',
})
export class CacherUtils {
  private caches: { [area: string]: Cache<any> } = {};

  constructor(private versionService: VersionService) {}

  static cacher<T>(
    id: string,
    cache: { [key: string]: T },
    fun: (key: string) => Observable<T>
  ): Observable<T> {
    if (id in cache) {
      return of(cache[id]);
    }
    return fun(id).pipe(tap((response) => (cache[id] = response)));
  }

  private resolvePromises<T>(cache: CacheContent<T>): void {
    cache.waiters.forEach((i) => {
      i.resolve(cache.value);
    });
    cache.waiters = [];
  }

  private fetchNewValue<T>(
    cache: CacheContent<T>,
    fun: (key: string) => Observable<T>
  ): void {
    fun(cache.id).subscribe((response) => {
      cache.value = response;
      cache.version = new Date().getTime();
      this.resolvePromises(cache);
    });
  }

  versionedAutoCacher<T>(
    area: string,
    id: string,
    fun: (key: string) => Observable<T>
  ): Promise<T> {
    if (!(area in this.caches)) {
      this.caches[area] = {};
    }
    // Already cached
    if (id in this.caches[area]) {
      const promise = new Promise<T>((resolve, reject) => {
        cache.waiters.push({ resolve, reject });
      });
      const cache = (this.caches[area][id] as unknown) as CacheContent<T>;
      // Exactly one waiter, so need to check version and act based on taht
      if (cache.waiters.length === 1) {
        this.versionService.getVersion(id).subscribe((version) => {
          if (version < cache.version) {
            this.resolvePromises(cache);
          } else {
            this.fetchNewValue(cache, fun);
          }
        });
      }
      return promise;
    }
    const cache = {
      id,
      version: undefined,
      value: null,
      waiters: [],
    };
    const promise = new Promise<T>((resolve, reject) => {
      cache.waiters.push({ resolve, reject });
    });
    this.fetchNewValue(cache, fun);
    return promise;
  }
}
