import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';
import { FormEditorConfig } from './form-editor.model';

@Component({
  selector: 'shared-form-editor',
  templateUrl: './form-editor.component.html',
  styleUrls: ['./form-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormEditorComponent implements OnInit {
  private config_: FormEditorConfig;
  @Input() set config(value: FormEditorConfig) {
    // If this changes, we stop listening to previous form (in case we were listeining to it)
    this.ngOnDestroy();
    this.config_ = value;
    this.init();
  }

  get config() {
    return this.config_;
  }

  constructor(
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {}

  private init(): void {
    if (!this.config) {
      return;
    }
    if (!this.config.formFieldClassList) {
      this.config.formFieldClassList = [];
    }
    if (this.config.dirtyStateTracker) {
      this.trackDirtyState();
    }
    this.cdr.detectChanges();
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  private trackDirtyState() {
    LifeCyclesUtil.sub(this, this.config.form.valueChanges, (i) => {
      if (this.config.form.dirty) {
        this.dirtyStateTrackerService.setDirty(this.config.dirtyStateTracker!);
      } else {
        this.dirtyStateTrackerService.removeDirty(
          this.config.dirtyStateTracker!
        );
      }
    });
  }
}
