<div class="toolbar" (click)="closeRightNavIfOpen()" *ngIf="!cmsConfig || isLoggedIn">
  <ng-container *ngIf="!cmsConfig && !isGroupRestriction">
    <span (click)="home()" class="clickable toolbar__item toolbar__item--title">{{title}}</span>
    <span class="global__flex-spacer"></span>
  </ng-container>

  <ng-container *ngIf="isLoggedIn">

    <ng-container *ngIf="isGroupRestriction">
      <app-group-selector class="toolbar__item" [selectedGroup]="selectedGroup" [groups]="user.groupMemberships"
        (onSelect)="selectGroup($event)">
      </app-group-selector>
      <span class="global__flex-spacer" *ngIf="!cmsConfig"></span>
    </ng-container>

    <app-group-selector *ngIf="!isGroupRestriction" class="toolbar__item" [selectedGroup]="selectedGroup"
      [groups]="user.groupMemberships" (onSelect)="selectGroup($event)">
    </app-group-selector>

    <ng-container *ngIf="!cmsConfig && !isGroupRestriction">
      <span class="toolbar__spacer" *appMediaMatcher="'m-'"></span>
    </ng-container>

    <ng-container *ngIf="cmsConfig">
      <ng-container *appGroupPermission="'GRP_MANAGE_WEB_PORTAL'">
        <span class="toolbar__spacer"></span>
        <span class="toolbar__item" (click)="modifyCms()">
          <span class="toolbar__item__text--with-icon" *appMediaMatcher="'m-'" translate="COMMON_UI.KEY_WORD.MODIFY">
          </span>
          <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-cogs">
          </mat-icon>
        </span>
      </ng-container>
      <span class="global__flex-spacer"></span>
    </ng-container>

    <ng-container *ngIf="!isGroupRestriction">
      <ng-container *appMediaMatcher="'l-'">
        <ng-container *appUserPermission="'SYS_CREATE_GROUP'">
          <span class="toolbar__item" (click)="newGroup()">
            <span class="toolbar__item__text--with-icon" translate="COMMON_UI.KEY_WORD.NEW_GROUP"></span>
            <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-plus-square">
            </mat-icon>
          </span>

          <span class="toolbar__spacer"></span>
        </ng-container>
      </ng-container>
    </ng-container>

    <ng-container *appMediaMatcher="'m-'">

      <ng-container *appUserPermission="'SYS_CREATE_EVENT'">
        <span class="toolbar__item" (click)="newEvent()">
          <span class="toolbar__item__text--with-icon" translate="COMMON_UI.KEY_WORD.NEW_EVENT"></span>
          <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-calendar-plus">
          </mat-icon>
        </span>

        <span class="toolbar__spacer"></span>
      </ng-container>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="isLoggedIn || !cmsConfig">
    <ng-container *appMediaMatcher="'m-'">
      <span class="toolbar__item" (click)="openRightNav()">
        <ng-container *ngIf="isLoggedIn">

          <span class="toolbar__item__text--with-icon">
            {{user.givenName}}
          </span>
          <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-user">
          </mat-icon>
        </ng-container>

        <ng-container *ngIf="!isLoggedIn && !cmsConfig">
          <span class="toolbar__item__text--with-icon" translate="COMMON_UI.KEY_WORD.LOGIN">
          </span>
          <mat-icon class="toolbar__item__text--icon" fontSet="fa" fontIcon="fa-sign-in-alt">
          </mat-icon>
        </ng-container>
      </span>

    </ng-container>
  </ng-container>

  <ng-container *ngIf="isLoggedIn || !cmsConfig">
    <ng-container *appMediaMatcher="'-s'">
      <span class="toolbar__item" (click)="openRightNav()">
        <mat-icon class="toolbar__item__text--icon toolbar__item__text--icon--center" fontSet="fa" fontIcon="fa-bars">
        </mat-icon>
      </span>
    </ng-container>
  </ng-container>


</div>

<mat-sidenav-container [ngClass]="{'sidenav-container': true,
    'sidenav-container__top-margin': !cmsConfig || isLoggedIn}">
  <!-- Right side nav -->
  <mat-sidenav #sidenav mode="over" [opened]="rightOpened" position="end" [fixedTopGap]="topGap" fixedBottomGap="0">
    <core-right-side-nav (close)="closeRightNavIfOpen()">
    </core-right-side-nav>
  </mat-sidenav>
  <mat-sidenav-content>
    <!-- Second navigation level -->
    <app-nav-second *ngIf="hasSecondNav()" [externalActive]="!!cmsConfig" [links]="secondNav"
      class="second-nav dropdown"></app-nav-second>

    <div style="margin: 5px">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>