import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RightSideNavComponent } from './right-side-nav.component';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatListModule } from '@angular/material/list';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';

@NgModule({
  declarations: [RightSideNavComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatListModule,
    MatIconModule,
    SharedDirectivesModule,
  ],
  exports: [RightSideNavComponent],
})
export class RightSideNavModule {}
