const PRI_API = {
  AUTH: {
    LOGIN: '',
    LOGOUT: '',
  },
  USER: {
    url: '/auth', // this way the path '/user' is changed to '/auth'
    ME: '',
  },
  VERSION: {
    url: '/version/:id',
  },
  VERSION_AREA: {
    url: '/version/:id/:area',
  },
  GROUP_CREATE: {
    AS_USER: '',
  },
  GROUP: {
    AVAILABLE_FEATURES: '/available-features/as-user',
    CMS: {
      CONFIG: '/group/:groupId/config',
      ADD_PAGE: '/group/:groupId/add-page',
      PAGE: '/page/:pageId',
      PAGE_ORDER: '/page/:pageId/move-to/:newIndex',
      MAILING_LIST: {
        url: '',
        SEND_MESSAGE: '/page/:pageId/mailing-list/send-message',
        SUBSCRIBER: '/mailing-list/subscriber/:subscriberId',
        SUBSCRIBERS: '/group/:groupId/mailing-list/subscribers',
        MESSAGES: '/group/:groupId/mailing-list/messages',
      },
      CSS_VARS: '/group/:groupId/css-vars',
    },
    LOOKUP_LIST: {
      LISTS: '/group/:groupId',
      LIST: '/:listId',
      SORT_ITEMS: '/:listId/sort-items',
      COPY_NON_EXISTING: '/:fromListId/copy-list-to/:toListId',
      ITEMS: '/:listId/items',
      ITEM: '/item/:itemId',
    },
    GET: '/:groupId',
    SETTINGS: {
      url: '',
      GENERAL: '/:groupId/settings',
      FEATURE: '/:groupId/settings/feature/:featureId',
      EVENTS: {
        url: '',
        SIGN_UP_FORMS: '/:groupId/settings/events/sign-up-forms',
        SIGN_UP_FORM: '/settings/events/sign-up-forms/:signUpFormId',
        CALENDARS: '/:groupId/settings/events/calendars',
        CALENDAR: '/settings/events/calendars/:calendarId',
        EVENT_TYPES: '/:groupId/settings/events/event-types',
        EVENT_TYPE: '/settings/events/event-types/:eventTypeId',
      },
    },
  },
  CMS: {
    CONFIG: '/:slug/config',
    PAGE: '/page/:pageId',
    MAILING_LIST: {
      url: '/mailing-list/:pageId',
      SUBSCRIBE: '',
    },
  },
};

function normalize(name: string) {
  const regex = /_/gi;
  name = name.replace(regex, '-');
  return name.toLowerCase();
}

function rec_create(path: string, data: any) {
  for (const i in data) {
    if (i.match(/^[A-Z_]{1,}$/) !== null) {
      if (typeof data[i] === 'string') {
        if (data[i] === '') {
          data[i] = path + '/' + normalize(i);
        } else {
          data[i] = path + data[i];
        }
      } else {
        let newPath = '';
        if ('url' in data[i]) {
          newPath = path + data[i].url;
        } else {
          newPath = path + '/' + normalize(i);
        }
        rec_create(newPath, data[i]);
      }
    }
  }
  return data;
}

export const API: typeof PRI_API = rec_create(
  '/api',
  PRI_API
) as typeof PRI_API;
