import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { LookupListDTO, LookupListItemsDTO } from '@gen/gen.dto';
import { FormEditorConfig } from '@shared/components/form-editor/form-editor.model';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { ToasterService } from '@shared/services/toaster.service';
import { tap } from 'rxjs/operators';
import { GroupLookupsService } from '../../../services/group-lookups.service';
import { LookupsUtil } from './lookups.util';

type PageAction = 'VIEW_LISTS' | 'ADD_LIST' | 'EDIT_LIST' | 'EDIT_ITEMS';

@Component({
  selector: 'app-lookups',
  templateUrl: './lookups.component.html',
  styleUrls: ['./lookups.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: LookupsUtil,
      useClass: LookupsUtil,
    },
  ],
})
export class LookupsComponent implements OnInit {
  lists: LookupListDTO[];
  listForm: FormGroup;
  action: PageAction = 'VIEW_LISTS';

  selectConfig: FormEditorConfig | undefined;
  config: FormEditorConfig;

  private editing: LookupListItemsDTO | undefined;

  constructor(
    private service: GroupLookupsService,
    private fb: FormBuilder,
    private dirtyStateTracker: DirtyStateTrackerService,
    private cdr: ChangeDetectorRef,
    private toastService: ToasterService,
    private lookupsUtil: LookupsUtil
  ) {}

  async ngOnInit() {
    await this.initLists();
  }

  private initListAdd() {
    this.action = 'ADD_LIST';
    this.listForm = this.lookupsUtil.getListForm();
    this.config = this.lookupsUtil.getListConfig(this.listForm, {
      save: () => this.addList(),
      cancel: () => this.changeAction('VIEW_LISTS'),
    });
    this.cdr.detectChanges();
    return true;
  }

  async changeAction(action: PageAction) {
    const canContinue = await this.dirtyStateTracker.confirmNotDirty();
    if (!canContinue) {
      return false;
    }
    if (action == 'ADD_LIST') {
      this.initListAdd();
    }
    this.action = action;
    this.cdr.markForCheck();
    return true;
  }

  private selectList(id?: string) {
    // Select now from form
    this.action = 'EDIT_ITEMS';
    const listId = id || this.selectConfig.form.value.list;
    return this.service.getItems(listId).pipe(
      tap((list) => {
        this.editing = list;
        this.listForm = this.lookupsUtil.getListForm();
        this.config = this.lookupsUtil.getListConfig(
          this.listForm,
          {
            save: () => this.updateList(),
            cancel: () => this.changeAction('VIEW_LISTS'),
            remove: () => this.removeList(),
          },
          true
        );
        this.config.form.patchValue(list.lookupList);
        this.cdr.detectChanges();
      })
    );
  }

  private updateList() {
    return this.service.updateList(this.listForm.value).pipe(
      tap((i) => {
        this.listForm.markAsUntouched();
        this.listForm.markAsPristine();
        //         this.lookupsUtil.stopTracking(FormTrackingKeys.LIST);
      })
    );
  }

  private removeList() {
    return this.service.removeList(this.listForm.value).pipe(
      tap((i) => {
        this.listForm.markAsUntouched();
        this.listForm.markAsPristine();
        this.dirtyStateTracker.removeAllDirtyStates();
        this.changeAction('VIEW_LISTS');
        this.initLists();
      })
    );
  }

  private addList() {
    return this.service.addList(this.listForm.value).pipe(
      tap(async (list) => {
        // This also resets dirty state tracker
        this.listForm = this.lookupsUtil.getListForm();
        this.toastService.showSuccessTranslationKey(
          TranslationService.KM.UI.GROUP.SETTINGS.LOOKUPS.LIST_ADDED_OK
        );
        await this.initLists();
        await this.selectList(list.id).toPromise();
        this.selectConfig.form.patchValue({
          list: list.id,
        });
      })
    );
  }

  private initLists() {
    return this.service
      .getLists()
      .toPromise()
      .then((i) => {
        this.lists = i.lookupLists;
        if (this.lists.length > 0) {
          this.selectConfig = this.lookupsUtil.getListsConfig(this.lists, () =>
            this.selectList()
          );
          this.selectConfig.form.patchValue({
            list: this.lists[0].id,
          });
        }
        this.cdr.detectChanges();
      });
  }
}
