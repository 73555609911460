<shared-form-editor [config]="config" *ngIf="!previewing"></shared-form-editor>

<ng-container *ngIf="previewing">

  <h2>{{form.controls.subject.value}}</h2>

  <iframe [srcdoc]="form.controls.body.value"></iframe>

  <button mat-stroked-button color="primary" (click)="previewing = !previewing" class="margin"
    translate="COMMON_UI.KEY_WORD.MODIFY"></button>

  <shared-loading-button key="COMMON_UI.KEY_WORD.SEND" [cb]="getSendCallback()">
  </shared-loading-button>

</ng-container>