import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabNavComponent } from './tab-nav.component';
import { NavSecondModule } from '@shared/components/nav-second/nav-second.module';

@NgModule({
  declarations: [TabNavComponent],
  imports: [CommonModule, NavSecondModule],
  exports: [TabNavComponent],
})
export class TabNavModule {}
