import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { LoadingButtonModule } from '../loading-button/loading-button.module';
import { FormEditorComponent } from './form-editor.component';

@NgModule({
  declarations: [FormEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    LoadingButtonModule,
    MatSlideToggleModule,
    MatButtonModule,
    MatSelectModule,
    SharedDirectivesModule,
  ],
  exports: [FormEditorComponent],
})
export class FormEditorModule {}
