import { Injectable } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  ActivatedRouteSnapshot,
  CanDeactivate,
  RouterStateSnapshot,
} from '@angular/router';
import { BehaviorSubject, Subject } from 'rxjs';
import {
  DirtyModalComponent,
  ReturnValue,
} from '../components/dirty-modal/dirty-modal.component';
import { LifeCyclesUtil } from '../utils/lifecycles.util';
import { ModalService, ModalSize } from './modal.service';

@Injectable({
  providedIn: 'root',
})
export class DirtyStateTrackerService implements CanDeactivate<any> {
  clearDirtyState$ = new Subject<void>();
  isSomethingDirty$ = new BehaviorSubject<boolean>(false);
  private dirtyStates = new Map<string, true>();

  constructor(private modalService: ModalService) {}

  setDirty(key: string) {
    if (!this.dirtyStates.has(key)) {
      this.dirtyStates.set(key, true);
      this.isSomethingDirty$.next(true);
    }
  }

  removeDirty(key: string) {
    if (this.dirtyStates.has(key)) {
      this.dirtyStates.delete(key);
    }
    this.isSomethingDirty$.next(this.dirtyStates.size > 0);
  }

  removeAllDirtyStates() {
    this.dirtyStates = new Map<string, true>();
    this.isSomethingDirty$.next(false);
  }

  isDirty(key: string) {
    return this.dirtyStates.has(key);
  }

  isAnythingDirty() {
    return this.dirtyStates.size > 0;
  }

  // Remember to stop on ngOnDestroy in component
  trackFormGroup(key: object, dirtyKey: string, form: AbstractControl) {
    LifeCyclesUtil.sub(key, form.statusChanges, (changes: any) => {
      if (form.dirty) {
        this.setDirty(dirtyKey);
      } else {
        this.removeDirty(dirtyKey);
      }
    });
  }

  trackFormGroups(
    key: object,
    formGroups: { [dirtyKey: string]: AbstractControl }
  ) {
    for (let dirtyKey in formGroups) {
      this.trackFormGroup(key, dirtyKey, formGroups[dirtyKey]);
    }
  }

  // If dirty key is not given, it looks for all
  async deactivateIfAllowed(callback: () => void, dirtyKey?: string) {
    const can = await this.confirmNotDirty(dirtyKey);
    if (can) {
      callback();
    }
  }

  canDeactivate(
    component?: any,
    currentRoute?: ActivatedRouteSnapshot,
    currentState?: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Promise<boolean> {
    return this.confirmNotDirty();
  }

  confirmNotDirty(dirtyKey?: string) {
    if (dirtyKey) {
      if (!this.isDirty(dirtyKey)) {
        return Promise.resolve(true);
      }
    } else if (!this.isAnythingDirty()) {
      return Promise.resolve(true);
    }
    return new Promise<boolean>((resolve, reject) => {
      this.modalService.showModal(DirtyModalComponent, {
        allowOverlayClick: false,
        showCloseButton: false,
        size: ModalSize.M,
        whenClosed: (value: ReturnValue) => {
          if (value === ReturnValue.DISCARD) {
            if (dirtyKey) {
              this.removeDirty(dirtyKey);
            } else {
              this.clearDirtyState$.next();
              this.removeAllDirtyStates();
            }
            resolve(true);
            return;
          }
          resolve(false);
        },
      });
    });
  }
}
