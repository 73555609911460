import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NavLink } from '@core/services/nav.models';
import { CurrentGroupService } from '@shared/services/current-group.service';

@Component({
  selector: 'app-tab-nav',
  templateUrl: './tab-nav.component.html',
  styleUrls: ['./tab-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TabNavComponent implements OnInit {
  tabLinks: NavLink[];
  constructor(
    private currentGroupService: CurrentGroupService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.currentGroupService.getSettingsNav().then((links) => {
      this.tabLinks = links;
      this.cdr.detectChanges();
    });
  }
}
