<div class="row-container" *ngIf="config">
  <form [formGroup]="config.form">
    <div class="row" *ngFor="let row of config.fields">
      <div [ngClass]="i.classList" *ngFor="let i of row">
        <ng-container *ngIf="!i.showIf || i.showIf()">
          <!-- Editable items -->
          <mat-form-field [ngClass]="config.formFieldClassList" *ngIf="i.isFormItem">
            <mat-label>{{ i.label }}</mat-label>
            <ng-container [ngSwitch]="i.type">
              <!-- Text -->
              <input *ngSwitchCase="'text'" type="text" matInput autocomplete="off" [formControl]="i.formControl">
              <!-- Number -->
              <input *ngSwitchCase="'number'" type="number" matInput autocomplete="off" [formControl]="i.formControl">
              <!-- Password -->
              <input *ngSwitchCase="'password'" type="password" matInput autocomplete="off"
                [formControlName]="i.formControlName">
              <!-- Textarea -->
              <textarea *ngSwitchCase="'textarea'" matInput [formControl]="i.formControl" [autosizeTextarea]></textarea>
              <!-- Select -->
              <select *ngSwitchCase="'select'" matNativeControl [formControl]="i.formControl">
                <option *ngFor="let opt of i.options" [value]="opt.value">{{opt.label}}</option>
              </select>
            </ng-container>
          </mat-form-field>
          <!-- SlideToggle -->
          <mat-slide-toggle *ngIf="i.type == 'slideToggle'" color="primary" [formControl]="i.formControl">
            {{i.label}}
          </mat-slide-toggle>
          <!-- Buttons -->
          <ng-container *ngIf="i.isButtonItem">
            <ng-container [ngSwitch]="i.type">
              <!-- Loading button -->
              <shared-loading-button *ngSwitchCase="'button.loading'" [text]="i.label" [confirm]="i.confirm"
                [disabled]="(config.form.status != 'VALID' || config.form.pristine) && !i.enableAlways"
                [cb]="i.callback">
              </shared-loading-button>
              <!-- Plain button -->
              <button *ngSwitchCase="'button.plain'" mat-stroked-button color="primary" (click)="i.callback()"
                [disabled]="(config.form.status !== 'VALID' || config.form.pristine) && !i.enableAlways">{{ i.label
                }}</button>
            </ng-container>
          </ng-container>
          <!-- Errors -->
          <ng-container *ngIf="i.hasErrors">
            <ng-container *ngFor="let e of i.errors">
              <mat-error *ngIf="e.when(i.formControl)">
                {{e.label}}
              </mat-error>
            </ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </form>
</div>