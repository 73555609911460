import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { MailingListMessageDTO } from '@gen/gen.dto';
import { RegionalFormattingService } from '@shared/services/regional-formatting.service';
import { MailingListService } from '../mailing-list.service';

interface Message extends MailingListMessageDTO {
  sentAtStr: string;
}

@Component({
  selector: 'cms-mailing-list-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MessagesComponent implements OnInit {
  messages: Message[];

  constructor(
    private cmsConfigService: CmsConfigService,
    private mailingListService: MailingListService,
    private regionalFormattingService: RegionalFormattingService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    const groupId = this.cmsConfigService.groupId;
    this.mailingListService.getMessages(groupId).subscribe((response) => {
      this.messages = response.messages.map((i) => {
        return Object.assign(
          {
            sentAtStr: this.regionalFormattingService.defaultRelativeDateTime(
              i.sentAt
            ),
          },
          i
        );
      });
      this.messages.sort((a, b) => b.sentAt - a.sentAt);
      this.cdr.detectChanges();
    });
  }
}
