import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ActivationStart } from '@angular/router';
import { assert } from '@shared/utils/assert.func';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { BehaviorSubject } from 'rxjs';
import { NavLink } from './nav.models';

export type SecondNavType = 'CMS' | 'GROUP';

const SECOND_NAV_SORT = ['CMS', 'GROUP'];

@Injectable({ providedIn: 'root' })
export class NavService {
  secondNav$ = new BehaviorSubject<NavLink[] | false>(false);
  private secondNavs: { [key in SecondNavType]?: NavLink[] } = {};

  activePath$ = new BehaviorSubject<string>('');

  private params: { [key: string]: any };

  constructor(private router: Router, route: ActivatedRoute) {
    router.events.subscribe((event) => {
      let route: any;
      if ('snapshot' in event) {
        route = event.snapshot;
        while (route.firstChild) {
          route = route.firstChild;
        }
      }
      if (event instanceof ActivationStart) {
        this.params = route.params || {};
        const path = '/' + route.routeConfig.path;
        if (path !== this.activePath$.value) {
          this.activePath$.next(path);
        }
      }
    });
  }

  /**
   * Second level navigation management
   */
  clearSecondNav(key: SecondNavType) {
    if (key in this.secondNavs) {
      delete this.secondNavs[key];
      this.emitCurrentSecondNav();
    }
  }

  setSecondNav(key: SecondNavType, links: NavLink[]) {
    this.secondNavs[key] = links;
    this.emitCurrentSecondNav();
  }

  private emitCurrentSecondNav() {
    for (let i in SECOND_NAV_SORT) {
      const key = SECOND_NAV_SORT[i];
      if (key in this.secondNavs) {
        this.secondNav$.next(this.secondNavs[key]);
        return;
      }
    }
    if (this.secondNav$.value) {
      this.secondNav$.next(false);
    }
  }

  /**
   * Navigating
   */
  goto(path: string | any, params: { [key: string]: any } = {}) {
    path = this.getLink(path, params);
    this.router.navigate([path]);
  }

  getLink(path: string | any, params: { [key: string]: any } = {}) {
    if (!(typeof path === 'string')) {
      return this.getLink(path.url, params);
    }
    // can't do with assign, because this.params is readonly
    for (const i in this.params) {
      if (!(i in params) && this.params.hasOwnProperty(i)) {
        params[i] = this.params[i];
      }
    }
    assert(path);
    return '/' + subsToUrl(path, params);
  }
}
