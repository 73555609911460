import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RouteCreator } from './route-creator';

const routes = RouteCreator.getRoutes();
console.log(routes);
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
