import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponentModule } from './components/login/login.component.module';
import { ProfileComponentModule } from './components/profile/profile.component.module';
import { CalendarModule } from './components/calendar/calendar.module';

@NgModule({
  imports: [
    CommonModule,
    ProfileComponentModule,
    LoginComponentModule,
    CalendarModule,
  ],
})
export class UserModule {
  constructor() {}
}
