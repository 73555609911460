<!--
<mat-form-field style="width: 40px;">
  <mat-label translate="COMMON_UI.KEY_WORD.ORDER"></mat-label>
  <input matInput autocomplete="off" [formControl]="form" required>
</mat-form-field>
-->

<button [disabled]="isFirst || disabled" mat-stroked-button class="icon" (click)="pos(-2)">
  <mat-icon fontSet="fa" fontIcon="fa-angle-double-up">
  </mat-icon>
</button>
<button [disabled]="isFirst || disabled" mat-stroked-button class="icon" (click)="pos(-1)">
  <mat-icon fontSet="fa" fontIcon="fa-angle-up">
  </mat-icon>
</button>
<button [disabled]="isLast || disabled" mat-stroked-button class="icon" (click)="pos(1)">
  <mat-icon fontSet="fa" fontIcon="fa-angle-down">
  </mat-icon>
</button>
<button [disabled]="isLast || disabled" mat-stroked-button class="icon" (click)="pos(2)">
  <mat-icon fontSet="fa" fontIcon="fa-angle-double-down">
  </mat-icon>
</button>