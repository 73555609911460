import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { BaseModalComponent } from '../../base-components/base-modal.component';

export enum ReturnValue {
  CANCEL = 'CANCEL',
  CONFIRM = 'CONFIRM',
}

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConfirmModalComponent
  extends BaseModalComponent
  implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}

  ret(discard: boolean) {
    this.close(discard ? ReturnValue.CONFIRM : ReturnValue.CANCEL);
  }
}
