import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { GroupComponentsModule } from './components/group-components.module';
import { EventEditorModule } from './events/components/editor/event-editor.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, GroupComponentsModule, EventEditorModule],
})
export class GroupModule {}
