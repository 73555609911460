import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { TranslationService } from '@core/services/translation.service';
import { StatusOption } from '@gen/gen.dto';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { ToasterService } from '@shared/services/toaster.service';
import { CustomValidators } from '@shared/validators/custom.validator';
import { tap } from 'rxjs/operators';
import { MailingListService } from '../mailing-list.service';

@Component({
  selector: 'cms-mailing-list-subscribe',
  template: '<shared-form-editor [config]="config"></shared-form-editor>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SubscribeComponent implements OnInit {
  config: FormEditorConfig;

  private form = this.fb.group({
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
    ],
    emailConfirm: [
      '',
      Validators.compose([
        CustomValidators.match(() => this.email),
        Validators.required,
      ]),
    ],
  });

  private get email() {
    return this.form?.get('email');
  }

  constructor(
    private fb: FormBuilder,
    private mailingListService: MailingListService,
    private configService: CmsConfigService,
    private toasterService: ToasterService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.config = this.getConfig();
  }

  private getConfig() {
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: ['full-width'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.EMAIL,
          formControlName: 'email',
          error: {
            labelKey:
              TranslationService.KM.UI.CMS.MAILING_LIST.ERRORS.INVALID_EMAIL,
          },
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.EMAIL_REPEAT,
          formControlName: 'emailConfirm',
          error: {
            labelKey:
              TranslationService.KM.UI.CMS.MAILING_LIST.ERRORS
                .CONFIRM_DOES_NOT_MATCH_EMAIL,
          },
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SUBSCRIBE,
          type: 'button.loading',
          callback: this.getSubscribeCallback(),
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getSubscribeCallback() {
    return () => {
      const email = this.form.controls.email.value;
      return this.mailingListService
        .subscribeToMailingList(this.configService.currentPageId, email)
        .pipe(
          tap((response) => {
            if (response.value === StatusOption.OK) {
              this.toasterService.showSuccessTranslationKey(
                TranslationService.KM.UI.CMS.MAILING_LIST.SUBSCRIPTION_OK
              );
            } else {
              this.toasterService.showErrorTranslationKey(
                TranslationService.KM.UI.CMS.MAILING_LIST.ERRORS[response.key]
              );
            }
            this.form.patchValue({
              email: '',
              emailConfirm: '',
            });
            this.form.markAsPristine();
            this.form.markAsUntouched();
          })
        );
    };
  }
}
