<ng-container *ngIf="configReady && image">
  <img [src]="image" *ngIf="mainBannerType === 'FILL'" class="main-image">
  <div *ngIf="mainBannerType === 'HEIGHT'" class="main-image-height">
    <img [src]="image">
  </div>
</ng-container>

<mat-card>

  <ng-container *ngIf="pageNotFound; else pageFound">
    <div [innerHtml]="notFoundStr"></div>
  </ng-container>

</mat-card>

<ng-template #pageFound>

  <ng-container *ngIf="configReady">
    <shared-message *ngIf="!hasPages" type="ERROR" key="UI.CMS.NO_PAGES" [params]="{portalName: portalName}">
    </shared-message>

  </ng-container>

  <ng-container *ngIf="page">
    <div class="draft" *ngIf="page.isDraft" translate="UI.CMS.DRAFT">
    </div>

    <div class="for-members-only" *ngIf="!page.isPublic && !page.isDraft" translate="UI.CMS.MEMBERS_ONLY">
    </div>
  </ng-container>

  <ng-container *appGroupPermission="'GRP_MANAGE_WEB_PORTAL'">
    <cms-editing-toolbar></cms-editing-toolbar>
  </ng-container>

  <!-- Navigation -->
  <ng-container *ngIf="configReady && !isEditing">
    <div class="goto-container" *ngIf="secondLevelLinks.length > 0">
      <app-nav-second [links]="secondLevelLinks" [allowPartialFit]="false" [externalActive]="true"
        class="tab-nav dropdown"></app-nav-second>
    </div>

    <div class="goto-container" *ngIf="siblingLinks.length > 0">
      <ng-container *ngFor="let i of siblingLinks; let ind = index">
        <span class="goto-separator" *ngIf="ind !== 0"></span>
        <span class="goto-link" [ngClass]="{active: i.isActive}" (click)="gotoLink(i)">{{i.name}}</span>
      </ng-container>
    </div>

    <div class="goto-container" *ngIf="navPath.length > 2">
      <ng-container *ngFor="let i of navPath; let ind = index">
        <ng-container *ngIf="ind > 2">
          <span class="goto-separator" *ngIf="ind !== 0">&gt;</span>
          <span class="goto-link" (click)="gotoLink(i)">{{i.name}}</span>
        </ng-container>
      </ng-container>
    </div>

    <div class="goto-container" *ngIf="navPath.length > 1 && childLinks.length > 0">
      <ng-container *ngFor="let i of childLinks; let ind = index">
        <span class="goto-separator" *ngIf="ind !== 0"></span>
        <span class="goto-link" (click)="gotoLink(i)">{{i.name}}</span>
      </ng-container>
    </div>

  </ng-container>

  <!-- Loading banner -->
  <shared-loading *ngIf="!page"></shared-loading>

  <!-- Page -->
  <div class="content" *ngIf="page">

    <ng-container [ngSwitch]="page.pageType">

      <cms-page-plain *ngSwitchCase="0" [page]="page"></cms-page-plain>
      <cms-mailing-list *ngSwitchCase="15" [page]="page"></cms-mailing-list>

    </ng-container>

  </div>

</ng-template>