import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { BaseModalComponent } from '@shared/base-components/base-modal.component';
import { NavLink } from '@core/services/nav.models';
import { TranslationService } from '@core/services/translation.service';
import { ITranslations, Translations } from '@core/types/translations.model';
import { CmsModifyModalService, TabId } from './modify-modal.service';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';

@Component({
  templateUrl: './modify-modal.component.html',
  styleUrls: ['./modify-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ModifyModalComponent extends BaseModalComponent implements OnInit {
  private dict: ITranslations;
  modalTitle: string;
  tabLinks: NavLink[];
  activeTabId: string;

  constructor(
    private translationService: TranslationService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private cdr: ChangeDetectorRef,
    private modifyModalService: CmsModifyModalService
  ) {
    super();
    this.dict = translationService.dict();
    this.activeTabId = this.modifyModalService.getActiveTabId();
  }

  ngOnInit(): void {
    this.tabLinks = this.getLinks();
  }

  private async clickTab(tab: NavLink) {
    if (await this.dirtyStateTrackerService.canDeactivate()) {
      this.modifyModalService.setActiveTabId(tab.id as TabId);
      this.activeTabId = tab.id!;
      this.tabLinks = this.getLinks();
      this.cdr.detectChanges();
    }
  }

  private getLinks() {
    const nameToTabId: { [key: string]: TabId } = {
      [this.dict.UI.CMS.CONFIG.TABS.GENERAL]: 'GENERAL',
      [this.dict.UI.CMS.CONFIG.TABS.ROUTES]: 'ROUTES',
      [this.dict.UI.CMS.CONFIG.TABS.STYLES]: 'STYLE',
    };
    let links: NavLink[] = [];
    for (const i in nameToTabId) {
      const link = {
        url: (p) => this.clickTab(p),
        name: i,
        id: nameToTabId[i],
        isActive: nameToTabId[i] === this.activeTabId,
      };
      links.push(link);
    }
    return links;
  }
}
