import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { CmsEditService } from '../../services/cms-edit.service';
import { SubscriptionBaseComponent } from '@shared/base-components/subscription-base/subscription-base.component';
import { TranslationService } from '@core/services/translation.service';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';

@Component({
  selector: 'cms-editing-toolbar',
  templateUrl: './editing-toolbar.component.html',
  styleUrls: ['./editing-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EditingToolbarComponent
  extends SubscriptionBaseComponent
  implements OnInit {
  isEditing = false;
  texts = this.getTexts();
  constructor(
    private editingService: CmsEditService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef,
    private dirtyStateTrackerService: DirtyStateTrackerService
  ) {
    super();
  }

  ngOnInit(): void {
    this.sub(this.editingService.isEditing$).subscribe((isEditing) => {
      this.isEditing = isEditing;
      this.cdr.detectChanges();
    });
  }

  getSave(stopEditing: boolean) {
    return () => this.editingService.save(stopEditing);
  }

  cancelEdit() {
    this.dirtyStateTrackerService.deactivateIfAllowed(() =>
      this.editingService.stopEditing()
    );
  }

  async startEdit() {
    this.dirtyStateTrackerService.deactivateIfAllowed(() =>
      this.editingService.startEditing()
    );
  }

  private getTexts() {
    const dict = this.translationService.dict();
    return {
      save: dict.COMMON_UI.KEY_WORD.SAVE,
      modify: dict.COMMON_UI.KEY_WORD.MODIFY,
      cancelEdit: dict.COMMON_UI.KEY_WORD.CANCEL,
    };
  }
}
