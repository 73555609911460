import { UpdateValueDTO, ValueCollectionDTO } from '../../gen/gen.dto';

export function objToUpdateValueList(
  obj: any,
  skip: string[] = []
): UpdateValueDTO[] {
  let values: UpdateValueDTO[] = [];
  let skipObj = skip.reduce((prev, cur) => {
    prev[cur] = true;
    return prev;
  }, {});
  for (let key in obj) {
    if (key in skipObj || obj[key] === null) {
      continue;
    }
    values.push({
      field: key,
      value: obj[key].toString(),
    });
  }
  return values;
}

export function objToValueCollection(obj: any): ValueCollectionDTO {
  return {
    id: obj.id?.toString() || 'undefined',
    values: objToUpdateValueList(obj, ['id']),
  };
}
