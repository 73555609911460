import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import {
  MediaMatcherService,
  MEDIA_TYPE,
} from '../services/media-matcher.service';
import { Subscription } from 'rxjs';

/**
 * Examples:
 *
 * Show xs: xs
 * Show xs-l: xs,m,l or xs-l or -l
 * Show m-xl: m,l,xl or m-xl or m-
 * Hide xs: hide.xs
 * Hide xs-l: hide.xs,m,l or hide.xs-l or hide.-l
 * Hide m-xl: hide.m,l,xl or hide.m-xl or hide.m-
 */

@Directive({
  selector: '[appMediaMatcher]',
})
export class MediaMatcherDirective implements OnDestroy {
  private currentType: MEDIA_TYPE;
  private matchingConditions: MEDIA_TYPE[] = [];

  private previousCondition = false;

  @Input() set appMediaMatcher(condition: string) {
    this.matchingConditions = [];
    if (condition.match(/^hide./)) {
      this.hide = true;
      condition = condition.substr(5);
    }
    if (condition.search(',') !== -1) {
      const conditions = condition.split(',');
      this.matchingConditions = conditions.map((c) => c as MEDIA_TYPE);
    } else if (condition.search('-') !== -1) {
      const conditions = condition.split('-');
      const from = MediaMatcherService.mediaToInd(
        (conditions[0] as MEDIA_TYPE) || 'xs'
      );
      const to = MediaMatcherService.mediaToInd(
        (conditions[1] as MEDIA_TYPE) || 'xxl'
      );
      for (let i = from; i <= to; i++) {
        this.matchingConditions.push(MediaMatcherService.indToMedia(i));
      }
    } else {
      this.matchingConditions = [condition as MEDIA_TYPE];
    }
    this.computeState();
  }

  // This is hide on true
  private hide: boolean = false;

  private mediaQuerySubscription: Subscription;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private mediaMatcherService: MediaMatcherService,
    private cdr: ChangeDetectorRef
  ) {
    this.mediaQuerySubscription = this.mediaMatcherService.mediaType$.subscribe(
      (type) => {
        this.currentType = type;
        this.computeState();
      }
    );
  }
  ngOnDestroy() {
    this.mediaQuerySubscription.unsubscribe();
  }

  private computeState() {
    if (this.matchingConditions.length === 0) {
      return;
    }
    let condition = this.matchingConditions.indexOf(this.currentType) !== -1;
    if (this.hide) {
      condition = !condition;
    }
    if (condition === this.previousCondition) {
      return;
    }
    if (condition) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.cdr.markForCheck();
    } else {
      this.viewContainer.clear();
    }
    this.previousCondition = condition;
  }
}
