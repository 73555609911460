import { Directive, Input, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { TranslationService } from '@core/services/translation.service';
import { TranslationKey } from '@core/types/translations.model';

@Directive({
  selector: '[translate]',
})
export class TranslateDirective implements OnDestroy {
  private key: TranslationKey | undefined;
  @Input() set translate(value: TranslationKey) {
    this.key = value;
    this.setContent();
  }
  private params: { [key: string]: string | number } = {};
  @Input()
  set par(value: { [key: string]: string | number }) {
    this.params = value;
    this.setContent();
  }

  private sub: Subscription;

  constructor(
    private elementRef: ElementRef<any>,
    private translationService: TranslationService
  ) {
    this.sub = translationService.localeReady$.subscribe((t) =>
      this.setContent(true)
    );
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private setContent(isReady: boolean = false) {
    if (
      !this.key ||
      (!isReady && !this.translationService.localeReady$.value)
    ) {
      return;
    }
    const value = this.translationService.fromString(this.key, this.params);
    this.elementRef.nativeElement.innerHTML = value;
  }
}
