import { Injectable } from '@angular/core';
import { SendMessageDTO, StatusDTO } from '@gen/gen.dto';
import { Observable } from 'rxjs';
import { MailingListApiService } from './mailing-list-api.service';

@Injectable({ providedIn: 'root' })
export class MailingListService {
  constructor(private api: MailingListApiService) {}

  subscribeToMailingList(pageId: string, email: string): Observable<StatusDTO> {
    return this.api.subscribeToMailingList(pageId, email);
  }

  sendToMailingList(
    pageId: string,
    message: SendMessageDTO
  ): Observable<StatusDTO> {
    return this.api.sendToMailingList(pageId, message);
  }

  getSubscribers(groupId: string, skip = 0, limit = 100) {
    return this.api.getSubscribers(groupId, skip, limit);
  }

  getMessages(groupId: string, skip = 0, limit = 100) {
    return this.api.getMessages(groupId, skip, limit);
  }

  removeSubscriber(subscriberId: string) {
    return this.api.removeSubscriber(subscriberId);
  }
}
