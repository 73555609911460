import { Injectable } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { EventTypeDTO } from '@gen/gen.dto';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class EventTypesFormUtils {
  constructor(
    private fb: FormBuilder,
    private translationService: TranslationService
  ) {}

  getAddFormConfig(
    dirtyStateTracker: string,
    saveCallback: () => Observable<any>
  ): FormEditorConfig {
    const form = this.fb.group({
      name: ['', Validators.required],
    });
    const config: EnrichableFormEditorConfig = {
      dirtyStateTracker,
      form,
      formFieldClassList: ['full-width'],
      classList: ['col-xs-12', 'col-sm-5'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.NAME,
          formControlName: 'name',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: saveCallback,
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getEditFormConfig(
    dirtyStateTracker: string,
    saveCallback: () => Observable<any>
  ): FormEditorConfig {
    const form = this.fb.group({
      name: ['', Validators.required],
      color: ['', Validators.required],
    });
    const config: EnrichableFormEditorConfig = {
      dirtyStateTracker,
      form,
      formFieldClassList: ['full-width'],
      classList: ['col-xs-12', 'col-sm-5'],
      fields: [
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.NAME,
          formControlName: 'name',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.COLOR,
          formControlName: 'color',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: saveCallback,
        },
      ],
    };

    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  getSelectConfig(lists: EventTypeDTO[], callback: () => void) {
    const form = this.fb.group({
      list: [''],
    });
    const config: EnrichableFormEditorConfig = {
      form: form,
      formFieldClassList: ['full-width'],
      fields: [
        [
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SELECT,
            formControlName: 'list',
            type: 'select',
            classList: ['col-xs-8', 'col-sm-4'],
            options: lists.map((i) => {
              return {
                value: i.id,
                label: i.name,
              };
            }),
          },
          {
            labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.MODIFY,
            type: 'button.plain',
            enableAlways: true,
            classList: ['col-xs-4', 'col-sm-4'],
            callback,
          },
        ],
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }
}
