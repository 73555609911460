import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { TranslationService } from '@core/services/translation.service';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { ToasterService } from '@shared/services/toaster.service';
import { tap } from 'rxjs/operators';
import { MailingListService } from '../mailing-list.service';

const DIRTY_KEY = 'CMS_SEND_MESSAGE_TO_MAILING_LIST';

@Component({
  selector: 'cms-mailing-list-send-message',
  templateUrl: './send-message.component.html',
  styleUrls: ['./send-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SendMessageComponent implements OnInit {
  config: FormEditorConfig;

  private form = this.fb.group({
    subject: ['', Validators.required],
    body: ['', Validators.required],
    isPubliclyVisible: [true],
  });
  previewing = false;

  constructor(
    private fb: FormBuilder,
    private configService: CmsConfigService,
    private mailingListService: MailingListService,
    private cdr: ChangeDetectorRef,
    private toasterService: ToasterService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.config = this.getConfig();
  }

  private getConfig() {
    this.resetForm();
    const config: EnrichableFormEditorConfig = {
      formFieldClassList: ['full-width'],
      dirtyStateTracker: DIRTY_KEY,
      form: this.form,
      fields: [
        {
          labelKey: TranslationService.KM.UI.CMS.MAILING_LIST.SUBJECT,
          formControlName: 'subject',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.MAILING_LIST.BODY,
          type: 'textarea',
          formControlName: 'body',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.MAILING_LIST.SHOW_PUBLICLY,
          formControlName: 'isPubliclyVisible',
          type: 'slideToggle',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.PREVIEW,
          type: 'button.plain',
          callback: () => (this.previewing = true),
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  private resetForm() {
    this.form.patchValue({
      subject: '',
      body: '',
      isPubliclyVisible: true,
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
  }

  // This is not from form-editor but from preview
  getSendCallback() {
    return () => {
      return this.mailingListService
        .sendToMailingList(this.configService.currentPageId, this.form.value)
        .pipe(
          tap((i) => {
            this.toasterService.showSuccessTranslationKey(
              TranslationService.KM.UI.CMS.MAILING_LIST.MESSAGE_SENT_OK
            );
            this.resetForm();
            this.previewing = false;
            this.cdr.detectChanges();
          })
        );
    };
  }
}
