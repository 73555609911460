import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CmsEditService } from '@cms/services/cms-edit.service';
import { NavLink } from '@core/services/nav.models';
import { TranslationService } from '@core/services/translation.service';
import { ITranslations } from '@core/types/translations.model';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';
import {
  CLASS_LIST,
  FORM_FIELD_CLASS_LIST,
} from '../plain-editor/plain-editor.component';
import { PlainComponent } from '../plain/plain.component';

type TabId = 'JOIN' | 'SEND_MESSAGE' | 'MEMBERS' | 'MESSAGES';

@Component({
  selector: 'cms-mailing-list',
  templateUrl: './mailing-list.component.html',
  styleUrls: [
    './mailing-list.component.scss',
    '../plain-editor/plain-editor.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MailingListComponent extends PlainComponent {
  tabLinks: NavLink[];
  activeTabId: string = 'JOIN';
  config: FormEditorConfig;

  private dict: ITranslations;

  constructor(
    editingService: CmsEditService,
    cdr: ChangeDetectorRef,
    private translationService: TranslationService,
    private dirtyStateTrackerService: DirtyStateTrackerService
  ) {
    super(editingService, cdr);
  }

  async ngOnInit() {
    super.ngOnInit();
    this.dict = await this.translationService.dictViaPromise();
    this.tabLinks = this.getLinks();
    //
    LifeCyclesUtil.sub(
      this,
      this.editingService.editorForm$,
      (form: FormGroup) => {
        this.form = form;
        this.config = this.getConfig();
      }
    );
  }

  private getConfig() {
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: FORM_FIELD_CLASS_LIST,
      classList: CLASS_LIST,
      fields: [
        {
          labelKey:
            TranslationService.KM.UI.CMS.MAILING_LIST.SUBSCRIPTION_MESSAGE,
          formControl: (this.form.controls.content as FormGroup).controls
            .subscribeMessage,
          type: 'textarea',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.MAILING_LIST.MESSAGE_FOOTER,
          formControl: (this.form.controls.content as FormGroup).controls
            .messageFooter,
          type: 'textarea',
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  private getLinks() {
    const nameToTabId: { [key: string]: TabId } = {
      [this.dict.COMMON_UI.KEY_WORD.JOIN]: 'JOIN',
      [this.dict.UI.CMS.MAILING_LIST.SEND_MESSAGE]: 'SEND_MESSAGE',
      [this.dict.UI.CMS.MAILING_LIST.SENT_MESSAGES]: 'MESSAGES',
      [this.dict.UI.CMS.MAILING_LIST.SUBSCRIBERS]: 'MEMBERS',
    };
    let links: NavLink[] = [];
    for (const i in nameToTabId) {
      const link = {
        url: (p) => this.clickTab(p),
        name: i,
        id: nameToTabId[i],
        isActive: nameToTabId[i] === this.activeTabId,
      };
      links.push(link);
    }
    return links;
  }

  private clickTab(tab: NavLink) {
    this.dirtyStateTrackerService.deactivateIfAllowed(() => {
      this.activeTabId = tab.id!;
      this.tabLinks = this.getLinks();
      this.cdr.detectChanges();
    });
  }
}
