<ng-container *ngFor="let f of features">
  <h2>{{f.name}}</h2>

  {{f.description}}
  <br>
  <shared-loading-button [cb]="getToggleFeature(f)" [text]="f.isEnabled ? deactivate : activate"
    [cls]="f.isEnabled ? 'green' : 'red'">

  </shared-loading-button>

</ng-container>