import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PlainModule } from './plain/plain.module';
import { MailingListModule } from './mailing-list/mailing-list.module';
import { PlainEditorModule } from './plain-editor/plain-editor.module';

@NgModule({
  declarations: [],
  imports: [CommonModule, PlainModule, PlainEditorModule, MailingListModule],
})
export class PagesModule {}
