import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, ActivationEnd, Router } from '@angular/router';
import { DatService } from '@core/services/dat.service';
import { NavLink, ROUTES } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import {
  CmsConfigDTO,
  CssVarDTO,
  LinkDTO,
  PortalConfigDTO,
} from '@gen/gen.dto';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CmsApiService } from './cms-api.service';
import { CmsFlattenedLink, CmsRecursiveLink } from './cms.models';

const CSS_CONTAINER_ID = 'cms-vars';

@Injectable({ providedIn: 'root' })
export class CmsConfigService {
  // string is the current slug
  configReady$ = new BehaviorSubject<string | boolean>(false);
  currentPath$ = new BehaviorSubject<CmsFlattenedLink | undefined | false>(
    undefined
  );
  private currentSlug: string | undefined;
  private hasSlugInPath = true; // False when own (sub)domain
  private config: CmsConfigDTO;
  // This is always current
  private snapshot: ActivatedRouteSnapshot | undefined;
  // Generated link structures
  flattenedLinks: CmsFlattenedLink[] = [];
  // These are references to flattened links that we use to mapping path to link
  // and determine isActive recursively
  private linkPathMap: { [path: string]: number } = {};
  private linkIdMap: { [id: string]: number } = {};

  get groupId(): string {
    return this.config.groupId;
  }

  get currentParent(): LinkDTO | undefined {
    return undefined;
  }

  get currentPageId(): string {
    const value = this.currentPath$.value;
    if (!value) {
      return '';
    }
    return value.id;
  }

  constructor(
    private router: Router,
    private navService: NavService,
    private api: CmsApiService,
    private datService: DatService
  ) {
    this.router.events.subscribe((event) => {
      if (event instanceof ActivationEnd) {
        if (!event.snapshot.data.isCms) {
          this.clearConfig();
          return;
        }
        this.snapshot = event.snapshot;
        const slug = this.getSlug(event.snapshot);
        if (!slug) {
          this.navService.goto(ROUTES.PUB.HOME);
          this.clearConfig();
        } else if (slug !== this.currentSlug) {
          this.initConfig(slug).subscribe();
        } else {
          // We already have the config, so we need to set active link
          // because navigation occurred
          this.setLinksToNavServiceAndComputeActive();
        }
      }
    });
  }

  setCustomColors(cssVars: CssVarDTO[]) {
    const el = this.getCssVarStyleContainer();
    let str = ':root {';
    cssVars.forEach((cssvar) => {
      str += cssvar.name + ': ' + cssvar.value + ';\n';
    });
    el.innerHTML = str + '}';
  }

  resetCustomColors() {
    if (!this.datService.isGroupRestriction) {
      const el = this.getCssVarStyleContainer();
      el.innerHTML = '';
    }
  }

  getCssVarStyleContainer(): HTMLElement {
    return document.getElementById(CSS_CONTAINER_ID);
  }

  getConfig() {
    return this.config;
  }

  getSecondLevelNav(): NavLink[] {
    const navPath = this.getNavPath();
    const inPathIds: { [key: string]: true } = {};
    navPath.forEach((i) => (inPathIds[i.id] = true));
    if (navPath.length === 0) {
      return [];
    }
    return navPath[0].children.map((link) =>
      this.recursiveLinkToNavLink(link, link.id in inPathIds)
    );
  }

  private recursiveLinkToNavLink(
    link: CmsRecursiveLink,
    isActive = false
  ): NavLink {
    const flattenedLink = this.flattenedLinks[this.linkIdMap[link.id]];
    return this.flattenedLinkToNavLink(flattenedLink, isActive);
  }

  private flattenedLinkToNavLink(
    link: CmsFlattenedLink,
    isActive = false
  ): NavLink {
    return {
      url: (this.hasSlugInPath ? this.currentSlug + '/' : '') + '/' + link.path,
      name: link.title,
      isActive: isActive,
      icon: link.icon || undefined,
    };
  }

  getChildLinks(onlyIfAboveFirst = true): NavLink[] {
    const path = this.getNavPath();
    if (path.length < 2 && onlyIfAboveFirst) {
      return [];
    }
    return path[path.length - 1].children.map((i) =>
      this.recursiveLinkToNavLink(i)
    );
  }

  // level stars from zero
  getSiblingLinks(level = 2): NavLink[] {
    const path = this.getNavPath();
    if (path.length < level + 1) {
      return [];
    }
    const current = path[level];
    return this.getSiblings(current).map((i) =>
      this.flattenedLinkToNavLink(i, current.id === i.id)
    );
  }

  getNavPath(): CmsFlattenedLink[] {
    let current = this.currentPath$.value;
    if (!current) {
      return [];
    }
    let links = [current];
    while (current.parentId !== this.config.groupId) {
      current = this.flattenedLinks[this.linkIdMap[current.parentId]];
      links.push(current);
    }
    return links.reverse();
  }

  updateConfig(config: PortalConfigDTO) {
    return this.api
      .updateConfig(this.groupId, config)
      .pipe(tap(() => this.refreshConfig()));
  }

  getNavPathAsNavLinks(): NavLink[] {
    return this.getNavPath().map((i) => this.flattenedLinkToNavLink(i, true));
  }

  getCurrentLevel(): CmsFlattenedLink[] {
    let current = this.currentPath$.value;
    return current ? this.getSiblings(current) : [];
  }

  getSiblings(link: CmsFlattenedLink): CmsFlattenedLink[] {
    return this.flattenedLinks.filter((i) => i.parentId === link.parentId);
  }

  getChildren(link: CmsFlattenedLink): CmsFlattenedLink[] {
    return this.flattenedLinks.filter((i) => i.parentId === link.id);
  }

  getRoots(): CmsFlattenedLink[] {
    return this.flattenedLinks.filter((i) => this.isRootLink(i));
  }

  isRootLink(link: CmsFlattenedLink) {
    return link.parentId === this.config.groupId;
  }

  getParentPath(link: CmsFlattenedLink) {
    return (
      '/' +
      this.currentSlug +
      (this.isRootLink(link)
        ? ''
        : this.flattenedLinks[this.linkIdMap[link.parentId]].path)
    );
  }

  getParent(link: CmsFlattenedLink): CmsFlattenedLink | undefined {
    return this.isRootLink(link)
      ? undefined
      : this.flattenedLinks[this.linkIdMap[link.parentId]];
  }

  refreshConfig(): Observable<CmsConfigDTO> {
    // Can't refresh if there is no slug saved, slug MUST be determined inside this
    if (!this.currentSlug) {
      this.clearConfig();
      return of(undefined);
    }
    return this.initConfig(this.currentSlug);
  }

  // Solves slug from path OR in case of accessing via (sub)domain gives that then
  // Note that slug cna as well be then domain name!
  // TODO: Modify this when (sub)domain support is there, note hasSlugInPath property
  private getSlug(snapshot: ActivatedRouteSnapshot) {
    const host = location.host;
    if (host !== 'localhost:4200' && host !== 'heimos.mathcodingclub.com') {
      this.hasSlugInPath = false;
      return location.host.match('.([a-z0-9-]*).[a-z]*$')[1];
    }

    if (snapshot.url.length > 0) {
      this.hasSlugInPath = true;
      return snapshot.url[0].path;
    }
    return undefined;
  }

  private clearConfig() {
    if (this.currentSlug === undefined) {
      return;
    }
    this.snapshot = undefined;
    this.currentSlug = undefined;
    this.navService.clearSecondNav('CMS');
    this.resetCustomColors();
    this.currentPath$.next(undefined);
    this.configReady$.next(false);
  }

  private initConfig(slug: string): Observable<CmsConfigDTO> {
    return this.api.getConfig(slug).pipe(
      tap((config) => {
        this.config = config;
        this.setCustomColors(this.config.cssVars);
        this.currentSlug = slug;
        this.createLinkStructures();
        this.setLinksToNavServiceAndComputeActive();
        this.configReady$.next(slug);
      })
    );
  }

  // Transforms links to better structures
  private createLinkStructures() {
    if (this.config.links.length === 0) {
      return;
    }
    // First we need parentId maps
    const parentIdCollection: { [parentId: string]: CmsRecursiveLink[] } = {};
    this.config.links.forEach((i) => {
      if (!(i.parentId in parentIdCollection)) {
        parentIdCollection[i.parentId] = [];
      }
      parentIdCollection[i.parentId].push(
        Object.assign({ children: [] }, i) as CmsRecursiveLink
      );
    });
    // Then, recursive links to create correct paths fo each
    const recursiveLinks = this.createRecursiveLinks(
      parentIdCollection[this.config.groupId],
      parentIdCollection
    );
    // Then, flattened links
    this.flattenedLinks = this.getFlattenedLinks('', recursiveLinks);
    this.linkIdMap = {};
    this.linkPathMap = {};
    // This global sort is all that matters to look for child links etc
    this.flattenedLinks.sort((a, b) => a.sortOrder - b.sortOrder);
    this.flattenedLinks.forEach((i, ind) => {
      this.linkIdMap[i.id] = ind;
      this.linkPathMap[i.path] = ind;
    });
    // See if we need to set default path IF page is not found
    const currentPath = this.getPathInCms();
    if (currentPath === '') {
      const firstPath = this.getFirstPage().path;
      this.navService.goto(
        (this.hasSlugInPath ? this.currentSlug + '/' : '') + firstPath
      );
    }
  }

  private createRecursiveLinks(
    links: CmsRecursiveLink[],
    parentCollection: { [parentId: string]: CmsRecursiveLink[] }
  ) {
    links.forEach((i) => {
      i.children = i.id in parentCollection ? parentCollection[i.id] : [];
      this.createRecursiveLinks(i.children, parentCollection);
    });
    links.sort((a, b) => a.sortOrder - b.sortOrder);
    return links;
  }

  private getFlattenedLinks(
    path: string,
    links: CmsRecursiveLink[]
  ): CmsFlattenedLink[] {
    let response: CmsFlattenedLink[] = [];
    links.forEach((i) => {
      const newPath = path + '/' + i.slug;
      const link = Object.assign({ path: newPath }, i);
      response.push(link);
      if (i.children.length > 0) {
        response = [
          ...response,
          ...this.getFlattenedLinks(newPath, i.children),
        ];
      }
    });
    response.sort((a, b) => a.sortOrder - b.sortOrder);
    return response;
  }

  private getFirstPage() {
    const mainMenu = this.getMainMenu();
    return mainMenu[0];
  }

  private getMainMenu() {
    return this.flattenedLinks.filter(
      (i) => i.parentId === this.config.groupId
    );
  }

  private setLinksToNavServiceAndComputeActive() {
    if (this.flattenedLinks.length === 0) {
      this.navService.setSecondNav('CMS', []);
      return;
    }
    // If there are sub routes, this must be changed
    const ind = this.linkPathMap[this.getPathInCms()];
    if (!(ind in this.flattenedLinks)) {
      // Page not found
      this.currentPath$.next(false);
      this.navService.setSecondNav('CMS', []);
      return;
    }
    this.flattenedLinks.forEach((i) => (i.isActive = false));
    let linkItem = this.flattenedLinks[ind];
    this.currentPath$.next(linkItem);
    linkItem = this.getActiveLinkItemFor(linkItem.id);
    linkItem.isActive = true;
    while (linkItem.parentId !== this.config.groupId) {
      linkItem = this.getActiveLinkItemFor(linkItem.parentId);
      linkItem.isActive = true;
    }
    this.navService.setSecondNav('CMS', this.getMainMenuAsNavLinks());
  }

  private getMainMenuAsNavLinks(): NavLink[] {
    return this.getMainMenu().map((i) => {
      return {
        url: (this.hasSlugInPath ? this.currentSlug + '/' : '') + i.path,
        name: i.title,
        isActive: i.isActive,
        icon: i.icon || undefined,
      };
    });
  }

  private getActiveLinkItemFor(id) {
    let linkItem = this.flattenedLinks[this.linkIdMap[id]];
    if (linkItem.highlightMenuId) {
      return this.getActiveLinkItemFor(linkItem.highlightMenuId);
    }
    return linkItem;
  }

  private getPathInCms() {
    let path: string[] = [];
    for (let i = 0; i < this.snapshot.url.length; i++) {
      if (this.snapshot.url[i].path === this.currentSlug && path.length === 0) {
        continue;
      }
      path.push(this.snapshot.url[i].path);
    }
    if (path.length === 0) {
      return '';
    }
    return '/' + path.join('/');
  }
}
