import {
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  OnInit,
} from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'shared-accordion-toggle-buttons',
  templateUrl: './accordion-toggle-buttons.component.html',
  styleUrls: ['./accordion-toggle-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccordionToggleButtonsComponent implements OnInit {
  @ContentChild(MatAccordion) accordion: MatAccordion;
  constructor() {}

  ngOnInit(): void {}
}
