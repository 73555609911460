import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { AccordionToggleButtonsComponent } from './accordion-toggle-buttons.component';

@NgModule({
  declarations: [AccordionToggleButtonsComponent],
  imports: [CommonModule, MatButtonModule, SharedDirectivesModule],
  exports: [AccordionToggleButtonsComponent],
})
export class AccordionToggleButtonsModule {}
