import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ContainerModule } from './container/container.module';
import { EventsModule } from './events/events.module';
import { FeaturesModule } from './features/features.module';
import { GeneralModule } from './general/general.module';
import { LookupsModule } from './lookups/lookups.module';
import { TabNavModule } from './tab-nav/tab-nav.module';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ContainerModule,
    GeneralModule,
    EventsModule,
    TabNavModule,
    FeaturesModule,
    LookupsModule,
  ],
})
export class SettingsModule {}
