import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatMenuModule } from '@angular/material/menu';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClientModule } from '@angular/common/http';

import { UserModule } from './user/user.module';

import { LandingModule } from './landing/landing.module';

import { SharedComponentsModule } from '@shared/components/shared-components.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';

import { CoreComponentsModule } from '@core/components/core-components.module';
import {
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { LeftSideNavModule } from '@core/components/left-side-nav/left-side-nav.module';
import { RightSideNavModule } from '@core/components/right-side-nav/right-side-nav.module';

import { GroupModule } from '@group/group.module';
import { NavSecondModule } from '@shared/components/nav-second/nav-second.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { GroupSelectorModule } from '@core/components/group-selector/group-selector.module';
import { RouteReuseStrategy } from '@angular/router';
import { CustomRouteReuseStrategy } from '@core/routing/route-reuse-strategy';
import { CmsComponentsModule } from '@cms/components/cms-components.module';

const FORM_FIELD_DEFAULTS: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatToolbarModule,
    MatSidenavModule,
    MatIconModule,
    MatSnackBarModule,
    MatMenuModule,
    HttpClientModule,
    UserModule,
    LandingModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    CoreComponentsModule,
    LeftSideNavModule,
    RightSideNavModule,
    GroupModule,
    NavSecondModule,
    ClickOutsideModule,
    GroupSelectorModule,
    CmsComponentsModule,
  ],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: FORM_FIELD_DEFAULTS,
    },
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
