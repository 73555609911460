import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainComponentModule } from './components/main/main.component.module';

@NgModule({
  imports: [CommonModule, MainComponentModule],
})
export class LandingModule {
  constructor() {}
}
