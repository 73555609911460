import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { NavLink } from '@core/services/nav.models';
import { NavService } from '@core/services/nav.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { SubscriptionBaseComponent } from '../../base-components/subscription-base/subscription-base.component';

const RESIZE_DEBOUNCE = 100;
const SHOW_MORE_WIDTH = 32;

@Directive({
  selector: '[linkElement]',
})
export class LinkElement {
  constructor() {}
}

@Component({
  selector: 'app-nav-second',
  templateUrl: './nav-second.component.html',
  styleUrls: ['./nav-second.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavSecondComponent
  extends SubscriptionBaseComponent
  implements OnInit {
  displayLinks: NavLink[];
  @Input() externalActive = false;
  @Input() allowPartialFit = true;
  @Input() set links(value: NavLink[]) {
    if (this.didLinksChange(value) || this.externalActive) {
      this.displayLinks = value;
      this.setActive();
    }
  }
  doesAnyOverflow = false;
  isMoreVisible = false;
  private $resetWidth = new Subject<void>();
  private resizeObserver: ResizeObserver;
  private activeUrl: string;
  private toggling = false;

  @ViewChildren(LinkElement, { read: ElementRef })
  linkElems: QueryList<ElementRef>;
  @ViewChild('navContainer', { static: true }) navContainer: ElementRef;

  constructor(
    private elementRef: ElementRef,
    private navService: NavService,
    private cdr: ChangeDetectorRef,
    private ngZone: NgZone
  ) {
    super();
    this.sub(this.navService.activePath$).subscribe((path) => {
      this.activeUrl = path;
      this.setActive();
    });
  }

  ngOnInit(): void {
    this.sub(
      this.$resetWidth.pipe(debounceTime(RESIZE_DEBOUNCE))
    ).subscribe(() => this.setHidden());
    this.setActive();
  }

  ngAfterViewInit(): void {
    this.sub(this.linkElems.changes).subscribe(() => {
      this.resetObservers();
      this.$resetWidth.next();
    });
    this.resetObservers();
    this.$resetWidth.next();
  }

  ngOnDestroy(): void {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  }

  getClasses(link: NavLink) {
    const cls = ['link'];
    if (link.isActive) {
      cls.push('link--active');
    }
    if ('cls' in link) {
      cls.push(link.cls);
    }
    return cls;
  }

  setActive() {
    if (!this.externalActive) {
      this.displayLinks?.forEach((i) => {
        i.isActive = i.activeUrl
          ? i.activeUrl === this.activeUrl.substr(0, i.activeUrl.length)
          : i.url === this.activeUrl.substr(0, i.url.length);
      });
    }
    this.cdr.markForCheck();
  }

  // need to subscrive to all span elements as they might change
  private resetObservers() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
    this.resizeObserver = new ResizeObserver((entries) => {
      this.$resetWidth.next();
    });
    if (this.linkElems) {
      this.linkElems.forEach((i) => {
        this.resizeObserver.observe(i.nativeElement);
      });
    }
    if (this.navContainer) {
      this.resizeObserver.observe(this.navContainer.nativeElement);
    }
  }

  // Compute which link elements don't fit into viewport
  private setHidden() {
    if (!this.elementRef) {
      return;
    }
    let widthReduced = false;
    this.doesAnyOverflow = false;
    const elems = this.linkElems.map((i) => i.nativeElement);
    let width =
      window.innerWidth - (this.allowPartialFit ? 0 : SHOW_MORE_WIDTH);
    elems.forEach((e, index) => {
      const rect = e.getBoundingClientRect();
      let doesOverflow = rect.x + rect.width > width;
      let doesOverflowWithShowMore =
        rect.x + rect.width > width - SHOW_MORE_WIDTH;
      if (
        this.allowPartialFit &&
        !widthReduced &&
        index !== elems.length - 1 &&
        doesOverflowWithShowMore
      ) {
        width = width - SHOW_MORE_WIDTH;
        doesOverflow = true;
        widthReduced = true;
      }
      this.displayLinks[index].doesOverflow = doesOverflow;
      if (this.displayLinks[index].doesOverflow) {
        this.doesAnyOverflow = true;
      }
    });
    this.cdr.markForCheck();
    this.cdr.detectChanges();
  }

  open(link: NavLink, newWindow?: boolean) {
    if (typeof link.url !== 'string') {
      link.url(link);
      return;
    }
    if (newWindow) {
      const url = link.url.match(/:\/\//)
        ? link.url
        : this.navService.getLink(link);
      window.open(url);
      return;
    }
    if (link.url.match(/:\/\//)) {
      window.location.href = link.url;
    } else {
      this.navService.goto(link.url);
    }
  }

  closeMore() {
    if (this.toggling) {
      return;
    }
    this.isMoreVisible = false;
    this.cdr.detectChanges();
  }

  toggleMore() {
    this.toggling = true;
    setTimeout(() => {
      this.isMoreVisible = !this.isMoreVisible;
      this.toggling = false;
      this.cdr.detectChanges();
    });
  }

  openShowMore(link: NavLink, newWindow?: boolean) {
    // I don't fully understand why this is needed
    this.ngZone.run(() => {
      this.open(link, newWindow);
    });
    setTimeout(() => {
      this.closeMore();
    });
  }

  private didLinksChange(links: NavLink[]) {
    if (
      !this.displayLinks ||
      !links ||
      this.displayLinks.length !== links.length
    ) {
      return true;
    }
    for (let i = 0; i < links.length; i++) {
      if (
        links[i].name !== this.displayLinks[i].name ||
        links[i].url !== this.displayLinks[i].url
      ) {
        return true;
      }
    }
    return false;
  }
}
