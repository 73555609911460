import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/services/api-v1.models';
import {
  LookupListDTO,
  LookupListItemDTO,
  LookupListItemsDTO,
  LookupListItemSortOrderDTO,
  LookupListsDTO,
  StatusDTO,
} from '@gen/gen.dto';
import { OngoingGetService } from '@shared/services/ongoing-get.service';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GroupLookupsApiService {
  constructor(private http: HttpClient, private ongoing: OngoingGetService) {}

  getLookups(groupId: string): Observable<LookupListsDTO> {
    return this.ongoing.getOrAdd<LookupListsDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.LISTS, { groupId })
    );
  }

  getItems(listId: string): Observable<LookupListItemsDTO> {
    return this.ongoing.getOrAdd<LookupListItemsDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.LIST, { listId })
    );
  }

  addList(groupId: string, list: LookupListDTO) {
    return this.http.post<LookupListDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.LISTS, { groupId }),
      list
    );
  }

  updateList(list: LookupListDTO) {
    return this.http.put<LookupListDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.LIST, { listId: list.id }),
      list
    );
  }

  removeList(listId: string) {
    return this.http.delete<StatusDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.LIST, { listId })
    );
  }

  copyNonExistingValues(fromListId: string, toListId: string) {
    return this.http.get<LookupListItemsDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.COPY_NON_EXISTING, {
        fromListId,
        toListId,
      })
    );
  }

  addItems(listId: string, items: LookupListItemDTO[]) {
    return this.http.post<LookupListItemsDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.ITEMS, {
        listId,
      }),
      { items }
    );
  }

  updateItems(listId: string, items: LookupListItemDTO[]) {
    return this.http.put<LookupListItemsDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.ITEMS, {
        listId,
      }),
      { items }
    );
  }

  removeItem(itemId: string) {
    return this.http.delete<StatusDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.ITEM, {
        itemId,
      })
    );
  }

  sortItems(listId: string, items: LookupListItemSortOrderDTO[]) {
    return this.http.put<LookupListItemsDTO>(
      subsToUrl(API.GROUP.LOOKUP_LIST.SORT_ITEMS, {
        listId,
      }),
      { items }
    );
  }
}
