import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle,
} from '@angular/router';
import { CurrentGroupService } from '@shared/services/current-group.service';

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private storedRoutes = new Map<string, DetachedRouteHandle>();

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {}

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    return false;
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle {
    return null;
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    const c =
      curr.children && curr.children.length > 0 ? curr.children[0] : curr;
    const f =
      future.children && future.children.length > 0
        ? future.children[0]
        : future;
    return !!(
      f &&
      c &&
      f.data &&
      c.data &&
      c.data.routeReUse &&
      c.data.routeReUse === f.data.routeReUse
    );
  }
}
