import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import { ToasterService } from '@shared/services/toaster.service';
import { NewGroupService } from './new-group.service';
import { tap } from 'rxjs/operators';
import { NavService } from '@core/services/nav.service';

@Component({
  templateUrl: './new-group.component.html',
  styleUrls: ['./new-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewGroupComponent {
  form = this.fb.group({
    name: ['', Validators.required],
  });

  dict = this.ts.dict();

  get controls() {
    return this.form.controls;
  }

  constructor(
    private fb: FormBuilder,
    private ts: TranslationService,
    private toasterService: ToasterService,
    private service: NewGroupService,
    private nav: NavService
  ) {}

  createCallback() {
    return () =>
      this.service.createGroup(this.form.controls.name.value).pipe(
        tap(() => {
          this.toasterService.showSuccess(this.dict.COMMON_UI.OK.SAVED);
        })
      );
  }
}
