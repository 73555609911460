import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class OngoingGetService {
  private ongoing: { [url: string]: Observable<any> } = {};

  constructor(private http: HttpClient) {}

  getOrAdd<T>(url: string) {
    const existing = this.get(url);
    return existing ? existing : this.add(url, this.http.get<T>(url));
  }

  get<T>(url: string): undefined | Observable<T> {
    return this.ongoing[url];
  }

  add(url: string, request: Observable<any>) {
    if (url in this.ongoing) {
      throw new Error('Already executing this request.');
    }
    this.ongoing[url] = request;
    return request.pipe(
      tap(
        () => this.remove(url),
        () => this.remove(url)
      )
    );
  }

  remove(url: string) {
    delete this.ongoing[url];
  }
}
