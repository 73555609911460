import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { TranslationService } from '@core/services/translation.service';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';

export const FORM_FIELD_CLASS_LIST = ['full-width'];
export const CLASS_LIST = ['col-xs-12', 'col-md-8'];

@Component({
  selector: 'cms-pages-plain-editor',
  template: '<shared-form-editor [config]="config"></shared-form-editor>',
  styleUrls: ['./plain-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlainEditorComponent implements OnInit {
  config: FormEditorConfig;

  @Input() form: FormGroup;

  constructor(private translationService: TranslationService) {}

  ngOnInit(): void {
    this.config = this.getConfig();
  }

  getConfig() {
    const config: EnrichableFormEditorConfig = {
      form: this.form,
      formFieldClassList: FORM_FIELD_CLASS_LIST,
      classList: CLASS_LIST,
      fields: [
        {
          labelKey: TranslationService.KM.UI.CMS.EDIT.META_DESCRIPTION,
          formControlName: 'metaDescription',
          type: 'textarea',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.EDIT.META_KEYWORDS,
          formControlName: 'metaKeywords',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.EDIT.HTML_CONTENT,
          formControl: (this.form.controls.content as FormGroup).controls.html,
          type: 'textarea',
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }
}
