import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SampleModalComponent } from './sample-modal.component';

@NgModule({
  declarations: [SampleModalComponent],
  imports: [CommonModule],
  entryComponents: [SampleModalComponent],
})
export class SampleModalModule {}
