import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslationService } from '@core/services/translation.service';
import { TranslationKey } from '@core/types/translations.model';

@Injectable({
  providedIn: 'root',
})
export class ToasterService {
  constructor(
    private snackBar: MatSnackBar,
    private translationService: TranslationService
  ) {}

  showSuccess(message: string) {
    this.snackBar.open(message, 'OK!', {
      duration: 2000,
    });
  }

  showSuccessTranslationKey(
    key: TranslationKey,
    params?: { [key: string]: string | number }
  ) {
    const message = this.translationService.fromString(key, params);
    this.showSuccess(message);
  }

  showError(message: string) {
    this.snackBar.open(message, 'Error!', {
      duration: 2000,
    });
  }

  showErrorTranslationKey(
    key: TranslationKey,
    params?: { [key: string]: string | number }
  ) {
    const message = this.translationService.fromString(key, params);
    this.showError(message);
  }
}
