import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayComponent } from './overlay.component';
import { SharedDirectivesModule } from '../../directives/shared-directives.module';
import { NotImplementedModule } from '../not-implemented/not-implemented.module';

@NgModule({
  declarations: [OverlayComponent],
  imports: [CommonModule, SharedDirectivesModule, NotImplementedModule],
  entryComponents: [OverlayComponent],
})
export class OverlayModule {}
