import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingButtonComponent } from './loading-button.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [LoadingButtonComponent],
  imports: [CommonModule, MatButtonModule, MatIconModule],
  exports: [LoadingButtonComponent],
})
export class LoadingButtonModule {}
