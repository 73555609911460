<mat-card>

  <mat-card-header>
    <mat-card-title>Luo uusi ryhmä</mat-card-title>
  </mat-card-header>

  <mat-card-content>
    <form [formGroup]="form">

      <mat-form-field class="full-width">
        <mat-label>{{ dict.COMMON_UI.KEY_WORD.NAME }}</mat-label>
        <input matInput autocomplete="off" formControlName="name" required>
      </mat-form-field>

      <shared-loading-button [disabled]="form.status != 'VALID'" [cb]="createCallback()"></shared-loading-button>

    </form>

  </mat-card-content>

</mat-card>