import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NewGroupComponent } from './new-group.component';
import { MatCardModule } from '@angular/material/card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';

@NgModule({
  declarations: [NewGroupComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatFormFieldModule,
    MatCardModule,
    LoadingButtonModule,
  ],
})
export class NewGroupModule {}
