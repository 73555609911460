import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { FormEditorModule } from '@shared/components/form-editor/form-editor.module';
import { LoadingButtonModule } from '@shared/components/loading-button/loading-button.module';
import { NavSecondModule } from '@shared/components/nav-second/nav-second.module';
import { OrderEditorModule } from '@shared/components/order-editor/order-editor.module';
import { SharedDirectivesModule } from '@shared/directives/shared-directives.module';
import { ColorPickerModule } from 'ngx-color-picker';
import { GeneralComponent } from './general/general.component';
import { ModifyModalComponent } from './modify-modal.component';
import { PageFormComponent } from './page-form/page-form.component';
import { RoutesComponent } from './routes/routes.component';
import { StyleComponent } from './style/style.component';

@NgModule({
  declarations: [
    ModifyModalComponent,
    RoutesComponent,
    GeneralComponent,
    StyleComponent,
    PageFormComponent,
  ],
  imports: [
    CommonModule,
    NavSecondModule,
    LoadingButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatSelectModule,
    MatSlideToggleModule,
    ColorPickerModule,
    SharedDirectivesModule,
    OrderEditorModule,
    FormEditorModule,
  ],
  entryComponents: [ModifyModalComponent],
})
export class ModifyModalModule {}
