import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { TranslationService } from '@core/services/translation.service';
import {
  EnrichableFormEditorConfig,
  FormEditorConfig,
} from '@shared/components/form-editor/form-editor.model';
import { enrichConfigToDefaults } from '@shared/components/form-editor/form-editor.util';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';

const DIRTY_KEY = 'CMS_CONFIG_GENERAL_COMPONENT';

@Component({
  selector: 'cms-modify-general',
  template: '<shared-form-editor [config]="config"></shared-form-editor>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class GeneralComponent implements OnInit {
  config: FormEditorConfig;

  private form = this.fb.group({
    name: ['', Validators.required],
    notFoundContent: [''],
    mainBanner: [''],
    mainBannerType: [''],
  });

  constructor(
    private fb: FormBuilder,
    private cmsConfigService: CmsConfigService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private translationService: TranslationService
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.cmsConfigService.getConfig().config);
    this.config = this.getConfig();
  }

  private getConfig() {
    const config: EnrichableFormEditorConfig = {
      dirtyStateTracker: DIRTY_KEY,
      form: this.form,
      formFieldClassList: ['full-width'],
      classList: ['col-xs-12', 'col-sm-5'],
      fields: [
        {
          labelKey: TranslationService.KM.UI.CMS.CONFIG.GENERAL.WEB_PORTAL_NAME,
          formControlName: 'name',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.CONFIG.GENERAL.BANNER_IMAGE,
          formControlName: 'mainBanner',
        },
        {
          labelKey: TranslationService.KM.UI.CMS.CONFIG.ROUTES.PAGE_TYPE,
          formControlName: 'mainBannerType',
          type: 'select',
          options: [
            {
              value: 'FILL',
              labelKey:
                TranslationService.KM.UI.CMS.CONFIG.GENERAL
                  .BANNER_IMAGE_STYLE_FILL,
            },
            {
              value: 'HEIGHT',
              labelKey:
                TranslationService.KM.UI.CMS.CONFIG.GENERAL
                  .BANNER_IMAGE_STYLE_HEIGHT,
            },
          ],
        },
        {
          labelKey: TranslationService.KM.UI.CMS.CONFIG.GENERAL.PAGE_NOT_FOUND,
          formControlName: 'notFoundContent',
          type: 'textarea',
        },
        {
          labelKey: TranslationService.KM.COMMON_UI.KEY_WORD.SAVE,
          type: 'button.loading',
          callback: this.getSaveCallback(),
        },
      ],
    };
    return enrichConfigToDefaults(config, { tx: this.translationService });
  }

  private getSaveCallback() {
    return () => {
      const data = Object.assign({}, this.form.value);
      this.form.markAsUntouched();
      this.form.markAsPristine();
      this.dirtyStateTrackerService.removeDirty(DIRTY_KEY);
      return this.cmsConfigService.updateConfig(data);
    };
  }
}
