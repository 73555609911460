import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';

@Component({
  selector: 'shared-order-editor',
  templateUrl: './order-editor.component.html',
  styleUrls: ['./order-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderEditorComponent implements OnInit {
  form = new FormControl();
  disabled = false;
  private total_: number;
  private current_: number;
  @Input() set total(value: number) {
    this.total_ = value;
    this.cdr.detectChanges();
  }
  @Input() set current(value: number) {
    this.current_ = value;
    this.form.patchValue(value);
  }
  @Output() onNewPosition = new EventEmitter<number>();

  get isLast() {
    return this.current_ === this.total_;
  }

  get isFirst() {
    return this.current_ === 1;
  }

  constructor(
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.dirtyStateTrackerService.isSomethingDirty$,
      (is: boolean) => (this.disabled = is)
    );
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  pos(ind) {
    if (ind < -1) {
      this.onNewPosition.emit(1);
    } else if (ind === -1) {
      this.onNewPosition.emit(this.current_ - 1);
    } else if (ind === 1) {
      this.onNewPosition.emit(this.current_ + 1);
    } else {
      this.onNewPosition.emit(this.total_);
    }
  }
}
