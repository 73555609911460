import {
  Directive,
  TemplateRef,
  ViewContainerRef,
  Input,
  OnDestroy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from '@core/services/user.service';
import { UserDTO } from '@gen/gen.dto';
import { USER_PERMISSION } from '@core/services/permissions.models';

@Directive({
  selector: '[appUserPermission]',
})
export class UserPermissionDirective implements OnDestroy {
  private permission: USER_PERMISSION;

  @Input() set appUserPermission(permission: USER_PERMISSION) {
    this.permission = permission;
    this.computeState();
  }

  // This is hide on true (when user has permission)
  @Input() hide = false;

  private previousState = false;

  private sub: Subscription;
  private user: UserDTO | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    userService: UserService
  ) {
    this.sub = userService.user$.subscribe((u) => {
      this.user = u;
      this.computeState();
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  private computeState() {
    let state = false;
    if (this.user && this.permission) {
      state = this.user.permissions.indexOf(this.permission) !== -1;
      if (this.hide) {
        state = !state;
      }
    }
    if (state !== this.previousState) {
      if (state) {
        this.viewContainer.createEmbeddedView(this.templateRef);
        this.cdr.markForCheck();
      } else {
        this.viewContainer.clear();
      }
    }
    this.previousState = state;
  }
}
