import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CmsConfigService } from '@cms/services/cms-config.service';
import { CmsFlattenedLink } from '@cms/services/cms.models';
import { TranslationService } from '@core/services/translation.service';
import { ITranslations } from '@core/types/translations.model';
import { CmsPageDTO } from '@gen/gen.dto';
import { SubscriptionBaseComponent } from '@shared/base-components/subscription-base/subscription-base.component';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { ToasterService } from '@shared/services/toaster.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';
import { filter } from 'rxjs/operators';
import { CmsEditorService } from '../services/cms-editor.service';
import { dataFromForm, getForm } from './form.helper';

@Component({
  selector: 'cms-modify-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutesComponent
  extends SubscriptionBaseComponent
  implements OnInit {
  readonly NEW_FORM_DIRTY_KEY = 'ROUTES_COMPONENT_NEW';
  readonly MOD_FORM_DIRTY_KEY = 'ROUTES_COMPONENT_MOD';

  newForm: FormGroup;
  modifyForm: FormGroup;
  modifyLink: CmsFlattenedLink;
  dict: ITranslations;
  links: { id: string; name: string }[] = [];
  currentLevel: CmsFlattenedLink[];
  parentPath: string = '';
  parent: CmsFlattenedLink | undefined = undefined;

  constructor(
    private fb: FormBuilder,
    private editor: CmsEditorService,
    private toasterService: ToasterService,
    private cmsConfigService: CmsConfigService,
    private dirtyStateTrackerService: DirtyStateTrackerService,
    private cdr: ChangeDetectorRef,
    translactionService: TranslationService
  ) {
    super();
    this.dict = translactionService.dict();
  }

  ngOnInit(): void {
    this.newForm = getForm(this.fb);
    this.modifyForm = getForm(this.fb);

    // If this is null (i.e. invalid route), will fail
    this.modifyLink = this.cmsConfigService.currentPath$
      .value as CmsFlattenedLink;
    this.modifyForm.patchValue(this.modifyLink);

    this.currentLevel = this.cmsConfigService.getCurrentLevel();
    this.parentPath = this.cmsConfigService.getParentPath(this.modifyLink);
    this.parent = this.cmsConfigService.getParent(this.modifyLink);
    this.newForm.patchValue({ parentId: this.modifyLink.parentId });

    this.subscribeToForms();
    this.subscribeToConfig();
  }

  ngOnDestroy() {
    this.dirtyStateTrackerService.removeDirty(this.MOD_FORM_DIRTY_KEY);
    this.dirtyStateTrackerService.removeDirty(this.NEW_FORM_DIRTY_KEY);
    LifeCyclesUtil.stop(this);
  }

  private subscribeToForms() {
    this.dirtyStateTrackerService.trackFormGroups(this, {
      [this.NEW_FORM_DIRTY_KEY]: this.newForm,
      [this.MOD_FORM_DIRTY_KEY]: this.modifyForm,
    });
  }

  setPositionOf(link: CmsFlattenedLink, sortOrder: number) {
    const data = { sortOrder };
    return this.editor.updatePage(link.id, data).then((response) => {
      this.toasterService.showSuccess(this.dict.COMMON_UI.OK.SAVED);
      this.startModify(
        this.cmsConfigService.flattenedLinks.find(
          (i) => i.id === this.modifyLink.id
        )
      );
    });
  }

  cancelAdd() {
    this.resetAddForm();
  }

  cancelEdit() {
    this.startModify(
      this.cmsConfigService.flattenedLinks.find(
        (i) => i.id === this.modifyLink.id
      )
    );
  }

  private subscribeToConfig() {
    LifeCyclesUtil.sub(
      [this, this.cdr],
      this.cmsConfigService.configReady$.pipe(filter((i) => !!i)),
      (i) => {
        // Refresh level
        this.refreshCurrentLevel();
        // Create possible paths
        if (this.links.length === 0) {
          this.links = this.getLinks();
        }
      }
    );
  }

  private getLinks() {
    const links = [
      {
        id: this.cmsConfigService.groupId,
        name: '/',
      },
    ].concat(
      this.cmsConfigService.flattenedLinks.map((i) => {
        return {
          id: i.id,
          name: i.path,
        };
      })
    );
    links.sort((a, b) => (a.name < b.name ? -1 : 1));
    return links;
  }

  levelUp() {
    if (!this.parent) {
      return;
    }
    this.parentPath = this.cmsConfigService.getParentPath(this.parent);
    this.currentLevel = this.cmsConfigService.getSiblings(this.parent);
    this.parent = this.cmsConfigService.getParent(this.parent);
  }

  levelDown(link: CmsFlattenedLink) {
    this.parentPath = link.path;
    this.parent = link;
    this.refreshCurrentLevel();
  }

  refreshCurrentLevel() {
    this.currentLevel = this.parent
      ? this.cmsConfigService.getChildren(this.parent)
      : this.cmsConfigService.getRoots();
  }

  async startModify(link: CmsFlattenedLink) {
    if (
      await this.dirtyStateTrackerService.confirmNotDirty(
        this.MOD_FORM_DIRTY_KEY
      )
    ) {
      this.modifyForm.patchValue(link, { emitEvent: false });
      this.modifyForm.markAsPristine();
      this.modifyLink = link;
    }
  }

  modify() {
    return () => {
      const data = dataFromForm<CmsPageDTO>(this.modifyForm.value);
      const id = this.modifyLink.id;
      return this.editor.updatePage(id, data).then((response) => {
        this.toasterService.showSuccess(this.dict.COMMON_UI.OK.SAVED);
        this.dirtyStateTrackerService.removeDirty(this.MOD_FORM_DIRTY_KEY);
        this.startModify(
          this.cmsConfigService.flattenedLinks.find((i) => i.id === id)
        );
      });
    };
  }

  deletePage(pageId: string) {
    return () => {
      return this.editor.deletePage(pageId).then((response) => {
        this.toasterService.showSuccess(this.dict.COMMON_UI.OK.DELETED);
      });
    };
  }

  newSetSibling() {
    this.newForm.patchValue({
      parentId: this.modifyLink.parentId,
    });
  }

  newSetChild() {
    this.newForm.patchValue({
      parentId: this.modifyLink.id,
    });
  }

  addNew() {
    return () => {
      const data = dataFromForm<CmsPageDTO>(this.newForm.value);
      return this.editor.addPage(data).then((response) => {
        this.toasterService.showSuccess(this.dict.COMMON_UI.OK.ADDED);
        this.resetAddForm();
      });
    };
  }

  private resetAddForm() {
    this.newForm.markAsPristine();
    this.newForm.markAsUntouched();
    this.newForm.patchValue({
      slug: '',
      title: '',
    });
  }
}
