import { Injectable } from '@angular/core';

interface Dat {
  restrictToGroup: boolean;
  onlyGroupId: string;
}

@Injectable({ providedIn: 'root' })
export class DatService {
  get isGroupRestriction() {
    return this.dat.restrictToGroup;
  }

  get onlyGroupId() {
    return this.dat.onlyGroupId;
  }

  private get dat(): Dat {
    return (window as any).DAT;
  }
}
