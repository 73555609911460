import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@core/services/api-v1.models';
import {
  CmsConfigDTO,
  CmsPageDTO,
  CssVarDTO,
  LinkDTO,
  PortalConfigDTO,
  StatusDTO,
} from '@gen/gen.dto';
import { subsToUrl } from '@shared/utils/subs-to-url.func';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CmsApiService {
  constructor(private http: HttpClient) {}

  getConfig(slug: string) {
    const url = subsToUrl(API.CMS.CONFIG, { slug });
    return this.http.get<CmsConfigDTO>(url);
  }

  updateConfig(groupId: string, data: PortalConfigDTO) {
    const url = subsToUrl(API.GROUP.CMS.CONFIG, { groupId });
    return this.http.put<StatusDTO>(url, data);
  }

  addPage(groupId: string, data: CmsPageDTO) {
    const url = subsToUrl(API.GROUP.CMS.ADD_PAGE, { groupId });
    return this.http.post<LinkDTO>(url, data);
  }

  deletePage(pageId: string) {
    const url = subsToUrl(API.GROUP.CMS.PAGE, { pageId });
    return this.http.delete<StatusDTO>(url);
  }

  updatePage(
    pageId: string,
    data: Partial<CmsPageDTO>
  ): Observable<CmsPageDTO> {
    const url = subsToUrl(API.GROUP.CMS.PAGE, { pageId });
    const dto = Object.assign({}, data) as any;
    if (dto.content) {
      dto.content = JSON.stringify(dto.content);
    }
    return this.http.put<CmsPageDTO>(url, dto).pipe(
      map((i) => {
        if (i.content) {
          i.content = JSON.parse(i.content as string);
        }
        return i as CmsPageDTO;
      })
    );
  }

  getPageContent(pageId: string): Observable<CmsPageDTO> {
    const url = subsToUrl(API.CMS.PAGE, { pageId });
    return this.http.get<any>(url).pipe(
      map((i) => {
        if (i.content) {
          i.content = JSON.parse(i.content);
        }
        return i;
      })
    );
  }

  updateCssVars(groupId: string, data: CssVarDTO[]) {
    const url = subsToUrl(API.GROUP.CMS.CSS_VARS, { groupId });
    return this.http.post<StatusDTO>(url, data);
  }
}
