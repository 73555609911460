import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';

import { GroupFeaturesService } from '../../../services/group-features.service';
import { CurrentGroupService } from '@shared/services/current-group.service';
import { TranslationService } from '@core/services/translation.service';
import { tap } from 'rxjs/operators';
import { TranslationKey } from '@core/types/translations.model';
type Feature = {
  id: string;
  name: string;
  description: string;
  isEnabled: boolean;
};

@Component({
  selector: 'app-features-component',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FeaturesComponent implements OnInit {
  features: Feature[] = [];

  activate: string;
  deactivate: string;

  isLoading = true;

  constructor(
    private groupFeaturesService: GroupFeaturesService,
    private currentGroupService: CurrentGroupService,
    private translationService: TranslationService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.activate = this.translationService.dict().COMMON_UI.KEY_WORD.ACTIVATE;
    this.deactivate = this.translationService.dict().COMMON_UI.KEY_WORD.DEACTIVATE;
    this.isLoading = true;
    const featureMap = this.currentGroupService.featureMap;
    this.cdr.detectChanges();
    this.groupFeaturesService.getEditableFeatures().subscribe((features) => {
      this.features = features.map((f) => {
        return {
          id: f.id,
          name: this.translationService.fromString(f.name),
          description: this.translationService.fromString(f.description),
          isEnabled: f.id in featureMap,
        };
      });
      this.isLoading = false;
      this.cdr.detectChanges();
    });
  }

  getToggleFeature(feature: Feature) {
    return () => {
      return this.groupFeaturesService
        .toggleFeature(this.currentGroupService.currentGroupId, feature.id)
        .pipe(
          tap((f) => {
            feature.isEnabled = !feature.isEnabled;
            this.cdr.detectChanges();
          })
        );
    };
  }
}
