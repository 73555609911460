<div class="message" *ngFor="let i of messages">
  <div class="title">
    {{i.subject}}
  </div>
  <div class="time">
    {{i.sentAtStr}} |
    Vastaanottajat: {{i.numberOfRecipients}}
  </div>
  <div class="body" [innerHTML]="i.body">
  </div>
</div>