<shared-message key="UI.GROUP.SETTINGS.LOOKUPS.NO_LISTS" type="ERROR" *ngIf="lists && lists.length == 0">
</shared-message>

<shared-form-editor *ngIf="lists?.length > 0" [config]="selectConfig"></shared-form-editor>

<button (click)="changeAction('ADD_LIST')" mat-stroked-button color="primary" *ngIf="action === 'VIEW_LISTS'"
  translate="UI.GROUP.SETTINGS.LOOKUPS.ADD_LIST"></button>

<ng-container [ngSwitch]="action">

  <shared-form-editor *ngSwitchCase="'ADD_LIST'" [config]="config"></shared-form-editor>

  <shared-form-editor *ngSwitchCase="'EDIT_ITEMS'" [config]="config"></shared-form-editor>

</ng-container>