import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { TranslationService } from '@core/services/translation.service';
import { TranslationKey } from '@core/types/translations.model';
import { ConfirmActionService } from '@shared/services/confirm-action.service';
import { DirtyStateTrackerService } from '@shared/services/dirty-state-tracker.service';
import { LifeCyclesUtil } from '@shared/utils/lifecycles.util';
import { Observable } from 'rxjs';

@Component({
  selector: 'shared-loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingButtonComponent implements OnInit {
  isDirtyDisabled = false;
  @Input() loading = false;
  @Input() disabled = false;
  @Input() cls: string = '';
  @Input() cb: (() => Promise<any>) | (() => Observable<any>);
  // Default text is save
  @Input() text: string = this.ts.dict().COMMON_UI.KEY_WORD.SAVE;
  @Input() key: TranslationKey;
  @Input() translationParams: { [key: string]: string } = {};
  @Input() confirm = false;
  @Input() disableWhenDirty = false;
  @Input() enableWhenDirty?: string;
  constructor(
    private ts: TranslationService,
    private cdr: ChangeDetectorRef,
    private confirmModalService: ConfirmActionService,
    private dirtyStateTrackerService: DirtyStateTrackerService
  ) {}

  ngOnInit() {
    if (this.key) {
      this.text = this.ts.fromString(this.key, this.translationParams);
    }
    if (this.disableWhenDirty || this.enableWhenDirty) {
      LifeCyclesUtil.sub(
        [this, this.cdr],
        this.dirtyStateTrackerService.isSomethingDirty$,
        (is: boolean) => {
          if (this.disableWhenDirty) {
            this.isDirtyDisabled = is;
          } else {
            this.isDirtyDisabled = !this.dirtyStateTrackerService.isDirty(
              this.enableWhenDirty
            );
          }
        }
      );
    }
  }

  ngOnDestroy() {
    LifeCyclesUtil.stop(this);
  }

  async click() {
    if (this.confirm) {
      const wasConfirmed = await this.confirmModalService.confirm();
      if (!wasConfirmed) {
        return;
      }
    }
    this.loading = true;
    this.cdr.markForCheck();
    const val = this.cb();
    if ('subscribe' in val) {
      val.subscribe(
        (_) => {
          this.loading = false;
          this.cdr.markForCheck();
        },
        (_) => {
          this.loading = false;
          this.cdr.markForCheck();
        }
      );
    } else {
      val.finally(() => {
        this.loading = false;
        this.cdr.markForCheck();
      });
    }
  }
}
