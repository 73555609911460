<div *ngIf="isLoggedIn" class="user">
  <div class="line">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.LOGGED_IN_AS"
      [par]="{givenName: user.givenName, familyName: user.familyName}"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-user"></mat-icon>
  </div>

  <ng-container *appMediaMatcher="'-s'">
    <div class="line line-hoverable" *appUserPermission="'SYS_CREATE_EVENT'">
      <span class="text" translate="COMMON_UI.KEY_WORD.NEW_EVENT"></span>
      <span class="global__flex-spacer"></span>
      <mat-icon class="icon" fontSet="fa" fontIcon="fa-plus-square"></mat-icon>
    </div>
  </ng-container>

  <ng-container *ngIf="!isGroupRestriction">
    <ng-container *appMediaMatcher="'-m'">
      <div class="line line-hoverable" (click)="newGroup()" *appUserPermission="'SYS_CREATE_GROUP'">
        <span class="text" translate="COMMON_UI.KEY_WORD.NEW_GROUP"></span>
        <span class="global__flex-spacer"></span>
        <mat-icon class="icon" fontSet="fa" fontIcon="fa-plus-square"></mat-icon>
      </div>
    </ng-container>
  </ng-container>

  <div class="line line-hoverable" (click)="me()">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.MY_PROFILE"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-cogs"></mat-icon>
  </div>

  <div class="line line-hoverable" (click)="logout()">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.LOGOUT"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-sign-in-alt"></mat-icon>
  </div>

</div>

<div *ngIf="!isLoggedIn" class="user">
  <div (click)="login()" class="line">
    <span class="text" translate="UI.VIEWS.SLIDING_MENU.LOGIN"></span>
    <span class="global__flex-spacer"></span>
    <mat-icon class="icon" fontSet="fa" fontIcon="fa-angle-right"></mat-icon>
  </div>
</div>